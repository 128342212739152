import React, { Component } from 'react'
import { Placeholder  } from 'react-bootstrap';

import axios from "axios";

export default class Ipfs extends Component {
    constructor(props) {
      super(props);
      this.state = {...props.formData,...{loadingFile:false}};
    }
    async submitIpfs(f) {
        const form = new FormData();
        var good = false;
        var res ;

        form.append("file", f);      
        const options = {
          method: "POST",
          url: process.env.REACT_APP_SERVER_URL + "misc/uploadIpfs",
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=---011000010111000001101001",
          },
          data: form,
        };

        await axios
        .request(options)
        .then(function (response) {
            if(response.data.data.Size !== undefined){
                res = response.data.data;
                res['Hash'] = 'http://ipfs.ntity.io:8040/ipfs/'+response.data.data.Hash;
                good = true;
            }
        })
        .catch(function (error) {
            console.error(error);
        });
        if(good === true){
            var tmp = this.props.formData;
            tmp['c'] = res.Name;
            tmp['v'] = res.Hash;
             this.props.onChange(tmp);
        }
        this.setState( {...this.state,...{loadingFile:false}})
      }

      

      
    processFile(files) {
        const f = files[0];
        this.setState( {...this.state,...{loadingFile:true}})
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(f);
            reader.onloadend = () => {
                var res = this.submitIpfs(f)
            }
        });
    }

    render() {
    return (
        <>
        <div className="form-group field field-string">
            <label className="form-group field field-string" >
                <span>upload</span>
            </label>
            
            <input className="form-control" type="file"
            required={this.required}
            onChange={(event) => {this.processFile(event.target.files)}} />
             { (this.state.loadingFile == true) ? 
               <Placeholder as="p" animation="wave">
               <Placeholder xs={12} />
             </Placeholder>: ''}
           
        </div>
        <div className="form-group field field-string">
            <label className="form-group field field-string" for={this.props.idSchema.c['$id']}>
                {this.props.schema.properties['c'].title}
            </label>
            <input className="form-control" type="text"
            required={this.required}
            value={this.props.formData.c}
            onChange={(event) => {
                var tmp = this.props.formData;
                tmp['c'] = event.target.value;
                this.props.onChange(tmp);}} />
        </div>
        <div className="form-group field field-string">
            <label className="form-group field field-string" for={this.props.idSchema.v['$id']}>
                {this.props.schema.properties['v'].title}
            </label>
            <a className="form-control" href={this.props.formData.v} target="_blank">{this.props.formData.v}</a>
        </div>
        </>
    );
    }
}
