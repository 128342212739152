import React, { useState, useEffect } from 'react'
import { Form, Alert, Button, Row,  Col, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useSysVar } from '../../hook/SysVar';
import { useLocalStorage } from '../../hook/useLocalStorage';
import AlertDismissible from '../shared/AlertDismissible';


const ModalLocation = ({ct, UsernameM='',show,onHide,title}) => {
	const { t } = useTranslation();
	const { sysVarGroup } = useSysVar();
	
	const { authToken, signOut } = useAuthorization();
	const [Name, setName] = useState("");
	const [locationD, setlocationD] = useState("");
	const [Err, setErr] = useState("");
	const [validated, setValidated] = useState(false);
	const [api, setAPI] = useLocalStorage("api",'');
	const [msgError, setMsgError] = useState('');
	const [groupC, setGroupC] = useState([]);
	
	useEffect(() => {
	 	sysVarGroup('CountryISO').then((entry)=>{setGroupC(entry)});
	},[]);

	useEffect(() => {
		if(ct === 'edit'){
			if(UsernameM['Name']!==undefined){
				setName(UsernameM['Name'])
			}
			getlocation(UsernameM['ID_Entity'])
		}else{
			setName("");
			var tmpLoc = 
				{'Firstname':'',
				'Address1':'',
				'Address2':'',
				'Zip':'',
				'City':'',
				'State':'',
				'Country':'',
				'Comment':''};
			setlocationD(tmpLoc);
		}
	  },[ct,UsernameM]);

	  const hide = () => {
		setName("");
		var tmpLoc = 
			{'Firstname':'',
			'Address1':'',
			'Address2':'',
			'Zip':'',
			'City':'',
			'State':'',
			'Country':'',
			'Comment':''};
		setlocationD(tmpLoc);
		setValidated(false);
        onHide();
    }

	  
	  async function getlocation(ID_Entity) {
		const form = new FormData();
		form["id"]=ID_Entity;
		const options = {
		  method: 'GET',
		  url: api + 'corporate/group',
		  headers: {
			Authorization: "Bearer " + authToken,
			'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
		  },
		  params: form
		};
	
		axios.request(options).then(function (response) {
		  if(response.data.status === 401 && response.data.path === "errorToken/group"){
			setMsgError(response.data.msg);
			signOut();
		  } else {
			if(response.data.success === true){
				setlocationD(response.data.data);
			}else{
				setMsgError(response.data.msg);
			}
		  }
		}).catch(function (error) {
			var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/group"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
	  }


	async function submitForm() {
		const form = new FormData();
		if(Name === ''){
			setErr('username is empty');
			return false;
		}
		form.append("name", Name);
		form.append("firstname", locationD['Firstname']);
		form.append("address1", locationD['Address1']);
		form.append("address2", locationD['Address2']);
		form.append("zip", locationD['Zip']);
		form.append("city", locationD['City']);
		form.append("state", locationD['State']);
		form.append("country", locationD['Country']);
		form.append("comment", locationD['Comment']);
		var moduleU
		if(ct==='edit'){
			moduleU = 'PUT'
			form.append("id", UsernameM['ID_Entity']);
		}else{
			moduleU = 'POST'
		}

		const options = {
			method: moduleU,
			url: api + 'corporate/group',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
		},
		data: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/group"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if(response.data.data === true){
					hide();
				}else{
					setMsgError(response.data.msg);
					setErr(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/group"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
		});
	}
  
	const handleSubmit = (event) => {
	  const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
	  if (form.checkValidity() === true) {
		submitForm()
	  }
	  setValidated(true);
	};
  
	function handleOptoinal(fields,value){
		var tmpLoc ={...locationD};
		tmpLoc[fields]=value;
		setlocationD(tmpLoc);
	};
	 //sysVarGroup('CountryISO').then((entry)=>{setGroupC(entry); console.log('grpContry');console.log(groupC);console.log('grpContry');});

	return(
		<>
			<Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<AlertDismissible variant="danger"  text={msgError}/>
				<Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
					<Modal.Header closeButton>
						<Modal.Title >
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Err!=='' ? <Alert  variant={'danger'}>{Err}</Alert>:''}
						<Row>
							<Col>
								<Form.Label>{t("DICO_WORD_COMPANY_NAME")} </Form.Label>
								<Form.Control type="text" required value={ Name} name="slogan" onChange={(ev) =>{setName(ev.target.value)}} />
							</Col>
							<Col>
								<Form.Label>{t("DICO_WORD_DEPARTEMENT")} </Form.Label>
								<Form.Control type="text" value={  locationD.Firstname} name="Firstname" onChange={(ev) =>{handleOptoinal('Firstname',ev.target.value)}} />
							</Col>
						</Row>

						<Row>
							<Col>
								<Form.Label> {t("DICO_WORD_ADDRESS")} </Form.Label>
								<Form.Control type="text" value={ locationD.Address1} name="Address1" onChange={(ev) =>{handleOptoinal('Address1',ev.target.value)}} />
								<Form.Control type="text" value={ locationD.Address2} name="Address2" onChange={(ev) =>{handleOptoinal('Address2',ev.target.value)}} />
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Label> {t("DICO_WORD_ZIP")} </Form.Label>
								<Form.Control type="text" value={ locationD.Zip} name="Zip" onChange={(ev) =>{handleOptoinal('Zip',ev.target.value)}} />
							</Col>
							<Col>
								<Form.Label>{t("DICO_WORD_CITY")}</Form.Label>
								<Form.Control type="text" value={locationD.City} name="City" onChange={(ev) =>{handleOptoinal('City',ev.target.value)}} />
							</Col>
							<Col>
								<Form.Label>{t("DICO_WORD_STATE")}</Form.Label>
								<Form.Control type="text" value={locationD.State} name="State" onChange={(ev) =>{handleOptoinal('State',ev.target.value)}} />
							</Col>
						</Row>
						<Form.Group>
							<Form.Label>{t("DICO_WORD_COUNTRY")}</Form.Label>
							<Form.Select required name={'Country'} value={locationD.Country} onChange={(ev) =>{handleOptoinal('Country',ev.target.value)}}>
								{groupC.map((entry,index) => {
									return <option key={index} value={entry['ID_Var']}>{entry['Name']}</option>;
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("DICO_WORD_COMMENT")}</Form.Label>
							<Form.Control rows={5} as="textarea" value={locationD.Comment} name="Comment" onChange={(ev) =>{handleOptoinal('Comment',ev.target.value)}} />
						</Form.Group>

					</Modal.Body>
					<Modal.Footer>
						<Button type="submit" variant="outline-info" >{t('DICO_WORD_SAVE')}</Button>
						<Button onClick={hide}>{t('DICO_WORD_CLOSE')}</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}
export default ModalLocation;