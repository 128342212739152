import { useState, useEffect } from 'react';
import axios from "axios";

import localSysVar from "../locales/var.json";
import { t } from 'i18next';

export const useSysVar = () => {
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Chargez localSysVar depuis localStorage ou faites une requête API au chargement du composant
      const fetchLocalSysVar = async () => {
        try {
  
          setLoading(false);
        } catch (error) {
          console.error('Erreur lors de la récupération de sysVar :', error);
          setLoading(false);
        }
      };
  
      fetchLocalSysVar();
    }, []);

    function sysVarId(val) {
        let res = [];
        try {
            Object.entries(localSysVar).map((entry) => {
               if(entry[1].ID_Var ===  val){ 
                   res = stdSysVar(entry[1])
               }
           });
           if(res.length != 0){
            console.log(res);
               return res;
           }
           let sysVar =  localStorage.getItem('sysVar');
           console.log(sysVar);
           if(typeof sysVar === 'string'){
            sysVar = JSON.parse(sysVar);
                if( val in sysVar){
                    return stdSysVar(sysVar[val]);
                }
           }
            sysVar = {};
            ApiSysVarId(val)
            return val;
       } catch (e) {
           console.error(e);
       }
       return val
   }

   
  function sysVarGroup(val) {
    let res = [];
        try {
            Object.entries(localSysVar).map((entry) => {
            if(entry[1].Grp ===  val){
               res = res.push(stdSysVar(entry[1]))
            }
        });
        if(res.length != 0){
            return res;
        }
        //localStorage.removeItem('sysVar'); 
        let sysVar =  localStorage.getItem('sysVar');
        if(typeof sysVar === 'string'){;
            sysVar = JSON.parse(sysVar);
            Object.entries(sysVar).map((entry) => {
                if(entry[1].Grp === val){
                    res.push(stdSysVar(entry[1]));
                }
            });
            if(res.length != 0){
                return res;
            }
        }
        sysVar = {sysVar};
        GetSvGroupe(val)
        return res;
    } catch (e) {
        console.error(e);
    }
    return res
    }

    
    function sysVarSearch(val){
        let res = [];
        try {
                Object.entries(localSysVar).map((entry) => {
                if(entry[1].Name ===  val){ 
                   res = res.push(stdSysVar(entry[1]))
                }
            });
            if(res.length != 0){
                return res;
            }
            let sysVar =  localStorage.getItem('sysVar');
            if(typeof sysVar === 'string'){
                sysVar = JSON.parse(sysVar);
                Object.entries(sysVar).map((entry) => {
                    if(entry[1].Name.includes(val)  !== false){
                        res.push(stdSysVar(entry[1]));
                    }
                });
            }else{
                sysVar = {};
                ApiSysVarSearch(val);
                GetSvGroupe(val)
                return res;
            }
        } catch (e) {
            console.error(e);
        }
        return res
    }

    function stdSysVar(entry) {
        let tpm = entry
                    tpm['Tname'] = entry.Name
                   if(entry.Dico !== null){
                    tpm['Tname'] = t(entry.Dico)
                   }
        return  tpm;
    }

    return { sysVarId, sysVarGroup, sysVarSearch, loading };
}

async function ApiSysVarId(bca) {
    const forms = {"ID_Sys": bca};
    const options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "misc/sysVarId",
        headers: {
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: forms,
    };
    return await request(options)
}

async function ApiSysVarSearch(bca) {
    const forms = {"bca": bca};
    const options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "misc/sysVarSearch",
        headers: {
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: forms,
    };
    return await request(options)
}

async function GetSvGroupe(bca) {
    const forms = {"bca": bca};
    const options = {
      method: "GET",
      url: process.env.REACT_APP_SERVER_URL + "misc/sysVarGroup",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      params: forms,
    };
    return await request(options)
}

async function request(options){ 
    return await axios
      .request(options)
      .then(function (response) {
        if(response.data.status === 200 && (typeof response.data.data === 'array' || typeof response.data.data === 'object') ){
          let sysVar
            try {
                sysVar  =  localStorage.getItem('sysVar'); 
            } catch (e) {
                console.error(e);
            }
            var tmp = response.data.data
            if(typeof sysVar === 'string'){
                sysVar = JSON.parse(sysVar);
            }else{
                sysVar = [];
            }
            if(tmp.Name === undefined ){ 
                sysVar = merge(sysVar,tmp)
            
            }else{
                sysVar = merge(sysVar,[tmp])
            }
            localStorage.setItem('sysVar', JSON.stringify(sysVar)  );
            return tmp;
        }else{
          console.error(response.data.msg);
        }
    })
    .catch(function (error) {
        console.error(error);
    });
}

const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}