import React, { useEffect, useState } from 'react'
import {
  Container,
  Button,
  Card,
  Form,
  Alert,
} from 'react-bootstrap';

import axios from 'axios';
import Footer from '../composent/index/footer';
import validator from "validator";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthorization } from '../hook/AuthProvider';
import { useLocalStorage } from '../hook/useLocalStorage';
import { QRCode } from 'react-qrcode';

const Credit = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [balance_ntt, setBalanceNtt] = useState(0); // Solde en ETH
  const [balance_ntth, setBalanceNtth] = useState(0); // Solde en ETH


  const [number, setNumber] = useLocalStorage('number','');
  const {authToken, wallet,signOut} = useAuthorization();
  const navigate = useNavigate();

  
	const params = useParams();

  const [msgError, setMsgError] = useState("");
  const [formData, setFormData] = useState({
    amount: 0,
  });

  useEffect(() => {
    if(wallet != null){
      getNtt();
      getNtth();
    } 
  },[wallet])
  
  const handleInputData = input => e => {
    const { value } = e.target;
    
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  
  const submitFormData = (e) => {
    e.preventDefault();
    setError(false);

    if (!validator.isNumeric(formData.amount)) {
      setError(true);
      setMsgError("The number of new credit is required");
      return false;
    } 

    setNumber(formData.amount);

    if(!error){
      createPayment();
    }
  };

  async function getNtt() {
    const form = new FormData();

    const options = {
      method: 'GET',
      url: 'https://blockscout.ntity.io/api?module=account&action=balance&address=' + params.wallet,
    };

    axios.request(options).then(function (response) {
      console.log(response);
      setBalanceNtt(response.data.result / Math.pow(10,18));
    }).catch(function (error) {
      console.error(error);
    });
  }

  async function getNtth() {
    const form = new FormData();

    const options = {
      method: 'GET',
      url: 'https://blockscout.haradev.com/api?module=account&action=balance&address=' + params.wallet,
    };

    axios.request(options).then(function (response) {
      console.log(response);
      setBalanceNtth(response.data.result / Math.pow(10,18));
    }).catch(function (error) {
      console.error(error);
    });
  }

  async function createPayment() {
    const form = new FormData();

    form.append("data",JSON.stringify([{"Quantity" : formData.amount, "ID_VAR" : 100220}]));
    form.append("method",'stripe');

    const options = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL + 'pay',
      headers: {
        Authorization: "Bearer " + authToken,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };

    axios.request(options).then(function (response) {
      console.log(response);
      if(response.data.status){
        navigate('/stripe', {
          state: {
            clientId: response.data.data.client_secret,
          }
        });
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  return (
    <>
        <Container>
          <div className='containerTitle'> Wallet </div>
        {error && <Alert variant="danger">{msgError}</Alert>}
        {success && <Alert variant="success">{msgError}</Alert>}
          <Card style={{ marginTop: 50, marginBottom: 100 }}>
            <Card.Body>
              <div className="container">
                <div className='row mt-4 justify-content-md-center'>
                  <div className="col-md-8">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row  mt-4">
                          <div className="col-md-8">
                            <h5 className="card-title">Adresse</h5>
                            <p className="card-text">{params.wallet}</p>
                          </div>
                          <div className="col-md-3">
                            <h5 className="card-title">QR Code</h5>
                            <QRCode value={params.wallet} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4 justify-content-md-center'>
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Solde</h5>
                        <div className="row">
                          <p className="card-text col-md-6 col-sm-12">{balance_ntt} NTT</p>
                          <p className="card-text col-md-6 col-sm-12">{balance_ntth} NTTH</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4 justify-content-md-center'>
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Buy new credit</h5>
                        <div className="row">
                          <Form onSubmit={submitFormData}>
                            <Form.Group className="mb-3">
                              <Form.Control name="amount" defaultValue={formData.amount} type="number" placeholder="Amount of new credit" onChange={handleInputData("amount")} />
                            </Form.Group>
                            <div className='text-center'>
                              <Button variant="outline-secondary" type="submit">Buy credits</Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
    </>
  )
}

export default Credit;
