import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../../hook/AuthProvider';
import Loader from '../../../composent/shared/Loader';
import ResultTable from '../../../composent/shared/Result.table';
import { useLocalStorage } from '../../../hook/useLocalStorage';
import ModalWallet from '../../../composent/corporate/addWallet';
import { useParams } from 'react-router-dom';
import AlertDismissible from '../../../composent/shared/AlertDismissible';


const DetailsWallet = (props) => {
    const { t } = useTranslation();

    const { authToken, signOut } = useAuthorization();
    const [resultAUsername, setResultAUsername] = useState("");
    const [rowUsername, setrowUsername] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [titleM, settitleM] = useState(t("DICO_WORD_ADD")+' '+t("DICO_WORD_WALLET"));
    const [api, setAPI] = useLocalStorage("api",'');
    const params = useParams();
    const [msgError, setMsgError] = useState('');

    useEffect(() => {
        if(authToken !== null){
          getInfo()
        }
      },[authToken]);


      async function getInfo() {
        const form = {};
        form["name"] = params.path;
  
        const options = {
          method: 'GET',
          url: api + 'wallet/batch',
          headers: {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          params: form
        };
    
        axios.request(options).then(function (response) {
          if(response.data.status === 401 && response.data.path === "errorToken/batch"){
            signOut();
          } else {
            if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 201 && response.data.success === true)){
              if(response.data.data === undefined){
                var tmprowUsername =[];
              }else{
                var tmprowUsername = [...response.data.data];
              }
              tmprowUsername.map((entry,index)=>{
                tmprowUsername[index]['ID'] = entry.Id
                tmprowUsername[index]['Created'] = new Date(tmprowUsername[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
              });
              setrowUsername(tmprowUsername);
              setResultAUsername(response.data.data);
            }else{
              setMsgError(response.data.msg);
            }
          }
        }).catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/batch"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
        });
      }


      const columns = [
        { accessor: 'ID', label: '' },
        { accessor: 'Batch', label: t('DICO_WORD_NAME') },
        { accessor: 'Address', label: t('DICO_WORD_ADDRESS') },
        { accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
      ]
  return (
    <>
            <Container>
			  <AlertDismissible variant="danger"  text={msgError}/>
            <div className='containerTitle'>{t("DICO_WORD_DETAIL")+ ' '+t("DICO_WORD_WALLET")}</div>
                <Card style={{ marginTop: 50, marginBottom: 100 }}>
                    <Card.Body>
                        {resultAUsername === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowUsername} columns={columns}/>}
                    </Card.Body>
                </Card>
            </Container>
            <ModalWallet
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={titleM}
            />

        </>
  );
}

export default DetailsWallet;