import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios';
import {
    Container,
    Badge
} from 'react-bootstrap';
import { useSysVar } from '../../hook/SysVar';

export default function Brand(props) {
    if (props.doc.ID_Format === 1507) {
        return (<Templat doc={props.doc} small={props.small} />);
    } else {
        return (<Certificat doc={props.doc} small={props.small} />);
    }
}


function Certificat(props) {
    const Path = props.doc.Path;
    const [status, setStatus] = useState('');
    const [nickname, setNickname] = useState('');
    const [name, setName] = useState('');
    useEffect(()=>{
        console.log(props.doc);
        if(props.doc.Secure !== undefined){
            setStatus(props.doc.Secure.status);
            setNickname(props.doc.Secure.nickname);
            humanName();
        }
    },[props.doc])
    
    const { sysVarId } = useSysVar();

    var logo = 'no_cert';
    if (status === true || status === 'true') {
        logo = 'ntity_certif';
    }
    var expire = props.doc.Expire;
    if (props.doc.Expire === null) {
        expire = 'Eternal';
    }
    var badge = '';
    if (status === true || status === 'true') {
        badge = <Badge bg="success" >&#10003; Secure</Badge>;
    }else if(status === false || status === 'false'){
        badge = <Badge bg="danger" > not secure</Badge>;
    }
    var checkIssue = '-';
    if (props.doc.ID_Status === "20" || props.doc.ID_Status === 20) {
        checkIssue = 'Success';
    }else if(props.doc.ID_Status === "14" || props.doc.ID_Status === 14){
        checkIssue = 'Pending';
    }else if(props.doc.ID_Status === "21" || props.doc.ID_Status === 21){
        checkIssue = 'Fail';
    }

    
    function humanName(){
        const match = props.doc.Name.match(/^(\d+)_(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d+)?)$/);
        if (match) {
            const [fullMatch,code, datePart] = match;
            console.log(code);
            console.log(datePart);
            
            const [date, time] = datePart.split(' ');
            const formattedDate =  new Date(datePart).toLocaleString('en-CA',{ year: 'numeric', month: 'numeric', day: 'numeric' })
            sysVarId(code).then((rep)=>{
                setName(rep.Name+'_'+formattedDate);
            })
        }else{
            setName(props.doc.Name);
        }
    }

    if(props.small){
        return (
            <a key={props.doc.ID_Doc} href={"/search/" + Path} className="searchA">
                <div id="nttHead" style={{ 'backgroundImage': "url('https://cdn.cryptolex.ch/img/" + logo + ".svg')", 'cursor': 'pointer' }} />
                <div id="_H_0">
                    <div id="_T_0" className="nttField">Name</div>
                    <span id="_F_0" className="nttValue0">{name}</span>
                </div>
                <div id="_H_1" className="nttHeaderDisplay">
                    <div id="_T_1" className="nttField">Issuer {badge} </div>
                    <span id="_F_1" className="nttValue">{nickname}</span>
                </div>
                
                <div id="_H_2" className="nttHeaderDisplay">
                    <div id="_T_2" className="nttField">Issue date</div>
                    <span id="_F_2" className="nttValue">{ new Date(props.doc.Date).toLocaleDateString('en-CA',{ year: 'numeric', month: 'numeric', day: 'numeric' }) }</span>
                </div>
                <div id="_H_3" className="nttHeaderDisplay">
                    <div id="_T_3" className="nttField">Expiration date</div>
                    <span id="_F_3" className="nttValue">{expire}</span>
                </div>
                <div id="_H_4" className="nttHeaderDisplay">
                    <div id="_T_4" className="nttField">Status</div>
                    <span id="_F_4" className="nttValue">{checkIssue}</span>
                    <div id="_H_5" className="nttHeaderDisplay">
                        <div id="_T_5" className="nttField">Blockchain</div>
                        <span id="_F_5" className="nttValue">{props.doc.Blockchain}</span>
                    </div>
                </div>
            </a>
        );
    }else{
        return (
            <Container key={props.doc.ID_Doc} className="searchA">
                <div id="nttHead" style={{ 'backgroundImage': "url('https://cdn.cryptolex.ch/img/" + logo + ".svg')", 'cursor': 'pointer' }} />
                <div id="_H_0">
                    <div id="_T_0" className="nttField">Name</div>
                    <span id="_F_0" className="nttValue0">{name}</span>
                </div>
                <div id="_H_1" className="nttHeaderDisplay">
                    <div id="_T_1" className="nttField">Issuer {badge} </div>
                    <span id="_F_1" className="nttValue">{nickname}</span>
                </div>
                
                <div id="_H_2" className="nttHeaderDisplay">
                    <div id="_T_2" className="nttField">Issue date</div>
                    <span id="_F_2" className="nttValue">{new Date(props.doc.Date).toLocaleDateString('en-CA').replace(/\//g, '-')}</span>
                </div>
                <div id="_H_3" className="nttHeaderDisplay">
                    <div id="_T_3" className="nttField">Expiration date</div>
                    <span id="_F_3" className="nttValue">{expire}</span>
                </div>
                <div id="_H_4" className="nttHeaderDisplay">
                    <div id="_T_4" className="nttField">Status</div>
                    <span id="_F_4" className="nttValue">{checkIssue}</span>
                </div>
                <div id="_H_5" className="nttHeaderDisplay">
                    <div id="_T_5" className="nttField">Blockchain</div>
                    <span id="_F_5" className="nttValue">{(props.doc.Blockchain !== undefined ? props.doc.Blockchain : '-')}</span>
                </div>
            </Container>
        ); 
    }

};

function Templat(props) {
    const Path = props.doc.Path;
    const [status, setStatus] = useState('');
    const [nickname, setNickname] = useState('');
    
    useEffect(()=>{
        if(props.doc.Secure !== undefined){
            setStatus(props.doc.Secure.status);
            setNickname(props.doc.Secure.nickname);
        }
    },[props.doc])


    var misk = props.doc.Misc;
    var { logo, tag } = '';
    if (isNaN(misk)) {
        misk = JSON.parse(props.doc.Misc);
        logo = misk.logo
        if (misk.tag !== undefined) {
            tag = misk.tag.join(", ");
        }
    }
    var badge = '';
    if (status === true || status === 'true') {
        badge = <Badge bg="success" >&#10003; Secure</Badge>;
    }else if(status === false || status === 'false'){
        badge = <Badge bg="danger" > not secure</Badge>;
    }

    var checkIssue = '-';
    if (props.doc.ID_Status === "20" || props.doc.ID_Status === 20) {
        checkIssue = 'Success';
    }else if(props.doc.ID_Status === "14" || props.doc.ID_Status === 14){
        checkIssue = 'Pending';
    }else if(props.doc.ID_Status === "21" || props.doc.ID_Status === 21){
        checkIssue = 'Fail';
    }

    if(props.small){
        return (
            <>
                <div key={props.doc.ID_Doc} className="searchA">
                    <div className="ntt_container_img" id="contTempImg">
                        <img className="ntt_img" id="tempImg" src={logo} alt={props.doc.Name} />
                    </div>
                    <div id="_H_0">
                        <span id="_F_0" className="nttValue0">{props.doc.Name}</span>
                    </div>
                    <div id="_H_1" className="nttHeaderDisplay">
                        <span id="_F_1" className="nttValue">{props.doc.Description}</span>
                    </div>
                </div>
                <br/><br/>
                <Badge bg="primary" className="hide"><Link to={{ pathname: "/search/", bca: Path }} state={{ bca: Path }} className="nttBadge">Detail</Link></Badge>{'  '}
                <Badge bg="danger" className="hide"><Link to={{ pathname: "/create", bca: Path }} state={{ certificat: props.doc.Content, TX: Path, Name : props.doc.Name }} className="nttBadge">Deploy</Link></Badge>

                
    
            </>
        )
    } else {
        return (
                <Container key={props.doc.ID_Doc} className="searchA">
                    <div className="ntt_container_img" id="contTempImg">
                        <img className="ntt_img" id="tempImg" src={logo} alt={props.doc.Name} />
                    </div>
                    <div id="_H_0">
                        <div id="_T_0" className="nttField">Name</div>
                        <span id="_F_0" className="nttValue0">{props.doc.Name}</span>
                    </div>
                    <div id="_H_1">
                        <div id="_T_1" className="nttField">Issuer {badge} </div>
                        <span id="_F_1" className="nttValue">{nickname}</span>
                    </div>
                    <div id="_H_4">
                        <div id="_T_4" className="nttField">Status</div>
                        <span id="_F_4" className="nttValue">{checkIssue}</span>
                    </div>
                    <div id="_H_5" className="nttHeaderDisplay">
                        <div id="_T_5" className="nttField">Blockchain</div>
                        <span id="_F_5" className="nttValue">{(props.doc.Blockchain !== undefined ? props.doc.Blockchain : '-')}</span>
                    </div>
                    <div id="_H_6">
                        <div id="_T_6" className="nttField">Name</div>
                        <span id="_F_6" className="nttValue">{props.doc.Name}</span>
                    </div>
                    <div id="_H_7" className="nttHeaderDisplay">
                        <div id="_T_7" className="nttField">Description</div>
                        <span id="_F_7" className="nttValue">{props.doc.Description}</span>
                    </div>
                    <div id="_H_8" className="nttHeaderDisplay">
                        <div id="_T_8" className="nttField">Tag</div>
                        <span id="_F_8" className="nttValue">{tag}</span>
                    </div>
                </Container>
        )
    }
}
