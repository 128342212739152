import React, { useEffect, useState } from 'react'

import {
  Container,
  Button,
  Form,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import validator from "validator";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useAuthorization } from '../../hook/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hook/useLocalStorage';

const ForgetCorporate = () => {
	const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [email, setEmail] = useState('');
  const [corporate, setCorporate] = useState('');

  const { signOut } = useAuthorization();
  const navigate = useNavigate();

  const [api,setAPI] = useLocalStorage("api",'');
	const [gbm,setGBM] = useLocalStorage("gbm",'');
  const [lstCorporate, setLstCorporate] = useState([]);


  useEffect(() => {
		getListCorporate()
	},[]);

	const  SelectAPI = () => {
		lstCorporate.forEach((val) => {
			if(val.Firstname === corporate.toLowerCase()){
				GetAPI(val.ID_GBM);
				setGBM(val.Username);
			}
		})
	}

  async function getListCorporate() {
		const form = {};
		const options = {
		  	method: 'GET',
		  	url: process.env.REACT_APP_SERVER_URL + 'corporate/list',
		  	headers: {
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
		  	},
		  	params: form
		};
		await axios.request(options).then(function (response) {
			if (response.data.success) {
				setLstCorporate(response.data.data);
			}
		  	
		}).catch(function (error) {
		  	console.error(error);
		});
	}

  async function GetAPI(gbm) {
		const form ={};
		
		form['Username']= gbm;
	
		const options = {
		  method: 'GET',
		  url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
		  headers: {
			'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
		  },
		  params: form
		};
	
		await axios.request(options).then(function (response) {
			if (response.data.success) {
				setAPI(response.data.data['4000']);
				submit(response.data.data['4000']);
			}
		}).catch(function (error) {
		  console.error(error);
		});
	}

  const submit = (api) => {
    const form = new FormData();
    form.append("username", email);

    if(!error){
      const options = {
        method: 'POST',
        url: api + 'auth/recover',
        headers: {
          'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        },
        data: form
      };
  
      axios.request(options).then(function (response) {
        if(response.data.status === 401 && response.data.path === "errorToken/recover"){
          signOut();
        } else {
          if(response.data.status != 201){
            setMsgError(response.data.msg);
            setError(true);
          }else{
            navigate('/code',{ state: {user:email}})
          }
        }
      }).catch(function (error) {
        var errt;
        if (error.response) {
          errt=error.response;
        } else if (error.request) {
          errt=error.request.response;
        } else {
          setMsgError(error.message);
          setError(true);
          return null;
        }
        if(errt.data.status === 401 && errt.data.path === "errorToken/recover"){
          setMsgError(errt.data.msg);
          setError(true);
          signOut();
        }else{
          setMsgError(errt.data.msg);
          setError(true);
        }
      });
    }
  }
  
  const submitFormData = (e) => {
    e.preventDefault();
    setError(false);

    if (validator.isEmpty(email)) {
      setError(true);
      setMsgError(t("DICO_ERR_EMAIL_EMPTY"));
      return false;
    } 

    if(!validator.isEmail(email)){
      setError(true);
      setMsgError(t("DICO_ERR_EMAIL"));
      return false;
    }

    SelectAPI();
  };

  return (
    <>
			<Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="card-login">
				<Card.Body>
			  	{error && <AlertDismissible variant="danger"  text={msgError} />}
					<div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase'}}>
						<img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
				  	{t("DICO_WORD_PASSWORD_RESTORE")}
					</div>
					<Form onSubmit={submitFormData}>
            <Form.Group className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i><FontAwesomeIcon icon={faBuilding}/></i>
                </span>
              </div>
              <Form.Control name="corporate" defaultValue={corporate} type="text" placeholder={t("DICO_WORD_CORPORATE")} onChange={(text) => setCorporate(text.target.value)} />
            </Form.Group>
						<Form.Group className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i><FontAwesomeIcon icon={faUser}/></i>
								</span>
							</div>
							<Form.Control name="email" defaultValue={email} type="email" placeholder={t("DICO_WORD_EMAIL")} onChange={(text) => setEmail(text.target.value)} />
						</Form.Group>
						<Row>
							<Col>
								<Button variant="primary" type="submit" style={{width:'100%'}}>{t("DICO_WORD_NEXT")}</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
    </>
  )
}

export default ForgetCorporate;
