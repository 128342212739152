import React, { useState, useEffect } from 'react'
import { Form,  Alert, Button, Row, Col, Modal, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import validator from "validator";
import { useNavigate, useParams } from 'react-router-dom';

const ModalBuyCredit = ({show,onHide,title, wallet}) => {
    const navigate = useNavigate();
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [name, setName] = useState('');
	const [number, setNumber] = useState(1);
	const [value, setvalue] = useState(1);
	const [Err, setErr] = useState("");
	const [validated, setValidated] = useState(false);
    const [api, setAPI] = useLocalStorage("api",'');

    const [msgError, setMsgError] = useState("");
    const [formData, setFormData] = useState({
        amount: 0,
      });

      
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState('');
  const [cryptoPrice, setCryptoPrice] = useState(null);


  useEffect(() => {
    getPrice();
  }, [quantity]);
  useEffect(() => {
    getPrice();
  }, [wallet]);

        // Récupérer le prix de la cryptomonnaie depuis l'API exemple.com
        async function getPrice() {
          const form = new FormData();
          form.append("domain", 'NTT');
          if(wallet!=='INNER'){
            form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100210,"bca":wallet}]));
          }else{
            form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100220}]));
          }
          const options = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'pay/calculate',
            headers: {
              'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
              Authorization: "Bearer " + authToken,
            },
            data: form 
          };
      
          await axios.request(options).then(function (response) {
            if (response.data.status === 200 && response.data.success) {
              console.log(response.data.data.Total);
              setPrice(response.data.data.Total);
            } 
          }).catch(function (error) {
            console.log(error);
          });
        }

    
      const setPriceQuantity = (value) => {
        setQuantity(value );
        //setPrice((value * cryptoPrice).toFixed(2));
      }
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!quantity) {
          alert('Veuillez remplir tous les champs.');
          return;
        }
    
        createPayment();
    
      };
    
      async function createPayment() {
        const form = new FormData();
        if(wallet!=='INNER'){
          form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100210,"bca":wallet}]));
        }else{
          form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100220}]));
        }
        form.append("method",'stripe');
    
        
        const options = {
          method: 'POST',
          url: process.env.REACT_APP_SERVER_URL + 'pay',
          headers: {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          data: form
        };
    
        axios.request(options).then(function (response) {
          console.log(response);
          if(response.data.status){
            navigate('/stripe', {
              state: {
                clientId: response.data.data.client_secret,
              }
            });
          }
        }).catch(function (error) {
          console.error(error);
        });
        
      }

    const hide = () => {
        setNumber(1);
      setQuantity(1);
      setPrice(0);
		setName("")
        setValidated(false);
        onHide()
    }

	return(
		<>
		    <Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
					<Modal.Header closeButton>
						<Modal.Title >
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Err!=='' ? <Alert  variant={'danger'}>{msgError}</Alert>:''}
						<Row className="justify-content-md-center mb-4">
            <Col md="6">
              <Container className='mt-4'>
                
						    {wallet==='INNER' ? <>
                <h4>{t('DICO_WORD_TITLE_PROD_CERT')} </h4>
                <p style={{textAlign: 'justify'}}>
                {t('DICO_WORD_BODY_PRODUCT_CERT')}
                </p>
                </>:
                <>
                <h4>What is Ntity token ? </h4>
                <p style={{textAlign: 'justify'}}>
                  Ntity is a utility fungible token. The token's main objective is not to generate expectations of obtaining economic benefits but is to offer a specific and value-added utility on applications related to the Ntity protocol.
                  <br/>
                  The token is called Ntity and its acronym is NTT.
                </p>
                </>}
              </Container>
            </Col>
            <Col md="6">
              <Container>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="quantity">
                  <Form.Label>Quantité:</Form.Label>
                  <Form.Control
                    type="number"
                    value={quantity}
                    onChange={(e) => {setPriceQuantity(e.target.value);}}
                    placeholder="Quantité"
                  />
                </Form.Group>
                <Form.Group controlId="price">
                  <Form.Label>Prix:</Form.Label>
                  <Form.Control
                    disabled
                    type="number"
                    value={price}
                    placeholder="Prix"
                  />
                </Form.Group>
                
						    {wallet!=='INNER' ? 
                <Form.Group controlId="wallet" className='mb-4'>
                  <Form.Label>Wallet:</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    value={wallet}
                    placeholder="Wallet : 0x154154"
                  />
                </Form.Group> :''}

                <Form.Group className='mb-4' style={{marginTop:'1.5em'}}>
                {quantity>2000 ? 
                <div>{t("DICO_WORD_OVER_BUY")} <a href="https://www.cryptolex.com/contact/"><Button>{t("DICO_WORD_PLEASE_CONTACT")}</Button></a></div>
                :
                  <Button variant="primary" type="submit">
                    Acheter
                  </Button>}
                </Form.Group>
              </Form>
              </Container>

            </Col>
          </Row>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={hide}>Close</Button>
					</Modal.Footer>
        	</Modal>
		</>
	)
}
export default ModalBuyCredit;