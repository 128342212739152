import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faQrcode } from '@fortawesome/free-solid-svg-icons'
import {
  Container, Button, Row,Modal
} from 'react-bootstrap';
import { useAuthorization } from '../../hook/AuthProvider';
import { useQRCode } from 'react-qrcode'
import { useTranslation } from 'react-i18next';

const Related = (props) => {
  const [rela, setRela] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [txtcopy, setTxtcopy] = useState('copy');
  const { authToken } = useAuthorization();
  const [modal, setModal] = useState(false);
  const [stock, setstock] = useState('');

  const [modalData, setModalDATA] = useState({title:'',body:''});

  
	const { t,i18n } = useTranslation();
  let version;
  let balance;
  let transaction;
  let share;
  let wallet;
  let rel;
  let stockI;

  let qrcode = useQRCode(stock);
  const urlScout = {
    1553: 'https://blockscout.ntity.io/',
    1653: 'https://blockscout.haradev.com/',
    1552: 'https://etherscan.io/',
    1551: 'http://blockchain.info/btc/',
  }

  useEffect(() => {
    getRelation();
    getIsOwner();
  }, [props.doc])

  function getRelation(){
    const form = {};
    form['TX'] = props.doc.Path;
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'ntity/related',
      params: form
    };

    axios.request(options).then(function (response) {
      setRela(response.data.data);
      if (props.doc.ID_Format !== 1507) {
        setstock(response.data.data.stock);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  function getIsOwner(){
    const form = {};
    form['TX'] = props.doc.Path;
    const options = {
      method: 'GET',
      headers: { Authorization: `Bearer ${authToken}` },
      url: process.env.REACT_APP_SERVER_URL + 'ntity/isOwner',
      params: form
    };

    axios.request(options).then(function (response) {
      console.log('ownedr');
      console.log(response.data.data);
      if(response.data.path === 'ntity/isOwner'){
        console.log(response.data.data);
        setIsOwner(response.data.data);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }
  if (props.doc.ID_Format === 1507) {
    version = (
      <div className="nttValueDisplay" >
        <div className="nttField">Ntity template name</div>
        <span className="nttValue">
          <span >v.{props.doc.Version} - <small>{props.doc.Name}</small></span>
        </span>
      </div>
    );
    if (rela !== undefined && rela !== null) {
      if (rela.doc.ID_Doc === undefined && rela.doc.ID_Doc !== null) {
        const list = Object.entries(rela.doc).map((entry) => {
          return (
            <Link key={entry[1].ID_Doc} to={{ pathname: "/search/", bca: entry[1].Path }} state={{ bca: entry[1].Path }} className="searchA">
              <FontAwesomeIcon icon={faLink} />
              V{entry[1].Version} - {entry[1].Name}
            </Link>
          )
        });
        rel = (<div>
          <div className="nttField">Related </div>
          <div className="links row">
            {list}
          </div>
        </div>);
      }
    }
  } else {
    if (rela !== undefined && rela !== null) {
      if (rela.doc !== undefined && rela.doc !== null) {
        const list = Object.entries(rela.doc).map((entry) => {
          return (
            <Link key={entry[1].ID_Doc} to={{ pathname: "/search/", bca: entry[1].Path }} state={{ bca: entry[1].Path }} className="searchA">
              <FontAwesomeIcon icon={faLink} />
              {entry[1].Name}
            </Link>
          )
        });
        rel = (<div>
          <div className="nttField">Related </div>
          <div className="links row">
            {list}
          </div>
        </div>);
      }

      if (rela.balance !== undefined && rela.balance !== null) {

        if (rela.balance.quoteDiv !== undefined) {
          var quote = Object.entries(rela.balance.quoteDiv).map((item) => {

            if (item[0].includes('SYMB')) {
              var curTmp = item[0].split('_');
              if (rela.balance.quoteDiv[curTmp[0]] !== undefined && ['EUR', 'BTC', 'USD', 'CHF', 'ETH'].includes(curTmp[0]))
                return <div className="getQuote" key={curTmp[0]}><span>{rela.balance.quoteDiv[item[0]]}</span> {Number((rela.balance.quoteDiv[curTmp[0]]).toFixed(2))}</div>
            }
          });
        }
        balance = (
        <div className="nttHeaderDisplay">
            <div id="_H_0" className="startTag0">
              <span id="_F_0" className="nttValue0">Wallet details</span>
            </div>
            <div id="_H_1" className="nttHeaderDisplay">
              <div id="_T_1" className="nttField">Account balance</div>
              <span id="_F_1" className="nttValue">
                <span ><img src={rela.balance.symbol} id="symbol" /> {(rela.balance.balance !== null ? rela.balance.balance : '0')}</span>
                <i class="fa fa-qrcode" aria-hidden="true"></i>
              </span>
            </div>
            {quote}
        </div>
        );

      }
    }
    if (props.temp !== undefined) {
      version = (
        <div className="nttValueDisplay" >
          <div className="nttField">Ntity template name</div>
          <span className="nttValue">
            <span >v.{props.temp.Version} - <small>{props.temp.Name}</small></span>
          </span>
        </div>
      );

      if (props.doc.Path !== null) {
        var walAdr = props.doc.Params
        try {
          walAdr = JSON.parse(walAdr).from;
        } catch (e) {
        }  
        wallet = (
            <div className="nttHeaderDisplay">
              <div className="nttField">Issuer details</div>
              <div className="links">
                <a href={urlScout[props.doc.ID_Type] + 'address/' +walAdr} target="_blank">
                  <FontAwesomeIcon icon={faLink} />
                  wallet certificate issuer
                </a>
              </div>
            </div>
        );
      }
    }
  }

  if (props.doc.Path !== null) {
    const link = urlScout[props.doc.ID_Type] + 'tx/' + props.doc.Path
    transaction = (
      <div className="nttHeaderDisplay">
        <div className="nttField">Transaction details</div>
        <div className="links">
          <a href={link} target="_blank">
            <FontAwesomeIcon icon={faLink} />
            View this certificate on the Blockchain
          </a>
        </div>
      </div>
    );
  }

  if (stock !== undefined && stock !== null && stock !== '') {
    stockI = (
        <div className="nttHeaderDisplay">
          <div className="nttField">Item details</div>
          <div className="links">
            <a href={urlScout[props.doc.ID_Type] + 'address/' +stock} target="_blank">
              <FontAwesomeIcon icon={faLink} />
              wallet containing the certificate   
            </a>
            <FontAwesomeIcon icon={faQrcode} style={{'margin-left': '5px'}} onClick={() =>{setModal(true);setModalDATA({title:stock,body:<img style={{margin: '0 auto',width: '100%'}} src={qrcode} />})}}/>
          </div>
        
        
      </div>
    )
  }
  


  function copyToClipboard(text){
    navigator.clipboard.writeText(text)
    setTxtcopy('copyed');
    setTimeout(() => setTxtcopy('copy'), 500);
  }

  if (props.doc.Path !== null) {
    const link = urlScout[props.doc.ID_Type] + 'tx/' + props.doc.Path
    share = (
      <Container className="nttHeaderDisplay" style={{padding:'0px'}}>
        <div className="nttField">share link</div>
          <Row className="links" style={{marginTop:'15px', marginLeft:'0px'}}>
            <div className='form-control' style={{width:'65%','white-space': 'nowrap','overflow': 'hidden', 'text-overflow': 'ellipsis'} } onClick={() => {copyToClipboard(window.location.host+'/search/'+props.doc.Path)}} >{window.location.host+'/search/'+props.doc.Path}</div>
            <Button   size="sm"  style={{width:'35%'}} variant="light" onClick={() => {copyToClipboard(window.location.host+'/search/'+props.doc.Path)}}  >{txtcopy}</Button>
          </Row>
           
      </Container>
    );
  }

  function btnManageCert(){
    return(
      <Container className="nttHeaderDisplay">
        <Button size='sm' className='btn btn-secondary' href={'/myCertificates/'+props.doc.Path+'/manage'}>manage</Button>
        <Button size='sm' className='btn btn-secondary' href={'/marketplace/'+props.doc.Path+'/sell'}>{t("DICO_NTT_MARKETPLACE")}</Button>
      </Container>
    );
  }

  return (
    <Container id="searchRes">
      {version}
      {rel}
      {transaction}
      {wallet}
      {stockI}
      {balance}
      {share}
      { isOwner===true? btnManageCert():''}
        <div className="nttHeaderDisplay">
          <div className="nttField">&nbsp;</div><div className="nttField" ><span style={{fontSize:"8pt"}}>@ {props.doc.Date}</span></div>
        </div>
      <Modal show={modal} onHide={()=>setModal(false)} >
          <Modal.Header closeButton>
            <Modal.Title>{modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body> {modalData.body}</Modal.Body>
        </Modal>  
    </Container>

  )
}

export default Related;