import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import AddMember from '../../composent/corporate/addMember';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocalStorage } from '../../hook/useLocalStorage';
import ModalMember from '../../composent/corporate/addMember';
import AlertDismissible from '../../composent/shared/AlertDismissible';


const Member = () => {
	const { t } = useTranslation();
	const { authToken,authUser, signOut } = useAuthorization();
	const [resultAUsername, setResultAUsername] = useState("");
	const [resultAllGrp, setresultAllGrp] = useState("");
	const [rowUsername, setrowUsername] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [titleM, settitleM] = useState('');
	const [modalConfirmShow, setModalConfirmShow] = useState(false);

	const [allGrp, setAllGrp] = useState('');
	const [rightCorp, setRightCorp] = useState('');
	const [memb, setMemb] = useState('');
	const [ct, setCT] = useState('');

	
	const [msgError, setMsgError] = useState('');
	const [member, setMember] = useLocalStorage("member",'');
	const [api, setAPI] = useLocalStorage("api",'');
	

	useEffect(() => {
		if(member !== ""){
			getAlllocation();
		}
	},[member]);

	useEffect(() => {
		if(!modalShow){
			getAllMember();
		}
	},[modalShow]);

	async function getAllMember() {
		const form = {};
		const options = {
			method: 'GET',
			url: api + 'corporate/member',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/member"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  &&  Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					var tmprowUsername = [...response.data.data];
					tmprowUsername.map((entry,index)=>{
						tmprowUsername[index]['ID'] = entry.ID_Entity; 
						tmprowUsername[index]['Created'] = new Date(tmprowUsername[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
						tmprowUsername[index]['Edit'] = <>
							{1 === member.Right[1060].U && authUser!==entry.Username ?
								<Button variant="outline-primary" onClick={() => {setModalShow(true); settitleM(t("DICO_WORD_MODIFY")+' - '+t("DICO_WORD_MEMBER")+' - '+entry['Username']);setAllGrp(resultAllGrp);setRightCorp(member.RightCorporate);setCT('edit');setMemb(entry);}}><FontAwesomeIcon icon={faEdit} /></Button>
							 : ''}
							 
							{1 === member.Right[1060].D && authUser!==entry.Username ?
								<Button variant="outline-danger" onClick={() => {setModalConfirmShow(true); setMemb(entry['ID_Entity'])}}><FontAwesomeIcon icon={faTrash} /></Button>	
							: ''}						
						</>
					});
					setrowUsername(tmprowUsername);
					setResultAUsername(response.data.data);		
				}else{
					setMsgError(response.data.msg);
					console.error(response.data.msg);
					setResultAUsername(false);
				}

			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/member"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}
	
	async function getAlllocation() {
		const form = {};
		const options = {
			method: 'GET',
			url: api + 'corporate/group', 
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};

		axios.request(options).then(function (response) {
			if(response.data.status === 401  && response.data.path === "errorToken/group"){
				setMsgError(response.data.msg);
				signOut();
			} else { 
				getAllMember();
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					setresultAllGrp(response.data.data);
				}else{
					setResultAUsername('');
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/group"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function remove() {
		const form = new FormData();
		form.append("id", memb);
		const options = {
			method: 'DELETE',
			url: api + 'corporate/member',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/member"){
				signOut();
			} else {
				if(response.data.data === true){
					getAllMember();
					setModalConfirmShow(false);
				}else{
					setMsgError(response.data.msg);}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/member"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	const columns = [
		{ accessor: 'ID', label: '' },
		{ accessor: 'Username', label: t('DICO_WORD_USERNAME') },
		{ accessor: 'Name', label: t('DICO_WORD_NAME') },
		{ accessor: 'Firstname', label: t('DICO_WORD_FIRSTNAME') },
		{ accessor: 'Group', label: t('DICO_WORD_GROUP') },
		{ accessor: 'Edit', label: t('DICO_WORD_ACTION') },
	]

	return (
	<>
		<Container>
		<AlertDismissible variant="danger"  text={msgError}/>
		<div className='containerTitle'>{t("DICO_WORD_MEMBER")}</div>
			<Card style={{ marginTop: 50, marginBottom: 100 }}>
				<Card.Body>
					{resultAUsername === '' ? <Container className='text-center'><Loader /></Container> :
					<>
						{1 === member.Right[1060].I ?
						<Button variant="outline-primary" onClick={() => {setModalShow(true); settitleM(t('DICO_WORD_ADD')+' '+t("DICO_WORD_MEMBER"));setAllGrp(resultAllGrp);setRightCorp(member.RightCorporate);setCT('add');setMemb(member);}}>
							{t("DICO_WORD_ADD")}
						</Button> : ''}
						<ResultTable rows={rowUsername} columns={columns}/>
					</>
				}
				</Card.Body>
			</Card>
		</Container>
		<Modal show={modalConfirmShow} onHide={() => setModalConfirmShow(false)} size="sm" aria-labelledby="contained-modal-title-vcenter">
				<Modal.Header closeButton>
					<Modal.Title >
						{t('DICO_WORD_DELETE_CONFIRM')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='text-center'>
					<Button variant="outline-danger" style={{width: '50%'}}  onClick={() => remove()}>
							{t('DICO_WORD_YES')}
						</Button>
						<Button variant="outline-primary" style={{width: '50%'}} onClick={() => setModalConfirmShow(false)}>
						{t('DICO_WORD_NO')}
						</Button>
        </Modal.Body>
			</Modal>
		<ModalMember
			show={modalShow}
			onHide={() => setModalShow(false)}
			title={titleM}
			allGrp={resultAllGrp} 
			RightCorp={rightCorp}
			UsernameM={memb} 
			ct={ct}
		/>	
	</>


	);
}

export default Member;