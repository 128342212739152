import React, { useState, useEffect } from 'react'
import { Form, Alert, Button, Row, Card, Col, Spinner, Modal, InputGroup } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpLong,faDownLong, faPlus, faDownload, faLink } from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../shared/AlertDismissible';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { json } from 'react-router-dom';

const ParamsImage = ({data,handleParam}) => {
    const { t } = useTranslation();

    var field;
    var valueD = [];

    field = data.map((el, index) => {
        valueD.push(el)
        return inputP(index,t)
    });
    
    function btnRemove(index){
        if(data.length > 1){
            return <Button size={'sm'} variant="outline-danger"  style={{width: 'fit-content'}} onClick={()=>{valueD.splice(index, 1);handleParam(valueD)}}><FontAwesomeIcon icon={faTrash} /></Button>
        }else{
            return ''
        }
    }

    function btnMove(index){
        if(data.length > 1){
            if(index == 0){
                return (
                    <Col sm={1}>
                        <Button size={'sm'} disabled variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index-1);handleParam(valueD)}}><FontAwesomeIcon icon={faUpLong} /></Button> 
                        <Button size={'sm'} variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index+1);handleParam(valueD)}}><FontAwesomeIcon icon={faDownLong} /></Button>
                    </Col>
                )
            }else if(index == data.length-1){
                return (
                    <Col sm={1}>
                        <Button size={'sm'}  variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index-1);handleParam(valueD)}}><FontAwesomeIcon icon={faUpLong} /></Button> 
                        <Button size={'sm'} disabled variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index+1);handleParam(valueD)}}><FontAwesomeIcon icon={faDownLong} /></Button>
                    </Col>
                )
            }
            return (
                <Col sm={1}>
                    <Button size={'sm'} variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index-1);handleParam(valueD)}}><FontAwesomeIcon icon={faUpLong} /></Button> 
                    <Button size={'sm'} variant="outline-secondary"  style={{width: 'fit-content'}} onClick={()=>{moveElement(valueD,index,index+1);handleParam(valueD)}}><FontAwesomeIcon icon={faDownLong} /></Button>
                </Col>
            )
        }else{
            return ''
        }
    }   

    function moveElement(array, fromIndex, toIndex) {
        const element = array.splice(fromIndex, 1);
        array.splice(toIndex, 0, element[0]);
      }

    function btnAdd(){
            return <Button size={'sm'} variant="outline-success" onClick={()=>{valueD.push(['']);handleParam(valueD)}}><FontAwesomeIcon icon={faPlus} /></Button>
    }


    function inputDa(index,t){

            var result
            var preview
			var def

            result = <Form.Control required   type="text" name={'Params['+index+']'}  value={valueD[index]} 
            pattern="(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?"  onChange={e => {valueD[index]=e.target.value;handleParam(valueD)}} />
                
            if(!(valueD[index] === null) && !(valueD[index] === undefined) && !(valueD[index] === '')){
                preview = <a href={valueD[index]} target="_blank" style={{position:'absolute'}} ><FontAwesomeIcon style={{position:'absolute', bottom: 0}}  icon={faLink}/><img src={valueD[index]} style={{width:56, height:56}}/></a>
            }

			if(0===index){
				def = <b>{t('DICO_WORD_DEFAULT')}</b>
			}else{
				def = index+1
			}


            return  <>
                <Row>
                    <Col>
						{def} {t('DICO_WORD_IMAGE')} URL&nbsp;&nbsp;
						<FontAwesomeIcon onClick={e => {document.querySelector('#file'+index).click()}} icon={faDownload}/>
                        {result}
                        <Form.Control 
							style={{'display':'none'}}  
							id={'file'+index} type="file" 
							name={'Params['+index+']'}   
							placeholder='https://'
							typeof='URL'
							onChange={e => {handleFileInput(e, index);}}/>
                        <Spinner  id={'spinner'+index} animation="border" role="status" style={{'display':'none'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
					</Col>
					<Col>
	                	{preview}
					</Col>
                </Row>
            </>;
    }

    const handleFileInput = (e,index) => {
        document.querySelector('#spinner'+index).style.display = 'block';
        const file = e.target.files[0];
        if (file.size > 1000024){
            alert("File size cannot exceed more than 1GB" );
        }else{
            const formData = new FormData();
            formData.append("file", file);
            axios
              .post(process.env.REACT_APP_SERVER_URL + "misc/uploadIpfs", formData)
              .then((response) => {
                if(response.data.status === 201 && response.data.success === true){
                    var data = JSON.parse(response.data.data)
                    valueD[index] = 'http://ipfs.ntity.io:8040/ipfs/'+data.Hash;
                    handleParam(valueD);
                }else{
                    alert("File Upload Error")
                }
              })
              .catch((err) => alert("File Upload Error"));
            }
        
        document.querySelector('#spinner'+index).style.display = 'none';
      };

    function inputP(index,t){
       return <Card key={index} style={{'margin': '10px','padding': '10px','width': '98%'}}>
            <Row >
                <Form.Group className="col" >
                    {inputDa(index,t)}
                </Form.Group>
                {btnRemove(index)}
                {btnMove(index)}
            </Row>
        </Card>
    }

    return(
        <>
            {field}
            {btnAdd()}
        </>
    )
}

const ModalProduct = ({ct, skuM='',show, onHide, title}) => {
    const { t } = useTranslation();
    const { authToken, signOut } = useAuthorization();
    const [sku, setSku] = useState("");
    const [autoLog, setAutoLog] = useState(false);
    const [Params, setParams] = useState([]);
    const [Description, setDescription] = useState([]);
    const [tempDesc, setDtempDesc] = useState("");
    const [name, setname] = useState("");
    const [jsonsource, setjsonsource] = useState("");
    const [Err, setErr] = useState("");
    const [validated, setValidated] = useState(false);
    const [switchjson, setSwitchjson] = useState(false);
    const [modifSku, setmodifSku] = useState(true);
    const [api, setAPI] = useLocalStorage("api",'');
	const [msgError, setMsgError] = useState('');

    
	const [member, setMember] = useLocalStorage("member",'');

    useEffect(() => {
        if(ct === 'edit'){
            setSku(skuM['Sku'])
            if(skuM['Description']!==undefined){
                setDtempDesc(skuM['Description'])
                setDescription(JSON.parse(skuM['Description']))
            }
            if(skuM['Name']!==undefined){
                setname(skuM['Name'])
            }
            setParams(JSON.parse(skuM['Params']))
            setjsonsource( JSON.stringify({ 'SKU': skuM['Sku'], 'Name': skuM['Name'], 'Params': JSON.parse(skuM['Params']), 'Description': JSON.parse(skuM['Description']) },undefined, 4));
            setmodifSku(false);
        }
      },[ct,skuM]);


    const hide = () => {
        setSku()
        setDtempDesc()
        setDescription([])

        setname()
        setParams([])
        setjsonsource();
        setValidated(false);
        setmodifSku(true);
        onHide();
    }

    async function submitForm() {
        const form = new FormData();
        if(sku === ''){
            setErr('SKU is empty');
            return false;
        }
        form.append("sku", sku);
        form.append("Name", name);
        if(Params !== []){
            form.append("Params", JSON.stringify(Params));
        }
        if(Params !== []){
            form.append("Description", JSON.stringify(Description));
        }
        
        if(member.Right.hasOwnProperty('1067') == true){
            if(member.Right['1067'].U == 1){
                form.append("Resolve", autoLog);
            }
        }
        
        var moduleU
        if(ct==='edit'){
            moduleU = 'PUT'
        }else{
            moduleU = 'POST'
        }

        const options = {
            method: moduleU,
            url: api + 'corporate/product',
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        },
        data: form
        };
        
        axios.request(options).then(function (response) {
        if(response.data.status === 401 && response.data.path === "errorToken/product"){
            setMsgError(response.data.msg);
            signOut();
        } else {
            if((response.data.status === 201 && response.data.success === true)||(response.data.status === 200 && response.data.success === true)){
                hide()
            }else{
                setMsgError(response.data.msg);
            }
        }
        }).catch(function (error) {
			var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/product"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
    }
  
    const handleSubmit = (event) => {
      const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

      if (form.checkValidity() === true) {
        submitForm()
      }
      setValidated(true);
    };

    const parmImage = () =>{
        return (
            <ParamsImage data={Params} handleParam={(data) =>{setParams(data);setjsonsource( JSON.stringify({ 'SKU': sku, 'Name': name, 'Params': data, 'Description': Description },undefined, 4)); }} />
        )
    }


    const handleJsonInput= (value) => {
        if(hasJsonStructure(value) === true){
            setErr("");
            var jsonT = JSON.parse(value);
            if(jsonT.SKU === undefined || jsonT.SKU === null || jsonT.SKU === ""){
                setErr('Sku is empty');
                setSku('');
            }else{
                if(modifSku == true){
                    setSku(jsonT.SKU);
                }else{
                    jsonT.SKU = sku;
                    value = JSON.stringify(jsonT,undefined, 4)
                }
                
            }
            if(jsonT.Name === undefined || jsonT.Name === null || jsonT.Name === ""){
                setname('');
            }else{
                setname(jsonT.Name);
            }
            if(jsonT.Params === undefined || jsonT.Params === null || jsonT.Params === ""){
                setParams([]);
            }else{
                setParams(jsonT.Params);
            }
            if(jsonT.Description === undefined || jsonT.Description === null || jsonT.Description === ""){
                setDescription([]);
            }else{
                setDtempDesc(JSON.stringify(jsonT.Description,undefined, 4))
                if(hasJsonStructure(jsonT.Description) === true){
                    setDescription(jsonT.Description);
                }
            }
        }else{
            setErr('Not a Json');
        }
        setjsonsource(value);
    }
    function hasJsonStructure(str) {
            if (typeof str !== 'string') return false;
            try {
                const result = JSON.parse(str);
                const type = Object.prototype.toString.call(result);
                return type === '[object Object]' || type === '[object Array]';
            } catch (err) {
                return false;
            }
        }
    const JsonInput = () =>{
        return (
            <Form.Group className="card" style={{'padding': '10px'}}>
                <Form.Label>JSON Format</Form.Label>
                <Form.Control as="textarea" rows={10} value={jsonsource} name="jsonsource" onChange={(ev) =>{handleJsonInput(ev.target.value)}}/>
            </Form.Group>
        )
    }

    const handleDescription= (value) => {
        setDtempDesc(value);
        if(hasJsonStructure(value) === false){
            setErr('Not a Json');
            setDescription([]);
        }else{
            setErr('');
            setDescription(JSON.parse(value));
            setjsonsource( JSON.stringify({ 'SKU': sku, 'Name': name, 'Params': Params, 'Description': JSON.parse(value) },undefined, 4))
        }
    }

    const autoResolve=()=>{
        console.log(member.Right);
        if(member.Right.hasOwnProperty('1067') == true){
            if(member.Right['1067'].U == 1){
                return <div className="mb-3">
                            <Form.Check
                                type={'checkbox'}
                                label={`Auto resolve log `}
                                onChange={(e)=>setAutoLog(e.target.checked)}
                            />
                        </div> 
            }
        }
        return ''
    }
    return(
        <>
		<Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<AlertDismissible variant="danger"  text={msgError}/>
            <Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
			<Modal.Header closeButton>
				<Modal.Title >
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
            <Form.Check // prettier-ignore
                type="switch"
                label="Show JSON format"
                onChange={(e) => setSwitchjson(!switchjson)}
            />
            
                {Err!=='' ? <AlertDismissible  variant={'danger'} text={Err} />:''}
                
            {switchjson ===true ? 
                    JsonInput()
                :
                <>
                    <Row style={{padding:'0 10px'}}>
                        <Col >
                            <Form.Label>SKU</Form.Label>
                            {modifSku ===true? <Form.Control required type="text" value={sku}  name="sku" onChange={(ev) =>{setSku(ev.target.value);
                            setjsonsource( JSON.stringify({ 'SKU': ev.target.value, 'Name': name, 'Params': Params, 'Description': Description },undefined, 4))}}/>: <Form.Control disabled type="text" value={sku}  name="sku" onChange={(ev) =>setSku(ev.target.value)}/>}
                        </Col>
                        <Col>
                            <Form.Label>{t('DICO_WORD_NAME')}</Form.Label>
                            <Form.Control type="text" value={name} name="name" onChange={(ev) =>{setname(ev.target.value);
                            setjsonsource( JSON.stringify({ 'SKU': sku, 'Name': ev.target.value, 'Params': Params, 'Description': Description },undefined, 4))}}/>
                        </Col>
                    </Row>
                    <Row style={{padding:'0 10px'}}>
                        <Col >
                            <Form.Label>{t('DICO_WORD_DESCRIPTION')}</Form.Label>
                            <Form.Control  rows={4} as="textarea"  value={tempDesc} name="Description" onChange={(ev) =>{handleDescription(ev.target.value)}}/>
                        </Col>
                    </Row><br />
                    <Row>
                        <Col className='groupedCol'>
                        
                            <div className='groupedTitle' >{t("DICO_WORD_IMAGE")} </div>
                            {parmImage()}
                        </Col>
                    </Row>
                    
					{autoResolve()}
     
                </>
            }
            </Modal.Body>
			<Modal.Footer>
                <Button type="submit" variant="outline-success" >{t('DICO_WORD_SAVE')}</Button>
		    </Modal.Footer>
            </Form>
		</Modal>
        </>
    )
}
export default ModalProduct;