import React, { useEffect, useState } from 'react'
import {
  Card,
  Container,
} from 'react-bootstrap';
import Footer from '../composent/index/footer';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import CheckoutForm from '../composent/shared/CheckoutForm';
import Loader from '../composent/shared/Loader';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_KVwZ4ncftA3K6Q38cuMeHY1w');

const Stripe = () => {
  const params = useLocation();

  const optionsStripe = {
    clientSecret: params.state.clientId,
  };

  return (
    <>
        <div className='text-center mt-4'>
          <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> NTITY Stripe</h2>
        </div>
        <Container>
          <Card style={{ marginTop: 50, marginBottom: 100 }}>
            <Card.Body>
              <h3>Please enter your credit card information</h3>
              <br/>
              { optionsStripe !== undefined ? <Elements stripe={stripePromise} options={optionsStripe}>
                <CheckoutForm />
              </Elements> : <Loader/>}
              
            </Card.Body>
          </Card>
        </Container>
    </>
  )
}


export default Stripe;
