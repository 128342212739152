import React from 'react'
import Contribute from '../composent/index/contribute.js';
import Header from '../composent/index/header';
import Ntity from '../composent/index/ntity';
import Community from '../composent/index/community.js';
import Description from '../composent/index/description';

const Index = () => {
  return (
    <>
      <Header />
      <Description />
      <Community />
      <Ntity />
      <Contribute />
    </>
  );
}

export default Index;


