import React, { useState, useEffect } from 'react'
import axios from "axios";
import Haradev from '../sendToBc/haradev';
import Web3Js from '../sendToBc/web3Js';
import { Modal } from 'react-bootstrap';

import {
  Container
} from 'react-bootstrap';
import PayApi from '../sendToBc/payApi';
import { useAuthorization } from '../../hook/AuthProvider';

const Send = (props) => {
  const { authUser} = useAuthorization();
  const [hexDatagram, setHexDatagram] = useState();
  const [isShowing, setIsShowing] = useState(false);
  const [bcPay, setBcPay] = useState('NTTH');

  const lstApi = {
    2515: 'certify',
    2516: 'event',
    15: 'lock',
    10: 'unlock',
    17: 'revoke',
    2519: 'ack',
    2517: 'move',
    2518: 'own',
    2514: 'transfer',
  }

  useEffect(() => {
    if (props.doc.Content !== null) {
      const form = new FormData();
      form.append("sourcejson", JSON.stringify({ N: props.doc.Name, X: props.doc.Expire, 'D': JSON.parse(props.doc.Content), 'V': 0, 'L': 1508 }));
      form.append("templateTX", props.doc.ID_Template);
      if(props.doc.ID_Type === null){
        form.append("bcid", 1553);
      }else{
        form.append("bcid", props.doc.ID_Type)
      }
      if(props.doc.ID_Type !== null){
      form.append("lastOperationTX", props.doc.ID_Parent);
      }
      
      
      const options = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'datagram/'+lstApi[props.doc.ID_Format],
        data: form
      };

      axios.request(options).then(function (response) {
        setHexDatagram(response.data.data);
      }).catch(function (error) {
        console.error(error);
      });
    }
  }, [props.doc]);

  function showPay() {
    switch (bcPay) {
      case 'NTTH': 
      if(authUser === null || authUser === undefined ){
        return <Haradev doc={props.doc} hexDatagram={hexDatagram} template={props.template}  blockchain={bcPay}/>
      }else{
        return <PayApi doc={props.doc} hexDatagram={hexDatagram} template={props.template}  blockchain={'NTTH'}/>;
      }
      
      ;
      case 'NTT': return <PayApi doc={props.doc} hexDatagram={hexDatagram} template={props.template}  blockchain={'NTT'}/>;
      case 'web3': return <Web3Js doc={props.doc} hexDatagram={hexDatagram} template={props.template} />;
      
     
      default:
        break;
    }
  }

  return (
    <Container id="registerForm">
      <div className='text-center mt-4'>
      </div>
      <h3><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> Your certificate is ready to be sent to the blockchain.</h3>
      <h4>Please choose the blockchain of your choice:</h4>
      <br />
      <button className="btn btn-secondary" onClick={() => { setIsShowing(true); setBcPay('NTTH');}}>Certify on testnet <div style={{ 'fontSize': 'smaller' }}>Ntity-Haradev</div></button>
      
      {authUser === null || authUser === undefined ? '' :<button className="btn btn-secondary" onClick={() => { setIsShowing(true) ;setBcPay('NTT');}}>Certify on Mainnet <div style={{ 'fontSize': 'smaller' }}>Ntity-Mainnent</div></button>}
      {authUser === null || authUser === undefined ? '' :<button className="btn btn-secondary" onClick={() => { setIsShowing(true) ;setBcPay('web3');}}>Certify on web3 <div style={{ 'fontSize': 'smaller' }}>Ntity-Mainnent</div></button>}
      <br />
      <div>
        <Modal
          size="lg"
          show={isShowing}
          onHide={() => setIsShowing(false)}>
          <Modal.Header closeButton>
            <Modal.Title><h3 style={{marginTop:'0.5rem'}}>Last step before certify on {bcPay}</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {showPay()}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  )
}

export default Send