import React from 'react';
import { useTranslation } from 'react-i18next';

const Loader = () => { 
    const { t} = useTranslation();

    return (
        <div className="loader">
            <img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png"  alt="logo" style={{width:"8em", height:"8em", margin: 20, padding: 10, opacity:"0.8" }}/>
            <span style={{fontSize:"2em", fontWeight: 100}}>{t("DICO_NIO_LOADING")}</span>
        </div>
    )
}
export default Loader;

