
import React, { useState, useEffect } from "react";


import { useNavigate } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Row,
    Modal,
    Alert,
    Collapse
} from 'react-bootstrap';
import axios from "axios";
import { useAuthorization } from "../../hook/AuthProvider";
import AlertDismissible from "../shared/AlertDismissible";

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { getAccount, getNetwork, switchNetwork, watchAccount, watchNetwork } from "@wagmi/core";

import { useBalance, usePrepareSendTransaction, useSendTransaction, useWaitForTransaction  } from 'wagmi'
import { parseEther } from "viem";

const Web3Js = (props) => {
  const { authToken, signOut } = useAuthorization();
    const [account, setAccount] = useState();
    const [connected, setConnected] = useState(false);
    const [chainVerif, setChainVerif] = useState();
    const [price, setPrice] = useState('');
    const [addresseTo, setAddresseTo] = useState();
    const [info, setInfo] = useState();
    const [isCollapseOpen, setCollapse]= useState(false);
    const [mailReq, setMailReq] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkSendBC, setcheckSendBC] = useState(false);
    
    const [errorF, seterrorF] = useState('');

    const navigate = useNavigate();
 
  const { chain, chains } = getNetwork()
    const acc = getAccount();
    
    const balance= useBalance({
      address: acc.address,
    })
    var misc;
    
    /*------------ initialyse -----------------*/
    useEffect(() => {
      if( props.template !== undefined){
        misc = JSON.parse(props.template.Misc)
        if(typeof misc.bc === 'object'){
          setChainVerif(misc.bc);
        }
        if(misc.price ){
          setPrice(misc.price );
        }
        if(misc.to){
          setAddresseTo(misc.to);
        }
        if(misc.Comment){
          setInfo(misc.Comment);
        }

        if(misc.email === 'required'){
          setMailReq(true);
        }
      }
    }, [props.template]);


    /*------------ loading -----------------*/
    const startLoading = () => {
      setLoading(true);
  };
  
  const stopLoading = () => {
    setLoading(false);
  };



    /*------------ handler -----------------*/
  
  // const handleNetwork = (e) => {
  //   const id = e.target.value;
  //   setNetwork(Number(id));
  // };
  // const handleInput = (e) => {
  //   const msg = e.target.value;
  //   setMessage(msg);
  // };


/*------------ handler network -----------------*/
  // const netwatch = watchNetwork((Network)=>{handleNetworkChanged(Network)})

  // const handleNetworkChanged = (network) => {
  //   console.log("ChainChanged", network);
  //   setChainId( network.chain.id);
  //   checkChainId( network.chain.id);
  //   // checkChainId(chainId);
  // };


  const  handleNetwork = async(id) => {
      await switchNetwork(id).catch((e)=>{console.log(e);})
    
    // checkChainId(chainId);
  };
  
  // function checkChainId(id){
  //   if (id in convertIdChain){
      
  //     if(chainVerif !== undefined){
  //       if(convertIdChain[id] ==chainVerif){
  //         setError('');
  //       }else{
  //         setError('please, select a blockchain compatible with this template : '+chainVerif.join(", "));
  //       }
  //     }else{
  //       setError('');
  //     }
  //   }else{
  //       setError('not ready for this blockchain');
  //   }
  // }



/*------------ handler account -----------------*/
const accWatch = watchAccount((account) => handleAccountsChanged(account))


const handleAccountsChanged = (accounts) => {
  if (accounts){
    setAccount(accounts);
    setAddresseTo(accounts.address);
  } 
};


/*------------ handler connect -----------------*/
useEffect(() => {
  if(acc.isConnected === true){
    setConnected(true);
    setAddresseTo(acc.address);
  }else{
    setConnected(false);
  }
});

/*------------ check to Send -----------------*/
useEffect(() => {
  checkSend()
},[acc,chain]);

const checkSend= () => {
  var errM = [];
  var ckeck = true;
  if(acc.isConnected === false){
    ckeck=false
  }
  console.log('isConnected');
  console.log(props);
  if(addresseTo !== undefined){
    if (addresseTo!==acc.address) {
      ckeck=false
      errM.push(<div>wrong address for this certificate : {addresseTo}</div>);
    }
  }
  if(chainVerif !== undefined){
    if (chainVerif!==convertIdChain[chain.id] ) {
      ckeck=false
      errM.push(<div>please, select a blockchain compatible with this template : {chainVerif}</div>);
    }
  }
  setcheckSendBC(!ckeck)
  if(errM.length>0){
    seterrorF(errM);
  }
};
    
    const convertIdChain = {
      197710212030: '1553',
      197710212031: '1653',
      1           : '1552',
      13          : '1591'
  }

  const convertBcCode = {
    '1653' : 197710212031,
    '1553' : 197710212030,
    '1652' : 11155111,
    '1552' : 11155111,
    //'1552' : 1,
    '1591' : 13
}

const { data, isLoading, isSuccess, sendTransaction } = useSendTransaction({
  to: acc.address,
  value: parseEther('0'),
  data: '0x'+props.hexDatagram,
  onSuccess(data) {
    console.log('Success', data)
    submitTx(data.hash)
  },
})
async function submitTx(txHash) {
  const form = new FormData();
  form.append("bcId", convertIdChain[chain.id]); 
  form.append("idDoc", props.doc.ID_Doc); 
  form.append("TX", txHash);

  const options = {
    method: "PUT",
    url: process.env.REACT_APP_SERVER_URL + "ntity/mine",
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=---011000010111000001101001",
        Authorization: `Bearer ${authToken}`
    },
    data: form,
  };

  await axios
    .request(options)
    .then(function (response) {
    if(response.data.status === 401 && response.data.path === "errorToken/mine"){
      seterrorF(response.data.msg);
      signOut();
    }else{
      if(true === response.data.data){
        navigate('/search/' + response.data.data, {
          state: {
            bca: txHash,
            congrat: props.doc.ID_Doc,
          }
        });
      }else{
        seterrorF( response.data.msg)
      }
     
    }})
    .catch(function (error) {
      seterrorF( error)
    });
}


const initCollapse = () => {
  return setCollapse(!isCollapseOpen)
}
const btnAddChain = () =>{
  var idDispo = acc.connector.chains.map((chainT)=>{ return chainT.id});
  return (
      <div className="btn-toolbar" role="toolbar" aria-label="btnAddChain">
             <Button
              onClick={initCollapse}
              aria-controls="example-collapse-text"
              aria-expanded={isCollapseOpen}
              variant="link" size="sm"
            >
              add blockchain to your wallet
            </Button>
           <Collapse in={isCollapseOpen}>
            <div id="example-collapse-text">
              <h5>please, click one any button under in order to add the blockchain to your wallet</h5>
              <div className="btn-group-toggle" data-toggle="buttons">
                  <Row>
                      <div className="col-sm"><h6>Ntity</h6>
                          <ButtonGroup > 
                          {(idDispo.includes(197710212030)) ? <Button variant="outline-secondary"  size="sm" onClick={() => {handleNetwork({chainId:197710212030})}}> Ntity Mainnet</Button>:
                          <Button variant="outline-secondary" disabled size="sm" > Ntity Mainnet</Button>}
                          {(idDispo.includes(197710212031)) ? <Button variant="outline-secondary"  size="sm" onClick={() => {handleNetwork({chainId:197710212031})}}> Ntity Testnet</Button>:
                          <Button variant="outline-secondary" disabled size="sm" > Ntity Testnet</Button>}
                               
                          </ButtonGroup>
                      </div>
                      <div className="col-sm"><h6>Ethereum</h6>
                          <ButtonGroup > 
                          {(idDispo.includes(1)) ? <Button variant="outline-secondary"  size="sm" onClick={() => {handleNetwork({chainId:1})}}> Ethereum Mainnet</Button>:
                          <Button variant="outline-secondary" disabled size="sm" > Ethereum Mainnet</Button>}
                          {(idDispo.includes(11155111)) ? <Button variant="outline-secondary"  size="sm" onClick={() => {handleNetwork({chainId:11155111})}}> sepolia</Button>:
                          <Button variant="outline-secondary" disabled size="sm" > sepolia</Button>}
                          </ButtonGroup>
                      </div>
                      <div className="col-sm"><h6>Matic</h6>
                          <ButtonGroup > 
                          {(idDispo.includes(137)) ? <Button variant="outline-secondary"  size="sm" onClick={() => {handleNetwork({chainId:137})}}> Polygon</Button>:
                          <Button variant="outline-secondary" disabled size="sm" > Polygon</Button>}
                          </ButtonGroup>
                      </div>
                  </Row>
              </div>
              
              </div>
            </Collapse>
      </div>
  );
}
      
      return (
        <> 
         <div class="container">
			  <AlertDismissible variant="danger"  text={errorF}/>
        <div>{(errorF === "" || errorF === undefined)? '' : <Alert key={'danger'} variant={'danger'}>{ errorF}</Alert>}</div>
        {(acc.isConnected === false) ?  ( <ConnectButton />):(
          <>
            <div>{acc.address}</div>
              {balance.isLoading && <div>Fetching balance…</div>}
              {balance.isError && <div>Error fetching balance</div>}
              {balance.isSuccess && <div>Balance: {balance.data?.formatted} {balance.data?.symbol}</div>}
            {btnAddChain()}
            {!(checkSendBC===false)  ? <></>:
              <>
              {errorF}
              <div><Button variant="secondary" size="sm" style={{marginTop:'1em'}} onClick={() => {sendTransaction?.()}}> send to blockchain</Button></div>
              
              {isLoading && <div>Check Wallet</div>}
              {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
              </>
            }

          </>
        )}
        
        
        
          </div>

          
      </>
    );
};

export default Web3Js;