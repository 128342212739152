import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

const AnnouncementPopup = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const { t} = useTranslation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  async function submit() {
    const form = new FormData();
    form.append("username", email);

    
    let URL_API = process.env.REACT_APP_SERVER_URL

    const options = {
      method: 'POST',
      url: URL_API + 'user/newsletters',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };
  
    await axios.request(options).then(function (response) {
        console.log(response);
    })
  }

  const handleSubmit = () => {
    submit()
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>

      <Modal.Header closeButton style={{border: 0,}} className='annoncement'>
        <Modal.Title>{t("DICO_WORD_ANNOUNCEMENT")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12} md={6}>
            <span>{t("DICO_NIO_KNOW_PRE_SALE")}</span>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder={t("DICO_WORD_EMAIL")}
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Button variant="danger" style={{marginTop: '1rem', width: '100%'}} onClick={handleSubmit}>
            {t("DICO_WORD_REGISTER")}
            </Button>
          </Col>
          <Col sm={12} md={6}>
          <div className="demi-cercle">
            <div>
            <FontAwesomeIcon icon={faBullhorn} size={'5x'}/>
            </div>
          </div>

          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AnnouncementPopup;