import React from 'react'

import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';

const Community = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="page-section" style={{backgroundColor:'#fff', marginTop:'10rem'}}>
      <div className='container'>
        <div className="row text-center">
          <div className="col-lg-6 col-md-12">
            <div className='ntt_coin_container'>
              <img className="ntt_coin img-fluid" src="./img/ntt_coin.png" alt=""/>
              <img className="ntt_coin2 img-fluid" src="./img/ntt_coin.png" alt=""/>
              <img className="ntt_coin3 img-fluid" src="./img/ntt_coin.png" alt=""/>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div style={{textAlign: 'left'}}>
              <p style={{color:'#808080',fontStyle: 'bold',fontSize: '12pt'}}>{t("DICO_NIO_COMMUNITY")}</p>
              <h2 className='mt-4' style={{fontSize: '32pt'}}>NTT token</h2>
              <p style={{marginTop: '5rem'}}>{t("DICO_NIO_NTT_TOKEN")}</p>
              <a href='https://docs.ntity.io/ntt.php' style={{padding: '1rem 4rem 1rem 4rem'}} className='btn btn-outline-danger'>{t("DICO_WORD_MORE_INFO")}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community
