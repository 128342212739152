import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Accordion, Button, Card, Col, Collapse, Container, Form, InputGroup, ListGroup, Modal, Row , useAccordionButton } from 'react-bootstrap';

import "../../css/stepsProgressBar.css"; // Import CSS styles for the stepsProgressBar


import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Loader from '../../composent/shared/Loader';
import { useAuthorization } from '../../hook/AuthProvider';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import Brand from '../../composent/ntityDoc/brand';
import Gallery from '../../composent/shared/gallery';
import { useSysVar } from '../../hook/SysVar';






const StepProgressBar = ({ steps, current }) => {

    var elem = steps.map((step,key) => {
        var classN = 'step-wizard-item';
        console.log(current);
        console.log(step.id);
        if(current===step.id){
            classN += ' current-item'
        }
        return (<li className={classN}>
            <span class="progress-count">{step.icon}</span>
            <span class="progress-label">{step.text}</span>
        </li>)
        
    })
    return(
        <section className="step-wizard">
            <ul className="step-wizard-list">
                {elem}
            </ul>
        </section>
    )
}
const Status = () => {
    const { authToken, signOut } = useAuthorization();
    const { t } = useTranslation();
    const params = useParams();

    const navigate = useNavigate();
    
    const [market, setmarket] = useState();
    
    const [loading, setloading] = useState(true);
    const [steps, setSteps] = useState();
    
    const [msgError, setMsgError] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');

    const [validated, setValidated] = useState(false);

    const [Doc, setDoc] = useState();
    
    const { sysVarGroup } = useSysVar();

    useEffect(() => {
        getSell();
        sysVarGroup('Status').then((rep)=>{ 
            var tmp={};
            rep.map((value)=>{
                tmp[value['Name']] = value
            })
            var stepsT = [
                {'id':tmp['Processing']['ID_Var'],    'text':tmp['Processing']['Name'],     icon:''},
                {'id':tmp['Confirmed']['ID_Var'],     'text':tmp['Confirmed']['Name'],      icon:''},
                {'id':tmp['Transfering']['ID_Var'],   'text':tmp['Transfering']['Name'],    icon:''},
                {'id':tmp['Resolved']['ID_Var'],      'text':tmp['Resolved']['Name'],       icon:''},
                {'id':tmp['Pending']['ID_Var'],       'text':tmp['Pending']['Name'],        icon:''},
                {'id':tmp['Success']['ID_Var'],       'text':tmp['Success']['Name'],        icon:''},
            ]
            setSteps(stepsT)
        });
    }, [authToken]);
    
    
    
    async function getSell() {
        const form = {};
        let options;
        form["TX"] = params.TX;

        options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "marketplace/sell",
        headers: {
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
                signOut();
            }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
                
                if(response.data.success === true){
                    setmarket(response.data.data);
                    console.log(response.data.data);
                }else if ( response.data.status=== 404 ) {
                    setmarket(t('DICO_WORD_NOT_SELL'));
                }else{
                    setMsgError(response.data.msg);
                }
                setloading(false);
                getNtityDoc();
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    
    

    async function getNtityDoc() {
        const form = {};
        let options;
        let action;
        action = "ntity";
        form["bca"] = params.TX;

        options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
            Authorization: "Bearer " + authToken,
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
              signOut();
            }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
              
              if(Array.isArray(response.data.data)){
                setDoc(response.data.data[0]);
              }else{
                setDoc(response.data.data);
            }
              setloading(false);
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }



    return (
        <>
                <AlertDismissible variant="danger"  text={msgError}/>
                <AlertDismissible variant="success"  text={msgSuccess}/>
            <div className='mt-4 row' style={{padding:'2rem'}}>
                {/* {loading === true ? <Container className='text-center mb-4'><Loader /></Container> : <FormEvent doc={Doc}/>} */}
                {loading === true || Doc === undefined ? <Container className='text-center mb-4'><Loader /></Container> : 
                    <>
                        {market === t('DICO_WORD_NOT_SELL') ? <Container className='text-center mb-4'><h2>{t('DICO_WORD_NOT_SELL')}</h2></Container> : 
                            <>
                                <div  id="searchRes">
                                    <div style={{height: 'fit-content'}}><Brand doc={Doc} small={true} /></div>
                                </div>
                                <Col sm={8} style={{padding:'3rem', fontSize:'170%'}}>
                                    <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> <span style={{'text-transform':'capitalize'}}>{t('DICO_WORD_STATUS')}</span> : {Doc['Name']}</h2>
                                    { steps === undefined && market===undefined? <Container className='text-center mb-4'><Loader /></Container> : 
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Etapes</Card.Title>
                                                <StepProgressBar steps={steps} current={market.status}/>
                                                <Card.Text>
                                                    Confirmation de la reception du montant de l'acheteur. Veuilez procéder à l'envoie du certificat
                                                </Card.Text>
                                                <Button variant="primary">Envoyer le certificat</Button>
                                            </Card.Body>
                                        </Card>
                                    }
                                </Col>
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}

export default Status;
