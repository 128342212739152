import React, { useState } from 'react'
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row
} from 'react-bootstrap';
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';

import validator from "validator";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQRCode } from 'react-qrcode'


const ProfilCorp = () => {
	const {t} = useTranslation();
	const { authUser, authToken, wallet,signOut } = useAuthorization();
	const [lang, setLang] = useLocalStorage("lang",'');
	const [showPWD, setShowPWD] = useState(false);
	const [activateFA, setActivateFA] = useState(false);
	const [qr, setQR] = useState('NA');
	const [api] = useLocalStorage("api",'');
	const data = useQRCode(qr);
 

	const [langOption, setLangOption] = useState();


	const handleClosePWD = () => setShowPWD(false);
	const handleShowPWD = () => {
		setShowPWD(true);
		setError('');
	}

	const [showMAIL, setShowMAIL] = useState(false);

	const handleCloseMAIL = () => setShowMAIL(false);
	const handleShowMAIL = () => {
		setShowMAIL(true);
		setError('');
	}

	const [showLANG, setShowLANG] = useState(false);

	const handleCloseLANG = () => setShowLANG(false);
	const handleShowLANG = () => {
		setShowLANG(true);
		setError('');
	}

	const [show2FA, setShow2FA] = useState(false);

	const handleClose2FA = () => setShow2FA(false);
	const handleShow2FA = () => {
		setShow2FA(true);
		setError('');
	}

	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const [langs, setLangs] = useState([]);

	const [msgError, setMsgError] = useState("");
	const [formData, setFormData] = useState({
		password: "",
		cpassword: ""
	});

	const [formData2fa, setFormData2fa] = useState('');

	const [formDataMail, setFormDataMail] = useState({
		mail: "",
	});

	const [formDataLang, setFormDataLang] = useState({
		lang: "",
	});

	
	const handleInputDataMail = input => e => {
		const { value } = e.target;
		
		setFormDataMail(prevState => ({
			...prevState,
			[input]: value
		}));
	}
	
	const handleInputDataPWD = input => e => {
		const { value } = e.target;

		setFormData(prevState => ({
			...prevState,
			[input]: value
		}));
	}

	const handleInputDataLANG = input => e => {
		const { value } = e.target;

		setFormDataLang(prevState => ({
			...prevState,
			[input]: value
		}));
	}

	useEffect(()=>{
		if(authToken !== null && authToken !== undefined && authToken !== 'undefined'){
			searchLANG();
		}
	},[authToken]);

	const submitFormData2FA = (e) => {
		e.preventDefault();
		if (!validator.isNumeric(formData2fa)) {
			setError(true);
			setMsgError("2Fa is required");
			return false;
		} 
		submit2FA();
	};

	const submitFormDataMail = (e) => {
		e.preventDefault();
		setError(false);

		if (validator.isEmpty(formDataMail.mail)) {
			setError(true);
			setMsgError("New mail is required");
			return false;
		} 

		if (!validator.isEmail(formDataMail.mail)) {
			setError(true);
			setMsgError("The format of the mail isn't valid");
			return false;
		} 

		if(!error){
			submitMail();
		}
	};
	
	const submitFormDataPWD = (e) => {
		e.preventDefault();
		setError(false);

		if (validator.isEmpty(formData.password)) {
			setError(true);
			setMsgError("New password is required");
			return false;
		} 

		if (validator.isEmpty(formData.cpassword)) {
			setError(true);
			setMsgError("Confirm password is required");
			return false;
		} 

		if(formData.password.length < 8){
			setError(true);
			setMsgError("Your password need min 8 char");
			return false;
		}

		if(formData.password !== formData.cpassword){
			setError(true);
			setMsgError("The password and the confirm password isn't equal");
			return false;
		}

		if(!error){
			submitPWD();
		}
	};

	const submitFormDataLANG = (e) => {
		e.preventDefault();
		setError(false);

		if (validator.isEmpty(formDataLang.lang)) {
			setError(true);
			setMsgError("New lang is required");
			return false;
		} 

		if(!error){
			submitLANG();
		}
	};

	useEffect(() => {
		if(authToken !== undefined && authToken !== null){
			getUserInfo();
		}
	},[authToken])

	useEffect(() => {
		if(show2FA && authToken !== undefined){
			const options = {
				method: 'GET',
				url: api + 'user/TOTP',
				headers: {
					Authorization: "Bearer " + authToken,
				},
			};
	
			axios.request(options).then(function (response) {
				if(response.data.success){
					setQR("otpauth://totp/Ntity.io?secret="+response.data.data);
				}
			}).catch(function (error) {
				setMsgError(error);
				setError(true);
			});
		}
	},[show2FA,authToken]);

	async function searchLANG() {
		const form ={};
		form.bca = 'Language';
		const options = {
			method: 'GET',
			url: api + 'misc/sysVarGroup',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
	
		await axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/sysVarGroup"){
				signOut();
			}else{
				setLangs(response.data.data);
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/sysVarGroup"){
			  setMsgError(errt.data.msg);
			  setError(true);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			  setError(true);
			}
		});
	}

	async function submit2FA() {
		const form = new FormData();
		form.append('totp', formData2fa);
	
		const options = {
			method: 'POST',
			url: api + 'user/TOTP',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		await axios.request(options).then(function (response) {
			if(response.data.success){
				setSuccess(true);
				setShow2FA(false);
				setMsgError(response.data.msg);
				setActivateFA(true);
			}else{
				setError(true);
				setMsgError('Your 2FA isn\'t valid');
			}
		}).catch(function (error) {
			setMsgError(error);
			setError(true);
		});
	}

	async function delete2FA() {
		const options = {
			method: 'DELETE',
			url: api + 'user/TOTP',
			headers: {
				Authorization: "Bearer " + authToken,
			},
		};
		
		await axios.request(options).then(function (response) {
			if(response.data.success){
				setSuccess(true);
				setMsgError(response.data.msg);
				setActivateFA(false);
			}else{
				setError(true);
				setMsgError(response.data.msg);
			}
		}).catch(function (error) {
			setMsgError(error);
			setError(true);
		});
	}

	async function submitLANG() {
		const form = new FormData();
		form.append("idLang", formDataLang.lang);

		const options = {
			method: 'PUT',
			url: api + 'user/lang',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
	
		await axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/setUserLang"){
				signOut();
			} else if (response.data.data[0] === false) {
				setMsgError(response.data.data[1]);
				setError(true);
			} else {
				setError(false);
				setSuccess(true);
				setMsgError(response.data.data[1]);
				setLang(formDataLang.lang);
			}
			handleCloseLANG();
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/setUserLang"){
			  setMsgError(errt.data.msg);
			  setError(true);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			  setError(true);
			}
		});
	}

	async function submitMail() {
		const form = new FormData();
		form.append("username", formDataMail.mail);

		const options = {
			method: 'POST',
			url: api + 'user/username',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
	
		await axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/username"){
				signOut();
			} else if (response.data.data[0] === false) {
				setMsgError(response.data.data[1]);
				setError(true);
			} else {
				setError(false);
				setSuccess(true);
				setMsgError(response.data.data[1]);
			}
			handleCloseMAIL();
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/username"){
			  setMsgError(errt.data.msg);
			  setError(true);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			  setError(true);
			}
		});
	}

	async function submitPWD() {
		const form = new FormData();
		form.append("password", formData.password);

		const options = {
			method: 'PUT',
			url: api +  'user/password',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
	
		await axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/password"){
				signOut();
			} else if (response.data.status === 400) {
				setMsgError(response.data.msg);
				setError(true);
			} else {
				setError(false);
				setSuccess(true);
				setMsgError(response.data.msg);
			}
			handleClosePWD();
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/password"){
			  setMsgError(errt.data.msg);
			  setError(true);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			  setError(true);
			}
		});
	}

	async function getUserInfo() {
		const options = {
			method: 'GET',
			url: api + 'user/info',
			headers: {
				Authorization: "Bearer " + authToken,
			},
		};
	
		await axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/info"){
				signOut();
			} else {
				setActivateFA(response.data.data.SecureLevel <= 5);
				if(response.data.data.SecureLevel <= 5){
					const options = {
						method: 'GET',
						url: api + 'user/TOTP',
						headers: {
							Authorization: "Bearer " + authToken,
						},
					};
			
					axios.request(options).then(function (response) {
						if(response.data.success){
							setQR("otpauth://totp/Ntity.io?secret="+response.data.data);
						}
					}).catch(function (error) {
						var errt;
						if (error.response) {
						  errt=error.response;
						} else if (error.request) {
						  errt=error.request.response;
						} else {
						  setMsgError(error.message);
						  setError(true);
						  return null;
						}
						if(errt.data.status === 401 && errt.data.path === "errorToken/TOTP"){
						  setMsgError(errt.data.msg);
						  setError(true);
						  signOut();
						}else{
						  setMsgError(errt.data.msg);
						  setError(true);
						}
					});
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/info"){
			  setMsgError(errt.data.msg);
			  setError(true);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			  setError(true);
			}
		});
	}

	useEffect(()=>{
		if (langs.length !== 0 ) {
			let tmp  = langs.map((option, index) => {        
				if(option.Value !== '999'){
					return (
						<option key={index} value={option.Value}>
							{option.Name}
						</option>
					)
				}
			})
			setLangOption(tmp);
		}
	},[langs]);


	
	return (
		<Container>
			<div className='containerTitle'> {t("DICO_WORD_PROFILE")}</div>
			<div>
				<Card style={{ marginTop: 50, marginBottom: 100 }}>
					<Card.Body>
						<h3>{t("DICO_WORD_USER_INFORMATION")}</h3>
						{success && <Alert variant="success">{msgError}</Alert>}
						{/* <br/>
						<Row>
							<Col colSpan="2">{t("DICO_WORD_YOUR_WALLET")} : {wallet !== null && wallet !== "undefined" ?wallet:t("DICO_WORD_ADMIN_CONTACT")}</Col>
						</Row> */}
						<br/>
						<Row>
							<Col>{t("DICO_WORD_EMAIL")} : {authUser}</Col>
							<Col><Button variant="outline-secondary" onClick={handleShowMAIL}>{t("DICO_WORD_MODIFY")} {t("DICO_WORD_EMAIL")}</Button><Button variant="outline-secondary" onClick={handleShowPWD}>{t("DICO_WORD_MODIFY")} {t("DICO_WORD_PASSWORD")}</Button></Col>
						</Row>
						<br/>
						<Row>
							<Col>{t("DICO_WORD_LANG")} : {lang}</Col>
							<Col><Button variant="outline-secondary" onClick={handleShowLANG}>{t("DICO_WORD_MODIFY")} {t("DICO_WORD_LANG")}</Button></Col>
						</Row>
						<Row>
							{activateFA && (
							<div className="col-md-6">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">2FA</h5>
										<img src={data} alt='QR Code of 2fa'/>
										<Button variant="outline-secondary" onClick={delete2FA}>disabled 2FA</Button>
									</div>
								</div>
							</div>
							)}
							{!activateFA && (
								<div className="col-md-6">
									<div className="card">
										<div className="card-body">
											<h5 className="card-title">2FA</h5>
											<Button variant="outline-secondary" onClick={handleShow2FA}>enable 2FA</Button>
										</div>
									</div>
								</div>
							)}
						</Row>
					</Card.Body>
				</Card>
			</div>
			<Modal show={showPWD} onHide={handleClosePWD}>
				<Modal.Header closeButton>
					<Modal.Title>{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_PASSWORD")}</Modal.Title>
				</Modal.Header>
				{error && <Alert variant="danger">{msgError}</Alert>}
				<Form onSubmit={submitFormDataPWD}>
					<Modal.Body>
						<Form.Group className="mb-3">
							<Form.Label>{t("DICO_WORD_PASSWORD")}</Form.Label>
							<Form.Control name="password" defaultValue={formData.password} type="password" placeholder={t("DICO_WORD_PASSWORD")} onChange={handleInputDataPWD("password")} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>{t("DICO_WORD_PASSWORD_REPEAT")}</Form.Label>
							<Form.Control name="cpassword" defaultValue={formData.cpassword} type="password" placeholder={t("DICO_WORD_PASSWORD_REPEAT")} onChange={handleInputDataPWD("cpassword")} />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-danger" onClick={handleClosePWD}>
							{t("DICO_WORD_CANCEL")}
						</Button>
						<div className='text-center'>
							<Button variant="outline-secondary" type="submit">{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_PASSWORD")}</Button>
						</div>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={show2FA} onHide={handleClose2FA}>
				<Modal.Header closeButton>
					<Modal.Title>Set your 2FA</Modal.Title>
				</Modal.Header>
				{error && <Alert variant="danger">{msgError}</Alert>}
				<Form onSubmit={submitFormData2FA}>
					<Modal.Body>
						<img src={data} alt='QR Code of 2fa'/>
						<Form.Group className="mb-3">
							<Form.Label>2FA</Form.Label>
							<Form.Control name="2fa" defaultValue={formData2fa} type="text" placeholder={"2FA"} onChange={(text)=> setFormData2fa(text.target.value)} />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-danger" onClick={handleClose2FA}>
							{t("DICO_WORD_CANCEL")}
						</Button>
						<div className='text-center'>
							<Button variant="outline-secondary" type="submit">{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_PASSWORD")}</Button>
						</div>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showLANG} onHide={handleCloseLANG}>
				<Modal.Header closeButton>
					<Modal.Title>{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_LANG")}</Modal.Title>
				</Modal.Header>
				{error && <Alert variant="danger">{msgError}</Alert>}
				<Form onSubmit={submitFormDataLANG}>
					<Modal.Body>
						<Form.Group className="mb-3">
							<Form.Label>{t("DICO_WORD_LANG")}</Form.Label>
							<Form.Select aria-label="Default select example" onChange={handleInputDataLANG("lang")}>
								{langOption}
							</Form.Select>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-danger" onClick={handleCloseLANG}>
							{t("DICO_WORD_CANCEL")}
						</Button>
						<div className='text-center'>
							<Button variant="outline-secondary" type="submit">{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_LANG")}</Button>
						</div>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showMAIL} onHide={handleCloseMAIL}>
				<Modal.Header closeButton>
					<Modal.Title>{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_EMAIL")}</Modal.Title>
				</Modal.Header>
				{error && <Alert variant="danger">{msgError}</Alert>}
				<Form onSubmit={submitFormDataMail}>
					<Modal.Body>
						<Form.Group className="mb-3">
							<Form.Label>{t("DICO_WORD_EMAIL")}</Form.Label>
								<Form.Control name="email" defaultValue={formData.email} type="email" placeholder={t("DICO_WORD_EMAIL")} onChange={handleInputDataMail("mail")} />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-danger" onClick={handleCloseMAIL}>
							{t("DICO_WORD_CANCEL")}
						</Button>
						<div className='text-center'>
							<Button variant="outline-secondary" type="submit">{t("DICO_WORD_CHANGE_YOUR")} {t("DICO_WORD_EMAIL")}</Button>
						</div>
					</Modal.Footer>
					</Form>
			</Modal>
		</Container>
	)
}


export default ProfilCorp;
