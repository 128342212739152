import React, { useState, useEffect } from 'react'
import { Form, Table, Alert, Button, Row, Card, Col, Spinner, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong, faRightLong } from '@fortawesome/free-solid-svg-icons';
import { useLocalStorage } from '../../hook/useLocalStorage';
import AlertDismissible from '../shared/AlertDismissible';


const ListBoxP = ({option,value,changeHandler}) => {
    const [bank, setBank] = useState([]);
    const [resp, setResp] = useState([]);
    const [selB, setSelB] = useState(null);
    const [selR, setSelR] = useState(null);
    
    useEffect(() => {
        optTri()
      },[option]);
    const optTri = () => {
        var tmResp = [];
        var tmBank = [];
        option.map((val) => {
            if (value[val.ID_Entity] == true) {
                tmBank.push(val)
            }else{
                tmResp.push(val)
            }
            setBank(tmBank);
            setResp(tmResp);
        });
    }
    const switcherLb = (ct) => {
        var tmBank = [...bank];
        var tmResp = [...resp];
        var tmResul = {};
       if(ct==='right' && selR !== null){
            var index = tmResp.indexOf(selR);
            var tmp =tmResp.splice(index, 1); 
            tmBank.push(tmp[0]);
            setSelR(null);
            setSelB(null);
            setBank(tmBank);
            setResp(tmResp);
       }
       if(ct==='left' && selB !== null){
            var index = tmBank.indexOf(selB);
            var tmp =tmBank.splice(index, 1); 
            tmResp.push(tmp[0]);
            setSelB(null);
            setSelR(null);
            setBank(tmBank);
            setResp(tmResp);
       }
        tmBank.map((entry) => {
            tmResul[entry.ID_Entity] =true;
        })
        tmResp.map((entry) => {
            tmResul[entry.ID_Entity] =false;
         })
         changeHandler(tmResul)
    }

    return(
        <Row className='no-gutters' >
            <Form.Group as={Col}  className='text-center no-gutters'>
                <Form.Select  multiple className="select-list" id="select2" size="10" onBlur={(ev) =>{setSelR(ev.target.value);}}>
                    
                    {resp.map((entry) => {
                        return <option value={entry['ID_Entity']}>{entry['Name']}</option>;
                    })}
                    </Form.Select>
            </Form.Group>
            <Col sm={1} className="options">
                <Button variant='outline-success' size="sm" onClick={(ev) =>{switcherLb('right')}}><FontAwesomeIcon icon={faRightLong} /></Button>
                <Button  variant='outline-danger' size="sm" onClick={(ev) =>{switcherLb('left')}}><FontAwesomeIcon icon={faLeftLong} /></Button>
            </Col>
            <Form.Group as={Col}  className='text-center'>
                <Form.Select  multiple className="select-list" id="select1" size="10" onBlur={(ev) =>{setSelB(ev.target.value);}}> 
                    {bank.map((entry) => {
                        return <option value={entry['ID_Entity']}>{entry['Name']}</option>;
                    })}
                </Form.Select>
            </Form.Group>
        </Row>
    )
}


const ModalMember = ({ct, UsernameM='', RightCorp='', allGrp='',show,onHide,title}) => {
    const { t } = useTranslation();
    const { authToken, signOut } = useAuthorization();
    const [username, setusername] = useState("");
    const [firstname, setfirstname] = useState([]);
    const [name, setname] = useState("");
    const [right, setRight] = useState({});
    const [rightCherged, setRightCherged] = useState(false);
    const [location, setLocaton] = useState({});
    const [Err, setErr] = useState("");
    const [validated, setValidated] = useState(false);
    const [modifSku, setmodifSku] = useState(false);
    const [api, setAPI] = useLocalStorage("api",'');
	const [msgError, setMsgError] = useState('');

	const [member, setMember] = useLocalStorage("member",'');

    useEffect(() => {
        if(ct === 'edit'){
            setRightCherged(false);
            setusername(UsernameM['Username'])
            if(UsernameM['Name']!==undefined){
                setname(UsernameM['Name'])
                setfirstname(UsernameM['Firstname'])
            }
            setmodifSku(false);
            getMember(UsernameM['ID_Entity'])
        }else{
            setmodifSku(true);
            setRightCherged(true);
        }
      },[ct,UsernameM]);


	  const hide = () => {
		setname("");
        setfirstname("");
        setmodifSku(true);
        setRightCherged(true);
        setusername("");
        setRight({});
        setLocaton({});

		setValidated(false);
        onHide();
    }

      
      async function getMember(ID_Entity) {
		const form = {};
        form['id']=ID_Entity;
        const options = {
          method: 'GET',
          url: api +'corporate/member',
          headers: {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          params: form
        };
    
        axios.request(options).then(function (response) {
          if(response.data.status === 401 && response.data.path === "errorToken/member"){
            signOut();
          } else {
            
			if (response.data.status !== 200 || response.data.success === false) {
                setMsgError(response.data.msg);
			}else{
                setRight(response.data.data.Right);
                setLocaton(response.data.data.Group);
                setRightCherged(true);
            }
          }
        }).catch(function (error) {
            var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/member"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
      }


    async function submitForm() {
        const form = new FormData();
        if(username === ''){
            setErr('username is empty');
            return false;
        }
        form.append("Username", username);
        form.append("Firstname", firstname);
        form.append("Name", name);
        if(Object.keys(right).length !== 0){
            form.append("Right", JSON.stringify(right));
        }
        if(Object.keys(location).length !== 0){
            form.append("Group", JSON.stringify(location));
        }
        
        
        var moduleU
        if(ct==='edit'){
            moduleU = 'PUT'
            form.append("id", UsernameM['ID_Entity']);
        }else{
            moduleU = 'POST'
        }

        const options = {
            method: moduleU,
            url: api + 'corporate/member',
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        },
        data: form
        };
        
        axios.request(options).then(function (response) {
        if(response.data.status === 401 && response.data.path === "errorToken/member"){
            signOut(); 
        } else {
            if(response.data.data === true){
                hide();
            }else{
                setErr(response.data.msg);
            }
        }
        }).catch(function (error) {
            var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/member"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
    }
  
    const handleSubmit = (event) => {
      const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
      if (form.checkValidity() === true) {
        submitForm()
      }
      setValidated(true);
    };

    const rightCard = () =>{
        var inputRight = t("DICO_WORD_CORPORATE_NO_RIGHT")
        var tmpright={...right};
        var arrR = {}
        if(RightCorp.length > 1){
            inputRight = RightCorp.map((val,index) =>{
                var inputS,inputI,inputD,inputU = '';

                if(right.hasOwnProperty(val['ID_Block']) === false ){
                    tmpright[val['ID_Block']] = {'S':false,'I':false,'U':false,'D':false};
                    setRight(tmpright);
                }
                if(val.S===1 && ['corporate', 'eventCustom'].includes(val.Template)){
                    inputS = <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    disabled={!member.Right[val['ID_Block']].S}
                    defaultChecked={Boolean(tmpright[val['ID_Block']]['S'])}
                    onChange={(ev)=>{tmpright[val['ID_Block']]['S']=ev.target.checked; setRight(tmpright);}}
                    />;
                }
                if(val.I===1 && ['corporate', 'eventCustom'].includes(val.Template)){
                    inputI =  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    disabled={!member.Right[val['ID_Block']].I}
                    defaultChecked={Boolean(tmpright[val['ID_Block']]['I'])}
                    onChange={(ev)=>{tmpright[val['ID_Block']]['I']=ev.target.checked; setRight(tmpright);}}
                    />
                }
                if(val.U===1 && ['corporate','corpoApp'].includes(val.Template)){
                    inputU =  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    disabled={!member.Right[val['ID_Block']].U}
                    defaultChecked={Boolean(tmpright[val['ID_Block']]['U'])}
                    onChange={(ev)=>{tmpright[val['ID_Block']]['U']=ev.target.checked; setRight(tmpright);}}
                    />
                }
                if(val.D===1 && ['corporate','corpoApp' ].includes(val.Template)){
                    inputD = <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    disabled={!member.Right[val['ID_Block']].D}
                    defaultChecked={Boolean(tmpright[val['ID_Block']]['D'])}
                    onChange={(ev)=>{tmpright[val['ID_Block']]['D']=ev.target.checked; setRight(tmpright);}}
                    />
                }
                if(!(val.Description in arrR)){
                    arrR[val.Description]=[];
                }
                arrR[val.Description].push(<tr>
                    <td  className='text-left'>
                        {val['Name']}
                    </td>
                    <td  className='text-center'>
                        {inputS}
                    </td>
                    <td  className='text-center'>
                        {inputI}
                    </td>
                    <td  className='text-center'>
                        {inputU}
                    </td>
                    <td  className='text-center'>
                        {inputD}
                    </td>
                </tr> )
                
            });
        }
        return( 
            <>
            {Object.entries(arrR).map((entry) => { return (<Table striped>
                <thead>
                    <tr>
                        <th style={{width:'20%'}}>{entry[0]===undefined || entry[0] === null ? '': entry[0]}</th>
                        <th className='text-center'>VIEW</th>
                        <th className='text-center'>CREATE</th>
                        <th className='text-center'>MODIFY</th>
                        <th className='text-center'>REMOVE</th>
                    </tr>
                </thead>
                <tbody>
                    {entry[1]}
                    
                </tbody>
            </Table>)})}
            </>
        );
    }

    const locationCard = () =>{
        var inputGRP = t("DICO_WORD_CORPORATE_NO_GROUP")
        var tmprloc={...location};
        if(allGrp.length > 0){
            
            inputGRP = 
                    <>
                        { rightCherged===false ? 
                        
                            (
                                <Spinner  id={'spinner'} animation="border" role="status" style={{'display':'none'}}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ):
                            (
                                <Col className='no-gutters' >
                                   <ListBoxP option={allGrp} value={tmprloc} changeHandler={setLocaton}/>
                                </Col>  
                            )
                        }
                    </>
        }
        return( 
            inputGRP
        );
    }

    return(
        <>
        	<Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter" >
		        <AlertDismissible variant="danger"  text={msgError}/>
                <Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {Err!=='' ? <Alert  variant={'danger'}>{Err}</Alert>:''}
                        <Row>
                            <Col>
                            <Form.Label>{t("DICO_WORD_EMAIL")}</Form.Label>
                                {modifSku ===true? 
                                    <Form.Control required type="email" value={username}  name="username" onChange={(ev) =>setusername(ev.target.value)}/>
                                : 
                                    <Form.Control disabled type="email" value={username}  name="username"/>
                                }

                            </Col>
                            <Col>
                            <Form.Label>{t("DICO_WORD_NAME")}</Form.Label>
                                <Form.Control type="text" value={name} name="name" onChange={(ev) =>{setname(ev.target.value)}}/>
            
                            </Col>
                            <Col>
                            <Form.Label>{t("DICO_WORD_FIRSTNAME")}</Form.Label>
                                <Form.Control type="text" value={firstname} name="firstname" onChange={(ev) =>{setfirstname(ev.target.value)}}/>
                        
                            </Col>
                        </Row>

                            {!(RightCorp==='')  && 
                                <>
                                    <Form.Label>{t("DICO_WORD_RIGHT")}</Form.Label>
                                    <Card>
                                        {rightCard()}
                                    </Card>
                                </>
                            }
                            {!(allGrp==='')  && 
                                <>
                                    <Form.Label>{t("DICO_WORD_GROUP")}</Form.Label>
                                    <Card>
                                        {locationCard()}
                                    </Card>
                                </>
                            }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="outline-info" >{t('DICO_WORD_SAVE')}</Button>
                        <Button onClick={hide} variant="outline-primary">{t('DICO_WORD_CLOSE')}</Button>
                    </Modal.Footer>
                </Form>

		    </Modal>
        </>
    )
}
export default ModalMember;