import React, { useState } from 'react'
import {
  Container,
  Button,
  Card,
  Form,
  Col,
  Row
} from 'react-bootstrap';

import axios from 'axios';
import Footer from '../composent/index/footer';
import validator from "validator";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../composent/shared/AlertDismissible';
import { useAuthorization } from '../hook/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hook/useLocalStorage';


const ChangePWD = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const [msgError, setMsgError] = useState("");
  const { signOut } = useAuthorization();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [api] = useLocalStorage("api",'');


  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
  });
  
  const handleInputData = input => e => {
    const { value } = e.target;
    
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  
  const submitFormData = (e) => {
    e.preventDefault();
    setError(false);
    
    if (validator.isEmpty(formData.password)) {
      setError(true);
      setMsgError("Password is required");
      return false;
    } 

    if (validator.isEmpty(formData.cpassword)) {
      setError(true);
      setMsgError("Confirm password is required");
      return false;
    } 

    if(formData.password !== formData.cpassword){
      setError(true);
      setMsgError("Password and confirm password must be equal");
      return false;
    }

    if(!error){
      submit();
    }
  };
  
  async function submit() {
    const form = new FormData();
    form.append("password", formData.password);

    
    let URL_API = process.env.REACT_APP_SERVER_URL
    if(api !== ''){
      URL_API = api;
    }

    const options = {
      method: 'PUT',
      url: URL_API + 'user/password',
      headers: {
        Authorization: "Bearer " + state.user,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };
  
    await axios.request(options).then(function (response) {
      if(response.data.status === 401 && response.data.path === "errorToken/password"){
        signOut();
      } else {
        if(response.data.status === 201){
          setSuccess(true);
          setMsgError(response.data.data);
          navigate('/login') 
        }else{
          setMsgError(response.data.msg);
          setError(true);
        }
      }
    }).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
				setMsgError(error.message);
        setError(true);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/password"){
				setMsgError(errt.data.msg);
        setError(true);
			  signOut();
			}else{
				setMsgError(errt.data.msg);
        setError(true);
			}
     });
  }
  
  return (
    <>
          <Container>
            <Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="card-login">
              <Card.Body>
              {error && <AlertDismissible variant="danger"  text={msgError} />}
              {success && <AlertDismissible variant="success"  text={msgError} />}
              <div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase'}}>
                <img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
                {t("DICO_WORD_PASSWORD_RESTORE")}
              </div>
                <Form onSubmit={submitFormData}>
                  <Form.Group className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i><FontAwesomeIcon icon={faKey}/></i>
                      </span>
                    </div>
                    <Form.Control name="password" defaultValue={formData.password} type="password" placeholder={t("DICO_WORD_PASSWORD")} onChange={handleInputData("password")} />
                  </Form.Group>
                  <Form.Group className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i><FontAwesomeIcon icon={faKey}/></i>
                      </span>
                    </div>
                    <Form.Control name="cpassword" defaultValue={formData.cpassword} type="password" placeholder={t("DICO_WORD_PASSWORD_REPEAT")} onChange={handleInputData("cpassword")} />
                  </Form.Group>
                <Row>
                  <Col>
                    <Button variant="primary" type="submit" style={{width:'100%'}}>{t("DICO_WORD_CHANGE")}</Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
    </>
  );
}

export default ChangePWD;
