import React, { useState, useEffect } from 'react'
import { Container, Card,  Col, Form, Alert, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import ResultTable from '../shared/Result.table';
import Loader from '../shared/Loader';
import AlertDismissible from '../shared/AlertDismissible';


const NotificationManager = ({ct, UsernameM='',show,onHide,title}) => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
    const [resultAUsername, setResultAUsername] = useState("");
	const [api, setAPI] = useLocalStorage("api",'');
	const [rowUsername, setrowUsername] = useState([]);
	const [Err, setErr] = useState("");
    const [resultChannel, setresultChannel] = useState("");
	const [rowChannel, setrowChannel] = useState([]);
    const [msgError, setMsgError] = useState('');


    useEffect(() => {
        if(authToken !== null){
            get();
            getChannel();
        }
      },[authToken]);

      async function get() {
        const form = {};
          const options = {
              method: 'GET',
              url: api + 'corporate/notifictionBlock',
              headers: {
                  Authorization: "Bearer " + authToken,
                  'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
              },
              params: form
          };
          
          axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/notifictionBlock"){
                setMsgError(response.data.msg);
                  signOut();
              } else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
                      var tmprowUsername = [...response.data.data];
                      tmprowUsername.map((entry,index)=>{
                          tmprowUsername[index]['Name'] = entry.Name; 
                          tmprowUsername[index]['Description'] =  entry.Description; 
                          tmprowUsername[index]['Edit'] = <>
                              <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              defaultChecked={Boolean(entry.S)}
                              onChange={(ev)=>{submitForm(entry.ID_Block,Number(ev.target.checked));}}
                              />
                          </>
                      });
                      setrowUsername(tmprowUsername);
                      setResultAUsername(response.data.data);		
                    }else{
                        setMsgError(response.data.msg);
                      }
                    }
                  }).catch(function (error) {
                    var errt;
                    if (error.response) {
                      errt=error.response;
                    } else if (error.request) {
                      errt=error.request.response;
                    } else {
                      setMsgError(error.message);
                      return null;
                    }
                    if(errt.data.status === 401 && errt.data.path === "errorToken/notifictionBlock"){
                      setMsgError(errt.data.msg);
                      signOut();
                    }else{
                      setMsgError(errt.data.msg);
                    }
                  });
                }
  
      async function submitForm(ID_Block,value) {
          const form = new FormData();
          form.append("ID_Block", ID_Block);
          form.append("value", value);
  
          const options = {
              method: 'PUT',
              url: api + 'corporate/notificationRight',
              headers: {
                  Authorization: "Bearer " + authToken,
                  'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          data: form
          };
          
          axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/notifictionBlock"){
              signOut(); 
          } else {
            if((response.data.success === false)){
                  setResultAUsername("");
                  setMsgError(response.data.msg);
                  get();
              }
          }
        }).catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/notifictionBlock"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
          });
        }

      async function getChannel() {
          const options = {
              method: 'GET',
              url: api + 'corporate/channel',
              headers: {
                  Authorization: "Bearer " + authToken,
                  'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
              },
          };
          
          axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/channel"){
                setMsgError(response.data.msg);
                  signOut();
              } else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
                        var tmprowchannel = [];
                        Object.entries(response.data.data).map((entry)=>{
                            tmprowchannel.push({
                                'Name':entry[1].Name,
                                'Edit':<Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                defaultChecked={Boolean(entry[1].S)}
                                onChange={(ev)=>{submitChannel(entry[1].ID_Block,Number(ev.target.checked));}}
                                />,

                            });
                           
                        });
                        setrowChannel(tmprowchannel);
                        setresultChannel(response.data.data);		
                    }else{
                        setresultChannel(false);
                        setMsgError(response.data.msg);
                      }
                    }
                  }).catch(function (error) {
                    var errt;
                    if (error.response) {
                      errt=error.response;
                    } else if (error.request) {
                      errt=error.request.response;
                    } else {
                      setMsgError(error.message);
                      return null;
                    }
                    if(errt.data.status === 401 && errt.data.path === "errorToken/channel"){
                      setMsgError(errt.data.msg);
                      signOut();
                    }else{
                      setMsgError(errt.data.msg);
                    }
                  });
                }
  
      async function submitChannel(ID_Block,value) {
          const form = new FormData();
          form.append("ID_Block", ID_Block);
          form.append("value", value);
  
          const options = {
              method: 'PUT',
              url: api + 'corporate/channel',
              headers: {
                  Authorization: "Bearer " + authToken,
                  'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          data: form
          };
          
          axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/channel"){
                setMsgError(response.data.msg);
              signOut(); 
          } else {
            if((response.data.success === false)){
                    setresultChannel("");
                    setMsgError(response.data.msg);
                    getChannel();
                }
          }
        }).catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/channel"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
          });
        }

    const columns = [
		{ accessor: 'Name', label: '' },
		{ accessor: 'Description', label: ''},
		{ accessor: 'Edit', label: t('DICO_WORD_NOTIFICATION') }
	]
	const columnsChannel = [
		{ accessor: 'Name', label: '' },
		{ accessor: 'Edit', label: t('DICO_WORD_CHANNEL') }
	]

    return (
        <>
        <AlertDismissible variant="danger"  text={msgError}/>
            <h5 >{t("DICO_WORD_NOTIFICATION")}</h5>
            {Err!=='' ? <Alert  variant={'danger'}>{Err}</Alert>:''}
            <Row>
                {resultAUsername === '' ? 
                    <Container className='text-center'><Loader /></Container> 
                    :
                    <>
                        <Col className='groupedCol'>
                        <div className='groupedTitle' >{t("DICO_WORD_NOTIFICATION")} </div>
                        <ResultTable rows={rowUsername} columns={columns}/>
                        </Col>	   	
                    </>
                }
                {setrowChannel === '' ? 
                    <Container className='text-center'><Loader /></Container> 
                    :
                    <>
                        <Col className='groupedCol' lg={4}>
                        <div className='groupedTitle' >{t("DICO_WORD_CHANNEL")} </div>
                        <ResultTable rows={rowChannel} columns={columnsChannel}/>
                        </Col>	   	
                    </>
                }
            </Row>
            </>
      );
}

export default NotificationManager;