import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";


const Pagination = ({
  noOfPosts,
  postsPerPage,
  onPageChange,
  currentPage,
  onPreviousClick,
  onNextClick,
}) => {
  const noOfPages = Math.ceil(noOfPosts/ postsPerPage);
  const pages = _.range(1, noOfPages + 1);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  },[currentPage]);

  let pageCutLow = currentPage - 2;
  let pageCutHigh = currentPage + 2;

  const pageLink = ()=>{
    return pages.map((page,index) => {
      if(index=== 2 && index<pageCutLow-1 || index=== pages.length-2 && index>pageCutHigh-1 ){
        return (
          <li
            key={page}
            className="page-item"
          >
            <a
              className="page-link"
              style={{ cursor: "pointer" }}
            >
              ...
            </a>
          </li>
          )
        }
      if(index===0 ||index>=pageCutLow-1 && index<=pageCutHigh-1 || index===pages.length-1){
        return (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a
              className="page-link"
              style={{ cursor: "pointer" }}
              onClick={() => onPageChange(page)}
            >
              {page}
            </a>
          </li>
          )
      }
    })
    
    
  }
  
  if (noOfPages === 1 || noOfPages === 0) return null;
  return (
    <nav>
      <ul className="pagination">
        {currentPage !== 1 && (
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: "pointer" }}
              onClick={onPreviousClick}
            >
              {t('DICO_NIO_PREV_PAGE')}
            </a>
          </li>
        )}
        {pageLink()}
        {currentPage !== noOfPages && (
          <li className="page-item">
            <a
              className="page-link"
              style={{ cursor: "pointer" }}
              onClick={onNextClick}
            >
              {t('DICO_WORD_NEXT')}
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};
export default Pagination;