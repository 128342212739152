import React, { useState, useEffect } from 'react'
import { Form,  Alert, Button, Row, Col, Modal, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import validator from "validator";
import { useNavigate, useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode';

const ModalWalletInfo = ({show,onHide,title, wallet}) => {
    const navigate = useNavigate();
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [Err, setErr] = useState("");
    const [balance_ntt, setBalanceNtt] = useState(0); // Solde en ETH
    const [balance_ntth, setBalanceNtth] = useState(0); // Solde en ETH

	const params = useParams();
    const [msgError, setMsgError] = useState("");

    const hide = () => {
        onHide()
    }
      
    useEffect(() => {
        if(wallet != null){
            getNtt();
            getNtth();
        } 
    },[wallet])
     
    async function getNtt() {
        const form = new FormData();

        const options = {
        method: 'GET',
        url: 'https://blockscout.ntity.io/api?module=account&action=balance&address=' + wallet,
        };

        axios.request(options).then(function (response) {
        console.log(response);
        setBalanceNtt(response.data.result / Math.pow(10,18));
        }).catch(function (error) {
        console.error(error);
        });
    }

    async function getNtth() {
        const form = new FormData();

        const options = {
        method: 'GET',
        url: 'https://blockscout.haradev.com/api?module=account&action=balance&address=' + wallet,
        };

        axios.request(options).then(function (response) {
        console.log(response);
        setBalanceNtth(response.data.result / Math.pow(10,18));
        }).catch(function (error) {
        console.error(error);
        });
    } 


	return(
		<>
		    <Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
					<Modal.Header closeButton>
						<Modal.Title >
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Err!=='' ? <Alert  variant={'danger'}>{msgError}</Alert>:''}
						<Row className="justify-content-md-center mb-4">
                        <div className="container">
                            <div className='row mt-4 justify-content-md-center'>
                                <div className="col-md-8">
                                    <div className="card ">
                                        <div className="card-body">
                                            <div className="row  mt-4">
                                                <div className="col-md-8">
                                                    <h5 className="card-title">Adresse</h5>
                                                    <p className="card-text">{wallet}</p>
                                                </div>
                                                <div className="col-md-3">
                                                    <h5 className="card-title">QR Code</h5>
                                                    <QRCode value={wallet} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4 justify-content-md-center'>
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Solde</h5>
                                            <div className="row">
                                                <p className="card-text col-md-6 col-sm-12">{balance_ntt} NTT</p>
                                                <p className="card-text col-md-6 col-sm-12">{balance_ntth} NTTH</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
          </Row>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={hide}>Close</Button>
					</Modal.Footer>
        	</Modal>
		</>
	)
}
export default ModalWalletInfo;