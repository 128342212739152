import { useState } from 'react';
import axios from 'axios';
import { useAuthorization } from './AuthProvider';

const useAPI = () => {
  const [response, setResponse] = useState({
    data: null,
    error: null,
    loading: false
  });

  const { signOut } = useAuthorization();

  const throwFormattedError = (message) => {
    const error = new Error(message);
    error.response = {
      data: {
        msg: message
      }
    };
    throw error;
  };

  const fetchData = async (
    url,
    method = 'GET',
    params = null,
    body = null,
    authToken = null
  ) => {
    try {
      setResponse({ ...response, loading: true, error: null });

      const headers = {
        'Content-Type': 'application/json'
      };

      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
      }

      const options = {
        method: method,
        url: url,
        headers: headers,
        params: params ? params : undefined,
        data: body ? JSON.stringify(body) : undefined
      };

      const res = await axios(options);
      if(res.data.success){
        setResponse({ ...response, data: res.data, loading: false });
        return res.data;
      }else{
        setResponse({ ...response, error: res.data.msg, loading: false });
        throwFormattedError(res.data.msg)
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        if(error.response.data.msg == 'Bad Token Key or Key missing, please register'){
          // signOut();
        }
        setResponse({ ...response, error: error.response.data.msg, loading: false });
        throw error.response.data.msg; // Jette le message d'erreur de la réponse
      } else {
        const errorMessage = error.msg || 'Une erreur est survenue.';
        setResponse({ ...response, error: errorMessage, loading: false });
        throw errorMessage; // Jette un message d'erreur générique
      }
    }
  };

  const get = (url, params = null, authToken = null) => {
    return fetchData(url, 'GET', params, null, authToken);
  };

  const post = (url, body, authToken = null) => {
    return fetchData(url, 'POST', null, body, authToken);
  };

  const put = (url, body, authToken = null) => {
    return fetchData(url, 'PUT', null, body, authToken);
  };

  const remove = (url, body, authToken = null) => {
    return fetchData(url, 'DELETE', null, body, authToken);
  };

  return { ...response, get, post, put, remove };
};

export default useAPI;
