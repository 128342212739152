import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { useParams } from 'react-router-dom';
import Brand from '../../composent/ntityDoc/brand';
import Related from '../../composent/ntityDoc/related';
import GitgraphHist from '../../composent/addEvent/gitgraphHist';
import Content from '../../composent/ntityDoc/content';
import { QRCode } from 'react-qrcode';
import Sku from '../../composent/ntityDoc/sku';
import AlertDismissible from '../../composent/shared/AlertDismissible';

const ItemDetail = () => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [result, setResult] = useState();
	const [template, setTemplate] = useState();

	const [rela, setRela] = useState();
	const [wallet, setWallet] = useState();
	const [sku, setSKU] = useState();

	const [msgError, setMsgError] = useState('');


	const params = useParams();

	const [api, setAPI] = useLocalStorage("api",'');
	const [gbm, setGBM] = useLocalStorage("gbm",'');

	useEffect(() => {
		if(authToken !== null){
			const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
			// String with valid UUID separated by dash
			if(regexExp.test(params.path)){
				getById()
			}else{
				getMyProducts()
				getRelated()
			}
		}
	},[authToken]);

	async function getById() {
		const form = {};
		form["bca"] = params.path;
		const options = {
			method: 'GET',
			url: api + 'ntity',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 201 && response.data.success === true)){
					let t = JSON.parse(response.data.data.Content);
					if(t.sn !== undefined){
						getSKU(t.sn);
					}
					getTemplate(response.data.data.ID_Template);
					setResult(response.data.data);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function getTemplate(template) {
		const form = {};
		form["bca"] = template;
		const options = {
			method: 'GET',
			url: api + 'ntity',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 201 && response.data.success === true)){
					setTemplate(response.data.data[0]);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function getMyProducts() {
		const form = {};
		form["bca"] = params.path;
		const options = {
			method: 'GET',
			url: api + 'ntity',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					let t = JSON.parse(response.data.data[0].Content);
					if(t.sn !== undefined){
						getSKU(t.sn);
					}
					setResult(response.data.data[0]);
					setTemplate(response.data.data[0].Template);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function getRelated() {
		const form = {};
		form["TX"] = params.path;
		const options = {
			method: 'GET',
			url: api + 'ntity/related',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params : form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/related"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					setRela(response.data.data);
					setWallet(response.data.data.stock);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/related"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function getSKU(sku) {
		const form = {};
		form["sku"] = sku;
		form["Username"] = gbm;
		const options = {
			method: 'GET',
			url: api + 'corporate/product',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/product"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					setSKU(response.data.data);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/product"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}
	
	return (
	< >
		<Container>
			<AlertDismissible variant="danger"  text={msgError}/>
			<div className='containerTitle'>{result ? result.Name: ''}</div>
			{ wallet !== undefined ? (   
			<Card style={{ marginTop: 50, marginBottom: 50 }}>
				<Card.Body>
					 
					<Container>
						{wallet}
						<QRCode value={wallet} />
					</Container>
				</Card.Body>
			</Card>) : (<></>) }
			<Card style={{ marginTop: 50, marginBottom: 50 }}>
				<Card.Body>
					{ result !== undefined && template !== undefined ? (    
					<Container>
     					<div id="searchRes"><Brand doc={result} small={false} /></div>
						{sku && (<div id="searchRes"><Sku result={sku}/></div>)}
      					<Related doc={result} temp={template}/>
      					{((rela === undefined || rela === null) || result.ID_Format === 1507) ? '' : <GitgraphHist doc={result} cbd={rela.branch} Orientation={'Horizontal'}></GitgraphHist>}
						<div style={{'width':'710px', 'margin':'auto'}}>
							<Content template={template} doc={result} />
						</div>
					</Container>) : (<></>) }
				</Card.Body>
			</Card>
		</Container>
	</>
	);
}

export default ItemDetail;