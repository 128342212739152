import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap';

const Congrat = ({ ID_Doc }) => {
  const [isShowing, setIsShowing] = useState(true);
  let title = <h3>Your certificate has been successfully created !</h3>;
  let content = <div>
    Your ntity certificate has been successfully registered into the blockchain. It will take up to 5-10 minutes to be mined. Please wait before using your new Ntity<br />You can now print your certificate &nbsp;
    <a href={process.env.REACT_APP_SERVER_URL + 'ntityio/print/?format=stylingCertificate&idNtityDoc=' + ID_Doc} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPrint} /></a>
  </div>

  return (
    <Modal
      size="lg"
      show={isShowing}
      onHide={() => setIsShowing(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{content}</div>
      </Modal.Body>
    </Modal>
  )
}

export default Congrat;