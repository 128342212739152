import React, { useEffect, useRef } from 'react';

const NodesJs = (props) => {
  const {
    id,
    width,
    height,
    particleSize = 2,
    lineSize = 1,
    particleColor = [255, 255, 255, 0.3],
    lineColor = '255,255,255',
    backgroundColor,
    number = 100,
    speed = 20,
    nobg = false,
  } = props;

  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const cwRef = useRef(width);
  const chRef = useRef(height);
  const nodesRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return; // Add a check to ensure the canvas element exists
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return; // Check if the context is available
    }

    let t0 = Date.now();
    let dt = 0;

    const setWidth = (width) => {
      canvas.width = width;
      cwRef.current = width;
    };

    const setHeight = (height) => {
      canvas.height = height;
      chRef.current = height;
    };

    const placeNodes = (number) => {
      nodesRef.current = [];

      for (let i = 0; i < number; i++) {
        nodesRef.current.push([
          Math.floor(Math.random() * (cwRef.current - 0 + 1)) + 0,
          Math.floor(Math.random() * (chRef.current - 0 + 1)) + 0,
          Math.random() * (Math.PI * 2 - 0 + 1) + 0,
          [],
        ]);
      }
    };

    const resizeHandler = () => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return; // Add a check to ensure the canvas element exists
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return; // Check if the context is available
      }

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      cwRef.current = canvas.width;
      chRef.current = canvas.height;
    };

    window.addEventListener('resize', resizeHandler);

    canvasRef.current = document.getElementById(id);
    ctxRef.current = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    cwRef.current = canvas.width;
    chRef.current = canvas.height;

    placeNodes(number);

    const step = () => {
      window.requestAnimationFrame(step);

      ctx.clearRect(0, 0, cwRef.current, chRef.current);

      if (!nobg) {
        const [r, g, b, a] = backgroundColor || [255, 255, 255, 0];
        ctx.beginPath();
        ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;
        ctx.fillRect(0, 0, cwRef.current, chRef.current);
        ctx.fill();
      }

      nodesRef.current.forEach((_node, _node_i) => {
        _node[0] += Math.cos(_node[2]) * speed * (dt / 1000.0);
        _node[1] += Math.sin(_node[2]) * speed * (dt / 1000.0);

        if (_node[0] < 0) {
          _node[0] = cwRef.current + (_node[0] % cwRef.current);
        }

        if (_node[0] > cwRef.current) {
          _node[0] = _node[0] % cwRef.current;
        }

        if (_node[1] < 0) {
          _node[1] = chRef.current + (_node[1] % chRef.current);
        }

        if (_node[1] > chRef.current) {
          _node[1] = _node[1] % chRef.current;
        }

        ctx.fillStyle = `rgba(${particleColor.join(',')})`;

        ctx.beginPath();
        ctx.arc(_node[0], _node[1], particleSize, 0, Math.PI * 2, true);
        ctx.fill();

        _node[3] = [];

        nodesRef.current.forEach((_node2, _node2_i) => {
          if (_node_i === _node2_i) {
            return true;
          }

          if (_node[3].indexOf(_node2_i) > -1) {
            return true;
          }

          const dx = Math.abs(_node[0] - _node2[0]);
          const dy = Math.abs(_node[1] - _node2[1]);
          const d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

          let alpha = 0;

          if (d <= 300) {
            alpha = 0.3 - (0.3 * d) / 200;
          }

          if (alpha === 0) {
            return true;
          }

          _node2[3].push(_node_i);

          ctx.strokeStyle = `rgba(${lineColor},${alpha})`;
          ctx.lineWidth = lineSize;

          ctx.beginPath();
          ctx.moveTo(_node[0], _node[1]);
          ctx.lineTo(_node2[0], _node2[1]);
          ctx.stroke();
        });
      });

      dt = Date.now() - t0;
      t0 = Date.now();
    };

    step();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [
    id,
    width,
    height,
    particleSize,
    lineSize,
    particleColor,
    lineColor,
    backgroundColor,
    number,
    speed,
    nobg,
  ]);

  return <canvas ref={canvasRef} id={id} width={width} height={height} className="center-canvas"></canvas>;
};

export default NodesJs;
