
import React, { useState } from 'react'

import {
  Container,
  Card,
  Accordion,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import axios from 'axios';

import Footer from '../composent/index/footer';
import { useAuthorization } from '../hook/AuthProvider';
import { useEffect } from 'react';
import ResultTable from '../composent/shared/Result.table';
import Loader from '../composent/shared/Loader';
import { useTranslation } from 'react-i18next';
import AlertDismissible from '../composent/shared/AlertDismissible';

const MyCertificats = (props) => {
  const { authToken, signOut } = useAuthorization();
  const [resultMnt, setResultMnt] = useState("");
  const {t} = useTranslation();
  const [resultUMnt, setResultUMnt] = useState("");
  const [resulOwned, setResulOwned] = useState("");
  const [action, setAction] = useState('all');
	const [Err, setErr] = useState("");
  const convertBcCode = {
    '1653' : {
    chainName: 'Ntity haradev',
    },
    '1553' : {
    chainName: 'Ntity Mainnet',
    },
    '1552' : {
    chainName: 'Ethereum Mainnet',
    },
    '1652' : {
    chainName: 'Ethereum Ropstein',
    },
    '1591' : {
    chainName: 'POA Mainnet',
    },
    '1592' : {
    chainName: 'Aurora Mainnet',
    },
    'none' : {
    chainName: '',
    }
}


  useEffect(() => {
    if(authToken !== null){
        submitowned('all');
      
    }
  },[authToken]);
  
  

  async function submitowned(entry) {
    const form = {};

    form["ct"]=entry;
    form["templateData"]=true;

    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'user/myCertificat',
      headers: {
        Authorization: "Bearer " + authToken,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    axios.request(options).then(function (response) {
      if(response.data.status === 401 && response.data.path === "errorToken/myCertificat"){
        signOut();
      } else {
        let tmp = response.data.data
        tmp.map((res)=>{
          let t = res;
          t.templateV = res.template.Name + " (v." + res.template.Version + ")";
          if( typeof t.ID_Type === 'null'||typeof t.ID_Type === 'object' ){
            t.ID_Type = 'none'
          }
          return t;
        });
        setResulOwned(tmp);
      }
    }).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
				setErr(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/myCertificat"){
				setErr(errt.data.msg);
			  signOut();
			}else{
				setErr(errt.data.msg);
			}
    });
  }


  const columnsMinned = [
    { accessor: 'Name', label: t('DICO_NIO_NAME_CERTIFICAT') },
    { accessor: 'ID_Type', label: 'Blockchain', format: value => (convertBcCode[value].chainName) },
    { accessor: 'templateV', label: t('DICO_NIO_VERSION_TEMPLATE') },
    { accessor: 'Date', label: t('DICO_WORD_CREATION_DATE') },

  ]

	const navFilter = () => {
		
		var btnBlock = [];

    ['all','owned','minned','unminned'].map((entry)=>{
      var bgB = 'outline-';
      if(action == entry ){
        bgB = '';
      }
			btnBlock.push(<Button  variant={bgB+'secondary'} style={{borderRadius:0}} size='sm' onClick={() => {setAction(entry);submitowned(entry)}}>{entry}</Button >);
		});
		return (
			<Accordion defaultActiveKey={['0','1']} alwaysOpen>
				<Accordion.Item eventKey="0">
					<Accordion.Header>{t("DICO_WORD_GROUP")}</Accordion.Header>
					<Accordion.Body style={{padding:0}}>
    					<div className="d-grid" style={{width:'100%'}}>
							{btnBlock}
    					</div>
					</Accordion.Body>
				</Accordion.Item>
		</Accordion>
	  )
	}

  const cardTab = (result, columns) => {
    let title = '';
    if(action === 'minned'){
      title = t("DICO_WORD_SIGN_CERTIFICAT")
    }else if(action === 'owned'){
      title = t("DICO_WORD_OWN_CERTIFICAT")
    }else if(action === 'unminned'){
      title = t("DICO_WORD_DOCUMENT_ARCHIVED")
    }
    return (
      <Card style={{ marginTop: 50, marginBottom: 100 }}>
            <Card.Body>
              <h3>{title}</h3>
              <Row>
                <Col sm={2}>
                  {resulOwned === '' ? '': <>{navFilter()}</>}
                </Col>
                <Col sm={10}>
                  {resulOwned === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={result} columns={columns} ct={'Doc'}/>}
                </Col>
              </Row>
            </Card.Body>
          </Card>
    )
  }

  return (
    <>
        <Container>
        <AlertDismissible variant="danger"  text={Err}/>
        <div className='containerTitle'>{t("DICO_WORD_CERTIFICATE")}</div>
          {resulOwned!=='' ? <>{cardTab(resulOwned,columnsMinned)}</> : ''}
        </Container>
    </>
  )
}


export default MyCertificats;
