import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Container, Form, Row, Spinner ,Col, Image, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useLocalStorage } from '../../hook/useLocalStorage';


const BrandId = () => {
	const { t } = useTranslation();
	const [member, setMember] = useLocalStorage("member",'');
	const { authToken,authUser, signOut } = useAuthorization();
	const [resultBrand, setResultBrand] = useState("");
	const [slogan, setslogan] = useState('');
	const [description, setdescription] = useState('');
	const [logo, setlogo] = useState('');
	const [logoDark, setlogoDark] = useState('');
	const [web, setweb] = useState('');
	const [paas, setpaas] = useState('');
	const [tqr, settqr] = useState('');
	const [email, setemail] = useState('');
	const [title, settitle] = useState('');
	const [GTC, setGTC] = useState('');
	const [policy, setpolicy] = useState('');
	const [TU, setTU] = useState('');
	const [Name, setName] = useState('');
	const [Css, setCss] = useState('');
	const [contact, setcontact] = useState('');
	const [andr, setAndr] = useState('');
	const [apple, setApple] = useState('');
	const [UrlC, setUrlC] = useState('');
	const [SKU, setSKU] = useState('');
	const [Mhead, setMhead] = useState('');
	const [Mfoot, setMfoot] = useState('');
	const [returnDelay, setReturnDelay] = useState('');
	const [returnCond, setReturnCond] = useState('');
		
	const [alertF, setalertF] = useState(undefined);
	const [msgform, setmsgform] = useState('');
	const [api] = useLocalStorage("api",'');

	const [msgError, setMsgError] = useState('');

	useEffect(() => {
		if(authToken !== null){
			submitAllProduc()
		}
	},[authToken]);


	async function submitAllProduc() {
		const form = {};
		form["username"]= authUser;
		const options = {
			method: 'GET',
			url: api + 'corporate/brandID',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/brandID"){
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined &&  Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					setResultBrand(response.data.data);
					setslogan(response.data.data.Slogan);
					setdescription(response.data.data.Description);
					setweb(response.data.data['URL_Website']);
					setpaas(response.data.data['URL_PaaS_Server']);
					settqr(response.data.data['URL_TQR_Alias']);
					settitle(response.data.data['Title_Description']);
					setemail(response.data.data['Email_Contact']);
					setGTC(response.data.data.GTC);
					setpolicy(response.data.data['Privacy_Policy']);
					setTU(response.data.data['Terms_of_use']);
					setName(response.data.data['Product_ID_Name']);
					setCss(response.data.data.CSS);
					setcontact(response.data.data['Address_Public']);
					setlogo(response.data.data['URL_Image']);
					setlogoDark(response.data.data['URL_Image_Dark']);
					setAndr(response.data.data.Android);
					setApple(response.data.data.Apple);
					setUrlC(response.data.data['URL_Contact']);
					setSKU(response.data.data['Product_SKU_Name']);
					setMhead(response.data.data['Mail_Header']);
					setMfoot(response.data.data['Mail_Footer']);
					setReturnDelay(response.data.data['Return_Delay']);
					setReturnCond(response.data.data['Return_Cond']);
				}else if(response.data.status === 401 && response.data.msg==='no brand id' && member.Role === 'Corporate'){
					setResultBrand({});
				}else{
					setMsgError(response.data.msg);
				}			
			}
		}).catch(function (error) {
            var errt;
            if (error.response) {
              errt=error.response;
            } else if (error.request) {
              errt=error.request.response;
            } else {
              setMsgError(error.message);
              return null;
            }
            if(errt.data.status === 401 && errt.data.path === "errorToken/brandID"){
              setMsgError(errt.data.msg);
			  signOut();
			}else if(errt.data.status === 401 && errt.data.msg==='no brand id' && member.Role === 'Corporate'){
				setResultBrand({});
            }else{
              setMsgError(errt.data.msg);
            }
		});
	}

	async function submitform() {
		const form = new FormData();
		form.append("username", authUser);
		form.append("Slogan", slogan);
		form.append("Description", description);
		form.append("URL_Image", logo);
		form.append("URL_Image_Dark", logoDark);
		form.append("URL_Website", web);
		form.append("Email_Contact", email);
		form.append("Title_Description", title);
		form.append("GTC", GTC);
		form.append("Privacy_Policy", policy);
		form.append("Terms_of_use", TU);
		form.append("Product_ID_Name", Name);
		form.append("CSS", Css);
		form.append("Address_Public", contact);
		form.append("Android", andr);
		form.append("Apple", apple);
		form.append("Product_SKU_Name", SKU);
		form.append("URL_Contact", UrlC);
		form.append("URL_TQR_Alias", tqr);
		form.append("URL_PaaS_Server", paas);
		form.append("Mail_Header", Mhead);
		form.append("Mail_Footer", Mfoot);
		form.append("Return_Delay", returnDelay);
		form.append("Return_Cond", returnCond);


		const options = {
			method: 'PUT',
			url: api + 'corporate/brandID',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/brandID"){
				signOut();
			} else {
				setResultBrand(response.data.data);
				if(response.data.data === false){
					setalertF(false);
					setMsgError(response.data.msg);
					setmsgform(response.data.msg);
				}else{
					setalertF(true);
					setmsgform('success');
				}
				setTimeout(() => {
					setalertF(undefined);;
				}, "5000");
			}
		}).catch(function (error) {
            var errt;
            if (error.response) {
              errt=error.response;
            } else if (error.request) {
              errt=error.request.response;
            } else {
              setMsgError(error.message);
              return null;
            }
            if(errt.data.status === 401 && errt.data.path === "errorToken/brandID"){
              setMsgError(errt.data.msg);
			  signOut();
            }else{
              setMsgError(errt.data.msg);
            }
		});
	}
	
	function inputIpfs(title,name,value,handler){
		var result
		var preview
			
		const handleFileInput = (e) => {
			document.querySelector('#prev'+name).src= '';
			// handle validations
			const file = e.target.files[0];
			if (file.size > 1000000024){
				alert("File size cannot exceed more than 1GB" );
				document.querySelector('#spinner'+name).style.display = 'none';
				document.querySelector('#prev'+name).style.display = 'inherit';
			}else{
				const formData = new FormData();
				formData.append("file", file);
				axios.post(process.env.REACT_APP_SERVER_URL + "misc/uploadImg", formData).then((response) => {
					if(response.data !== undefined){
						handler(response.data.data.Hash);
						document.querySelector('#prev'+name).src= response.data.data.Hash;
					}else{
						alert("File Upload Error")
					}
					document.querySelector('#spinner'+name).style.display = 'none';
					document.querySelector('#prev'+name).style.display = 'inherit';
				}).catch((err) => alert("File Upload Error"));
			}				
		};
		
		if(value === 'null'||value === ''|| value === undefined){
			preview = <Image src={value} id={'prev'+name} rounded style={{width: "100%",display:'none'}} />
		}else{
		 preview = <Image src={value} id={'prev'+name} rounded style={{width: "100%"}} />
		}
		result = <Form.Control   type="text" name={name}  value={value} onChange={(ev)=>{handler(ev.target.value)}}  />
		return  <>
			<Form.Label>{title}  <FontAwesomeIcon onClick={e => {document.querySelector('#spinner'+name).style.display = 'inherit';document.querySelector('#file'+name).click()}} icon={faDownload}/></Form.Label> 			
			{result}
			<Form.Control style={{'display':'none'}}  id={'file'+name} type="file" name={'file['+name+']'}   onChange={e => {handleFileInput(e);}}/><br/>
			<Spinner  id={'spinner'+name} animation="border" role="status" style={{'display':'none'}}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
			{preview}
		</>;
	}


const codeExample = `
{
	"backgroundColor" : "#505050",
	"borderColor" : "#100010",
	"borderWidth" : 1,
	"borderRadius" : 5,
	"padding" : 3,
	"margin" : 3
}`

	return (

	<>
	<Container>
	<AlertDismissible variant="danger"  text={msgError}/>
	<div className='containerTitle'>{t("DICO_WORD_BRAND_IDENTITY")}</div>
		<Card style={{ marginTop: 50, marginBottom: 100 }}>
		<Card.Body>
			{resultBrand === '' ? <Container className='text-center'><Loader /></Container> : 
				<Form id="test" onSubmit={(ev) =>{ ev.preventDefault();ev.stopPropagation();submitform(ev)}}>
					{alertF === false && <Alert variant="danger">{msgform}</Alert>}
					{alertF === true && <Alert variant="success">{msgform}</Alert>}
					{alertF === false&& <AlertDismissible variant="danger"  text={msgform} />}
					{alertF === true&& <AlertDismissible variant="success"  text={msgform} />}
					
					<Row>					
          <Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_COMPANY")} {t("DICO_WORD_DESCRIPTION")} </div>
							<Form.Label>{t("DICO_WORD_DESC_TITLE")} </Form.Label>
							<Form.Control type="text" value={title} name="title" onChange={(ev) =>{settitle(ev.target.value)}} />
							<Form.Label>{t("DICO_WORD_DESCRIPTION")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={description} name="description" onChange={(ev) =>{setdescription(ev.target.value)}} />

							<Form.Label>  {t("DICO_WORD_WEB")}</Form.Label>
							<Form.Control type="text" value={ web} name="url" placeholder='https://www.company.com' onChange={(ev) =>{setweb(ev.target.value)}} />

						</Col>
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_CONTACT_INFORMATION")} </div>
								<Form.Label> {t("DICO_WORD_EMAIL")} </Form.Label>
							<Form.Control type="email" value={ email} name="email" placeholder='info@company.com' onChange={(ev) =>{setemail(ev.target.value)}} />

              <Form.Label > {t("DICO_WORD_ADDITIONAL_INFORMATION")} </Form.Label>
							<Form.Control type="text" value={ contact} name="contact" placeholder='Way to contact' onChange={(ev) =>{setcontact(ev.target.value)}} />
              
              <Form.Label > {t("DICO_WORD_ADDITIONAL_URL_CONTACT")} </Form.Label>
							<Form.Control type="text" value={ UrlC} name="UrlC" placeholder='Way to contact' onChange={(ev) =>{setUrlC(ev.target.value)}} />


						</Col>
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_IMAGE")}</div>
							<Form.Label > {t("DICO_WORD_SLOGAN")}</Form.Label>
							<Form.Control type="text" value={ slogan} name="slogan" onChange={(ev) =>{setslogan(ev.target.value)}} />
							{inputIpfs(t("DICO_WORD_LOGO"),'logo', logo,setlogo)}
							{inputIpfs(t("DICO_WORD_LOGO")+' dark background','logoDark', logoDark,setlogoDark)}
						</Col>		
					</Row><Row>
						<Col className='groupedCol' colspan={2} >
            <div className='groupedTitle' >{t("DICO_WORD_CERTIFICATE")}</div>      
							<Form.Label>{t("DICO_WORD_ITEMID_NAME")}</Form.Label>
							<Form.Control type="text" value={Name} name="name" placeholder='ProductID' onChange={(ev) =>{setName(ev.target.value)}} />
							     
							<Form.Label>{t("DICO_WORD_SKU")}</Form.Label>
							<Form.Control type="text" value={SKU } name="name" placeholder='SKU' onChange={(ev) =>{setSKU(ev.target.value)}} />
							
              			<Form.Label>{t("DICO_WORD_CSS")}&nbsp;&nbsp;
								<OverlayTrigger
									key='top'
									placement='top'
									overlay={<Tooltip id={`tooltip-top`}> See in the example below the parameters that can be used to modify the appearance of the certificate.</Tooltip>}>
									<span>	<FontAwesomeIcon icon={faInfoCircle} />	</span>
								</OverlayTrigger>
							</Form.Label>
							<Form.Control as="textarea" rows={5} value={Css} name="css" onChange={(ev) =>{setCss(ev.target.value)}} />
							<br/>
							{t("DICO_WORD_EXAMPLE")}<br/>
							<code style={{ whiteSpace: 'pre-wrap' }} >	{codeExample}</code>
						</Col>
						
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_APP")}</div>
							<Form.Label > {t("DICO_WORD_ANDROID_URL")}</Form.Label>
							<Form.Control type="url" value={andr} name="slogan" placeholder='https://' onChange={(ev) =>{setAndr(ev.target.value)}} />
							<Form.Label > {t("DICO_WORD_APPLE_URL")}</Form.Label>
							<Form.Control type="url" value={apple} name="slogan" placeholder='https://' onChange={(ev) =>{setApple(ev.target.value)}} />
						</Col>
						
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_EMAIL")}</div>
							<Form.Label>{t("DICO_WORD_EMAIL_HEADER")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={Mhead} name="Mhead" onChange={(ev) =>{setMhead(ev.target.value)}} />
							<Form.Label>{t("DICO_WORD_EMAIL_FOOTER")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={Mfoot} name="Mfoot" onChange={(ev) =>{setMfoot(ev.target.value)}} />

						</Col>
					</Row>
					<Row>
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_RETURN_POLICY")}</div>
							<Form.Label > {t("DICO_WORD_RETURN_DELAY")}</Form.Label>
							<Form.Control type="text" value={returnDelay} name="returnDelay" placeholder='30' onChange={(ev) =>{setReturnDelay(ev.target.value)}} />
							<Form.Label > {t("DICO_WORD_RETURN_CONDIT")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={returnCond} name="returnCond" onChange={(ev) =>{setReturnCond(ev.target.value)}} />

						</Col>
					</Row>
					<Row>
						<Col className='groupedCol'>
							<div className='groupedTitle' >{t("DICO_WORD_TERMS")}</div>
							<Form.Label>{t("DICO_WORD_GTC")} </Form.Label>
							<Form.Control as="textarea" rows={5} value={GTC} name="GTC" onChange={(ev) =>{setGTC(ev.target.value)}} />

							<Form.Label>{t("DICO_WORD_TERMS_USE")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={TU} name="TU" onChange={(ev) =>{setTU(ev.target.value)}} />

							<Form.Label>{t("DICO_WORD_PRIVACY_POLICY")}</Form.Label>
							<Form.Control as="textarea" rows={5} value={policy} name="policy" onChange={(ev) =>{setpolicy(ev.target.value)}} />
						</Col>
					</Row>
					<Button type="submit" variant="danger" >{t('DICO_WORD_SAVE')}</Button>
				</Form>
			}
		</Card.Body>
	</Card>
	</Container>
	</>

	);
}

export default BrandId;