import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Card, Container, Form, Row, Spinner ,Col, Image, OverlayTrigger} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { Link } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,TimeScale } from 'chart.js/auto';
import { Bar, Doughnut, Line, Pie} from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import AlertDismissible from '../../composent/shared/AlertDismissible';

const InfoServer = () => {
    const { t } = useTranslation();
    const { authToken, signOut } = useAuthorization();
    const [resultAll, setResultAll] = useState("");
    const [mode, setMode] = useState('month');
    const [secretMode, setsecretMode] = useState("month");
	  const [titletqr, setTitletqr] = useState("");
    const [api, setAPI] = useLocalStorage("api",'');
    const [member, setMember] = useLocalStorage("member",'');
    const [msgError, setMsgError] = useState('');
    
    useEffect(() => {
        if(authToken !== null && member !== undefined){
          if(member.Role ==="Corporate"){
            getInfo('month')
          }else{
            setResultAll(false);
          }
          
        }
      },[member,authToken]);

      useEffect(() => {
        if(!resultAll.hasOwnProperty(secretMode)){
          getInfo(secretMode)
        }else{
          setMode(secretMode)
        }
    },[secretMode]);

      async function getInfo(ct) {
        const form = {};
        form["ct"] = ct;
        const options = {
          method: 'GET',
          url: api + 'corporate/stats',
          headers: {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          data: form
        };
    
        axios.request(options).then(function (response) {
          if(response.data.status === 401 && response.data.path === "errorToken/stats"){
            signOut();
          } else {
            if(response.data.status == false){
  
            }else{
              if(typeof resultAll === 'object' && resultAll !== null){
                var tmp = structuredClone(resultAll);
                tmp[ct] = response.data.data;
              }else{
                var tmp = {};
                tmp[ct] = response.data.data;
              }
              setResultAll(tmp);
              setMode(ct)
            }
          }
        }).catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/stats"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
        });
      }
      ChartJS.register(ArcElement, Tooltip, Legend);
  

      const doughnut = (data,colLabel) =>{
        var val= [];
        var label= [];
        data.data.map((entry) => {
          if(label.indexOf(entry[colLabel]) === -1){
            label.push(entry[colLabel]);
            val.push(entry['count']);
          }else{
            val[label.indexOf(entry[colLabel])] += entry['count'];
          }
          
          return entry.count;
        });
        const config = {
          labels: label,
          datasets: [
            {
              label: '# of '+colLabel,
              data: val,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };

        // var options = {
        //   maintainAspectRatio: false, // Désactive le maintien des proportions
        //   aspectRatio: 0.75,
        //   responsive: true,
        // }; 

        return <Doughnut data={config} />
      }

      var getDaysArray = function(start, end, ct) {
        if('day' === ct){
          for(var arr=[],dt=new Date(start); dt<=Date.now(); dt.setDate(dt.getDate()+1)){
              arr.push(new Date(dt));
          }
        }else if('year' === ct){
          for(var arr=[],dt=new Date(start); dt<=Date.now(); dt.setFullYear(dt.getFullYear()+1)){
              arr.push(new Date(dt));
          }
        }else{
          for(var arr=[],dt=new Date(start); dt<=Date.now(); dt.setMonth(dt.getMonth()+1)){
              arr.push(new Date(dt));
          }
        }
        
        return arr;
    };
      
      const line = (datas,colLabel,colValu) =>{
        var indexColLabel;
        var label= [];
        var group= [];
        var lstgroup= {};
        var dataset= [];
        datas.data.map((entry) => {

          if(group.indexOf(entry[colLabel]) === -1){
            group.push(entry[colLabel]);
            indexColLabel = group.indexOf(entry[colLabel]);
            lstgroup[indexColLabel] = {}
            dataset.push({
              label: entry[colLabel],
              data: [],
              spanGaps: true,
              tension: 0.4
            });
          }
          if(label.indexOf(entry[colValu]) === -1){
            label.push(entry[colValu]);
          }
          indexColLabel = group.indexOf(entry[colLabel]);
          lstgroup[indexColLabel][new Date(entry[colValu])] = entry['count'];
          
          
          //dataset[group.indexOf(entry[colLabel])].data.push({x:entry[colValu],y:entry['count']})
        }); 
        label = getDaysArray(label[0],label[label.length-1],mode);

        dataset.map((droup,iGroup) => {
          label.map((dlabel,ilabel) => {
            if(lstgroup[iGroup].hasOwnProperty(dlabel)){
              dataset[iGroup].data.push({x:dlabel,y:lstgroup[iGroup][dlabel]});
            }else{
              dataset[iGroup].data.push({x:dlabel,y:0});
            }
          });
        });

        const data = {
          labels: label,
          datasets: dataset
        };
        
          const options = {
            scales: {
              x: {
                type: 'time',
                title: {
                  display: true,
                  text: 'Date'
                }
              },
              y: {
                beginAtZero: false,
              },
            },
          };
        
          return <Line data={data} options={options} />;
      }
      
  return (
    <>
      <Container>
        <AlertDismissible variant="danger"  text={msgError}/>
        <div className='containerTitle'>{t("DICO_WORD_DASHBOARD")} - {member.Corporate}</div>
        <div className='btn-group mb-4'>
          <Button variant={'outline-primary'} size='md' onClick={()=>{setsecretMode('day')}}>{t('DICO_WORD_DAY')}</Button>
          <Button variant={'outline-primary'} size='md' onClick={()=>{setsecretMode('month')}}>{t('DICO_WORD_MONTHS')}</Button>
          <Button variant={'outline-primary'} size='md' onClick={()=>{setsecretMode('year')}}>{t('DICO_WORD_YEAR')}</Button>
        </div>

        {resultAll === false ? '' :
        <>

          <Row>
            <Col sm={5}>
              {resultAll === '' && resultAll[mode] === undefined ? 
                <Container className='text-center'><Loader /></Container> 
                : <> 
                {resultAll[mode].log.title}
              {line(resultAll[mode].log,'Level','Time') }</>
              }
            </Col>
            <Col sm={5}>
              {resultAll === '' && resultAll[mode] === undefined ? 
                <Container className='text-center'><Loader /></Container> 
                : <> 
              {resultAll[mode].certificat.title}
              {line(resultAll[mode].certificat,'ID_Type','Date') }</>
              }
            </Col>

          </Row>
          <Row>
            <Col sm={5}>
              {resultAll === '' && resultAll[mode] === undefined ? 
                <Container className='text-center'><Loader /></Container> 
                : <> 
                {resultAll[mode].tqr.title}
                {doughnut(resultAll[mode].tqr,'ID_Status') }</>
              }
            </Col>
            <Col sm={5}>
              {resultAll === '' && resultAll[mode] === undefined  ? 
                <Container className='text-center'><Loader /></Container> 
                : <> 
              {resultAll[mode].certificat.title}
              {doughnut(resultAll[mode].certificat,'ID_Type') }</>
              }
            </Col>
          </Row>
        </>
        }
            </Container>
           

        </>
  );
}

export default InfoServer;