import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Loader from '../composent/shared/Loader';
import AlertDismissible from '../composent/shared/AlertDismissible';

const VerifyScreen = () => {
	const { t } = useTranslation();
	const params = useParams();

	const [brandId, setBrandID] = useState({Slogan:'NTITY',URL_Image:'https://cdn.cryptolex.ch/img/logo_ntity_bt.png',URL_Contact:''});
  const [success, setSuccess] = useState();
  const [api, setAPI] = useState();
	const [cssBackgroundColor, setCssBackgroundColor] = useState();
  const [error, setError] = useState(false);
  const [suc, setSuc] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    const element = document.getElementById('mainNav');
    element.classList.add('hidden');
    // console.log(params);
  }, []);

	async function GetAPI() {
    
    const form = {};
    form["Username"] = params.ID_GBM;

    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
      } else {
        BrandCorporate(response.data.data['4000']);
        setAPI(response.data.data['4000']);
        verify(response.data.data['4000']);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

	async function BrandCorporate(api) {
    const form = {};
    form["username"] = params.ID_GBM;

    const options = {
      method: 'GET',
      url: api + 'corporate/brandID',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
      } else {
        setBrandID(response.data.data);
        const jsonObject = JSON.parse(response.data.data.CSS);
        setCssBackgroundColor(jsonObject.backgroundColor);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  const [timer, setTimer] = useState(10);

	async function verify(api) {
		const form = new FormData();
		let options = {
			method: 'POST',
			url: api + 'user/verify',
			headers: {
				Authorization: "Bearer " + params.Path,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};

		await axios.request(options).then(function (response) {
      console.log(response.data);
			if(response.data.success === true){
				setSuccess(true);
        if(params.ID_GBM == undefined){
          const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
      
          // Redirige l'utilisateur après 10 secondes
          const redirectTimeout = setTimeout(() => {
            clearInterval(countdown);
            navigate('/login');
          }, 10000);
      
          return () => {
            clearInterval(countdown);
            clearTimeout(redirectTimeout);
          };
        }
      }else{
        setError(true)
      }
		}).catch(function (error) {
      setError(true);
			console.log(error);
		});
	}

  function parseJwt (token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  async function resend() {
    const form = {};
    const jwt = parseJwt(params.Path);
    form["username"] = jwt.ID_User;
	
		let options = {
			method: 'GET',
			url: api + 'auth/resend',
			headers: {
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
      params: form
		};

		await axios.request(options).then(function (response) {
			if(response.data.success === true){
				setSuc(true);
			}
		}).catch(function (error) {
      setError(true);
			console.log(error);
		});
	}


  useEffect(() => {
    // console.log(params);
    if(params.ID_GBM !== undefined){
      GetAPI();
    }else{
      setAPI(process.env.REACT_APP_SERVER_URL);
      verify(process.env.REACT_APP_SERVER_URL );
    }
  },[params.ID_GBM]);
  


  return (

    <>
      <div className='text-center pt-4'>
        <Container style={{color : cssBackgroundColor}}> 
        {suc && <AlertDismissible variant="success"  text={'The email with the link is send'} />}
        <Card style={{ boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="TQR-card">
          {success ? (
            <div>
              <header className="TQR-header">
                <img src={brandId.URL_Image} alt={brandId.Slogan} />
              </header>
              <main className='TQR-Content'>
				<Row>
					<Col>Hello, thank you for confirming your account. <br/> You can now connect to our services and applications.</Col>

				</Row> 
        {
          params.ID_GBM == undefined &&           <p>Redirection dans {timer} secondes</p>
        }
				
				<Row>
					<Col></Col>		
					<Col><br />
						<a target='_blank' style={{color : cssBackgroundColor}} href={brandId.URL_Contact}>{t('DICO_WORD_CONTACT_INFORMATION')}</a> <br />
						{brandId.Email_Contact}
					</Col>
					<Col></Col>
				</Row>
              </main>
            </div>
          ):error ? (
            <div>
              <header className="TQR-header">
                <img src={brandId.URL_Image} alt={brandId.Slogan} />
              </header>
              <main className='TQR-Content'>
              <Row>
                <Col>Hello, your link have expired. Please click on the button below to resend a new link</Col>
              </Row> 
              <Row>
                <Col><Button onClick={resend}>Resend new link</Button></Col>
              </Row>
      
              <Row>
                <Col></Col>		
                <Col><br />
                  <a target='_blank' style={{color : cssBackgroundColor}} href={brandId.URL_Contact}>{t('DICO_WORD_CONTACT_INFORMATION')}</a> <br />
                  {brandId.Email_Contact}
                </Col>
                <Col></Col>
              </Row>
            </main>
          </div>
          ):(
            <div>
				<Loader />
            </div>
          )}

          </Card>

        </Container>
		<br/>
      </div>
    </>
  );
}

export default VerifyScreen;


