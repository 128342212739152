import React from 'react'
import { useTranslation } from "react-i18next";

const Ntity = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container" style={{marginTop: '10rem'}}>
      <div className='card d-card'>
        <div className='card-body' style={{padding: '5rem'}}>
          <div style={{marginTop: '5rem'}}>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <img src='./img/tools.png' className='img-fluid' style={{width:'450px',height:'auto'}}/>
                <h3 style={{textAlign: 'center', fontSize:'30pt'}}>{t("DICO_NIO_TOOLS")}</h3>
              </div>
              <div className='col-md-6 col-sm-12' style={{verticalAlign: 'middle'}}>
                <h3>{t("DICO_NIO_PROTOCOL")}</h3>
                <p>{t("DICO_NIO_PROTOCOL_DESC")}</p>
                <a href='https://docs.ntity.io/datagram.php' style={{padding: '1rem 4rem 1rem 4rem'}} className='btn btn-outline-danger'>{t("DICO_WORD_MORE_INFO")}</a>
                <br/>
                <br/>
                <br/>

                <hr/>
                <h3>{t("DICO_NIO_BLOCKCHAIN")}</h3>
                <p>{t("DICO_NIO_BLOCKCHAIN_DESC")}</p>
                <a href='https://docs.ntity.io/ntt.php' style={{padding: '1rem 4rem 1rem 4rem'}} className='btn btn-outline-danger'>{t("DICO_WORD_MORE_INFO")}</a>
                <br/>
                <br/>
                <br/>

                <hr/>
                <h3>{t("DICO_NIO_ABOVE_ALL")}</h3>
                <p>{t("DICO_NIO_NTITY_HEART")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ntity
