import React, { useState, useEffect } from 'react'
import Loader from "../composent/shared/Loader";
import {Container} from 'react-bootstrap';
import axios from 'axios';
import Resultat from '../composent/search/resultat';
import Footer from '../composent/index/footer';
import SearchCmp from '../composent/index/search';


const Store = () => {
  const [template, ] = useState();
  const [resultat, setResultat] = useState('');

  useEffect(() => {
    submit(); 
  },[]);

  async function submit() {
    const form = {};
    
    form["bca"] = '';
    form["Blockchain"] = '[1551,1552,1553]';
    form["Status"] = '20';
    form["Type"] = '1507';

    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'ntity',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
        console.log('login error', response.output.message);
      } else {
        console.log(response);
        setResultat(response.data.data);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  return (
    <>
        <div className='text-center mt-4'>
          <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> Store</h2>
        </div>
        <Container>
          <SearchCmp searchs={false} />
        </Container>
        <Container className='text-center mb-4'>
          {resultat === '' ? <Loader /> : <Resultat res={resultat} show={template} />}
        </Container>
    </>
  )
}

export default Store;