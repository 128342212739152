import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/ntity.css';
import { BrowserRouter as Router, Routes, Route, useLocation  }
  from 'react-router-dom';

import Navigation from './composent/shared/Navigation.js';
import Search from './pages/search';
import Create from './pages/create';
import Index from './pages/index';
import Doc from './pages/doc';
import Store from './pages/store';
import { AuthProvider } from './hook/AuthProvider';
import Login from './pages/login';
import Logout from './pages/logout';
import Forget from './pages/forget';
import Stripe from './pages/stripe';
import Register from './pages/register';
import MyCertificats from './pages/certificate';
import ChangePWD from './pages/changePassword';
import Credit from './pages/credit';
import NotFound from './pages/NotFound';
import Confirm from './pages/confirm';
import ManageDoc from './pages/manageDoc';
import Corporate from './pages/corporate/corporate';
import Privacy from './pages/Privacy';
import TQRPublic from './pages/TQRPublic';
import Code from './pages/code';
import Terms from './pages/Terms';
import VerifyScreen from './pages/verify';
import IndexP from './pages/profil/indexP';
import Footer from './composent/index/footer.js';
import Buy from './pages/buy.js';

import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { chains, wagmiConfig } from './composent/sendToBc/configWagmi.js';
import AnnouncementPopup from './composent/shared/AnnoncementPopup.js';
import BuyPublic from './pages/buy_public.js';
import { UseLocalStorageWithTimeout } from './hook/useLocalStorageWithTimeout.js';
import Marketplace from './pages/marketplace/marketplace.js';
import { LanguageProvider } from './i18n.js';

function NavigationControl() {
  const location = useLocation();

  // Vérifiez si l'URL actuelle correspond à "/"
  const isHome = location.pathname === '/';

  return <>{isHome ? null : <><div className="headerBlack" /></>}</>; // Ne rend le composant que si ce n'est pas la page d'accueil
}

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [pop, setPop, clearPop] = UseLocalStorageWithTimeout('popup', '', 3600); // 60000 millisecondes = 1 minute

  useEffect(() => {
    const timer = setTimeout(() => {
      if(!pop && window.location.pathname == '/'){
        setPop(true);
        setShowPopup(true);
      }
    }, 10000); // 10 secondes

    return () => clearTimeout(timer);
  }, []); // Effect runs only once

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleOpen = () => {
    setShowPopup(true);
  };

  return (
    <div style={{ backgroundColor: '#DDD' }}>
      <Router>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}  modalSize="compact">
            <LanguageProvider>
              <AuthProvider>
                <NavigationControl />
                <Navigation />
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/create" element={<Create />} />
                  <Route path="/buy" element={<BuyPublic />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/confirm" element={<Confirm/>}/>
                  <Route path="/verify/:Path" element={<VerifyScreen />} />
                  <Route path="/verify/:Path/:ID_GBM" element={<VerifyScreen />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forget" element={<Forget />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/credit" element={<Credit />} />
                  <Route path="/stripe" element={<Stripe />} />
                  <Route path="/code" element={<Code />} />
                  <Route path="/search/:Path" element={<Search />} />
                  <Route path="/search.php" element={<Search />} />
                  <Route path="/privacy" element={<Privacy/>} />
                  <Route path="/terms" element={<Terms/>} />
                  <Route path="/search.php/:Path" element={<Search />} />
                  <Route path="/ntityDoc/id/:ID_Doc" element={<Doc />} />
                  <Route path="/recover" element={<ChangePWD />} />
                  <Route path="/store" element={<Search />} />
                  <Route path="/myCertificates/signed" element={<MyCertificats ct={'signed'} />} />
                  <Route path="/myCertificates/owned" element={<MyCertificats ct={'owned'} />} />
                  <Route path="/myCertificates/archived" element={<MyCertificats ct={'archived'} />} />
                  <Route path="/myCertificates/:Path/manage" element={<ManageDoc />} />
                  <Route path="/myCertificates/:Path" element={<Doc />} />
                  <Route path="/myCertificates" element={<MyCertificats />} />
                  <Route path="/tqr/:GBM/:TQR" element={<TQRPublic />} />
                  <Route path="/corporate/*" element={<Corporate />} />
                  <Route path="/profil/*" element={<IndexP />} />
                  <Route path="/marketplace/*" element={<Marketplace />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AuthProvider>
            </LanguageProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </Router>
      <AnnouncementPopup show={showPopup} handleClose={handleClose} />
      <Footer showPopup={handleOpen} />
    </div>
  );
}

export default App;
