import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../composent/shared/Loader";
import { Accordion, Button, Card, Col, Collapse, Container, ListGroup, Modal, Row , useAccordionButton } from 'react-bootstrap';

import Footer from '../composent/index/footer';
import FormEvent from '../composent/addEvent/formEvent';


import { useAuthorization } from '../hook/AuthProvider';
import Content from '../composent/ntityDoc/content';
import GitgraphHist from '../composent/addEvent/gitgraphHist';
import { useTranslation } from 'react-i18next';
import ModalAddApproval from '../composent/ntityDoc/modalAddApproval';
import AlertDismissible from '../composent/shared/AlertDismissible';

const ManageDoc = () => {
    const { authToken, signOut } = useAuthorization();
    const { t } = useTranslation();
    const params = useParams();
    const [rela, setRela] = useState();
    const [certOrigin, setcertOrigin] = useState();
    const [loading, setloading] = useState(true);
    const [open, setOpen] = useState(false);
    const [lstTempl, setlstTempl] = useState({});
    const [parrentForm, setparrentForm] = useState({});

    const [modalProp, setModalProp] = useState(false);
    const [propSel, setpropSel] = useState();
    const [propaction, setpropaction] = useState();

    const [modal, setModal] = useState(false);
    const [msgError, setMsgError] = useState('');

    const handleModalClose = () => setModal(false);
    const handleModalShow = () => setModal(true);

    let countAcc = 0;
    let countColla = 0;
    useEffect(() => {
        if(authToken !== null){
             getNtityDoc()
        }
    }, [authToken]);

    const forceUpdate =() =>{
        setloading(true);
        setModalProp(false);
        getNtityDoc()
    }
    async function getNtityDoc(){
        setloading(true);
        var form 
        form = {"TX": params.Path};
        const options = {
          method: 'GET',
          url: process.env.REACT_APP_SERVER_URL + 'ntity/related',
          headers: {
              Authorization: "Bearer " + authToken,
              "Content-Type":
              "multipart/form-data; boundary=---011000010111000001101001",
          },
          params: form
        };
        await axios
        .request(options)
        .then(function (response) {
            console.log('responses');
            console.log(response);
            if(response.data.status === 401 && response.data.path === "errorToken/related"){
             //signOut();
            }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
                setRela(response.data.data);
                var cert = toCert(response.data.data);
                getTemplate(cert.ID_Template);
                setloading(false);
            }
        })
        .catch(function (error) {
            var errt;
            if (error.response) {
              errt=error.response;
            } else if (error.request) {
              errt=error.request.response;
            } else {
              setMsgError(error.message);
              return null;
            }
            if(errt.data.status === 401 && errt.data.path === "errorToken/related"){
              setMsgError(errt.data.msg);
			  signOut();
            }else{
              setMsgError(errt.data.msg);
            }
        });
    }

    async function getTemplate(ID_Template){
        setloading(true);
      const form = {};
      form["bca"] = ID_Template
      const options = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'ntity/',
        params: form
      };

       await axios.request(options).then(function (response) {
        var tmp = lstTempl;
        tmp[response.data.data[0].Path] = response.data.data[0];
        setlstTempl(tmp)
        setloading(false);
      }).catch(function (error) {
        console.error(error);
      });
    }

    function toCert(data){
        var cert;
        if(data.history && typeof data.history === 'object' && data.history.constructor === Object){
            cert = data.history
        }else if(Array.isArray(data.history)){
            Object.entries((data.history).filter((entry) => {
                if(entry['Path'] === data.origin){
                    cert = entry;
                }
            }));
        }
        setcertOrigin(cert);
        return cert;
    }

    function cardCbd(){

        return rela['history'].map((entry,index) => {
            if(entry['Path'] === rela.origin){
              var test = accordionCbd([entry]);
              return test;
            }
        });
    }

    function accordionCbd(arrData){
        return arrData.map((entry,index) => {
            var child = ''
            if(entry['Child'] !== undefined){
                child = accordionCbd(entry['Child']);
            }
            countAcc = countAcc+1;
            return(
                <>
                    <Accordion>
                        <Accordion.Item eventKey={entry['Path']}>
                            <Accordion.Header className={'accHead'} id={'Acc'+entry['Path'].substring(2, 9)}>
                            <div className='rounded-circle' id={'circle'+entry['Path']} style={{width: '20px',height: '20px',backgroundColor: 'yellow',border: 'solid 2px #e7f1ff', margin: '5px'}}></div>
                                {entry['Name']}</Accordion.Header>
                            <Accordion.Body >
                                {cartCert(entry)}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div style={{'marginLeft': '1rem'}}>
                        {child}
                    </div>
                </>
            )
        });
    }

    const accordionShow = (path) => {
        var accor =  document.getElementsByClassName('accHead')
        if(accor.hasOwnProperty(0)){
            Object.entries(accor).map((entry) => {
                if(!entry[1].children[0].classList.contains("collapsed")){
                    entry[1].children[0].click();
                }
            });
        }else{
            if(!accor.children[0].classList.contains("collapsed")){
                accor.children[0].click();
            }
        }
        document.getElementById('Acc'+path.substring(2, 9)).children[0].click();
        document.getElementById('Acc'+path.substring(2, 9)).scrollIntoView({behavior:"smooth", block: "end", inline:"end"});
        //window.location.href = '#Acc'+path.substring(2, 4);

    }

    function toggleCollapse(id){
        if (document.getElementById(id).classList.contains('show')) {
            document.getElementById(id).classList.remove('show');
        } else {
            document.getElementById(id).classList.add('show');
        }
    }

    function handleAddEvent(id){
        setparrentForm(id);
        setModal(true);
    }

    function cartCert(doc, ct=''){
        var content
        if(lstTempl[doc.ID_Template] === undefined){
            getTemplate(doc.ID_Template);
        }else{
            countColla = countColla+1
            var tmp = countColla;
            content = <div>
                <a 
                key={countColla}
                onClick={() => toggleCollapse('colla'+tmp)}
                aria-controls="example-collapse-text">Information : </a>
                <Collapse  id={'colla'+tmp} >
                    <div >
                        <Content template={lstTempl[doc.ID_Template]} doc={doc} />
                    </div>
                </Collapse>
            </div>
        }
        var orig;
        if(ct==='origine'){
            orig = <>
                <div className="row">
                    <div className="col-sm-2 text-muted">
                        owner 
                    </div>
                    <div className="col-sm-10 row">
                        {rela['owner']}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-muted">
                        creator 
                    </div>
                    <div className="col-sm-10 row">
                        {rela['creator']}
                    </div>
                </div>
            </>
        }
        var actionButton
        if(ct==='proposale'){
            actionButton = (
                <> <Button variant="outline-primary" onClick={() => {setpropSel(doc);setpropaction("25");setModalProp(true)}} style={{'float':'right'}}>Published</Button>
                    <Button variant="outline-danger" onClick={() => {setpropSel(doc);setpropaction("24");setModalProp(true);}} style={{'float':'right'}}>Rejected</Button>
                   
                </>
            );
        }else{
            actionButton = <Button variant="outline-primary" onClick={() => handleAddEvent(doc.ID_Doc)} style={{'float':'right'}}>add Operation</Button>;
        }

        return (
            <>
                <div className="row">
                    <div className="col-sm-2 text-muted">
                        Type 
                    </div>
                    <div className="col-sm-10 row">
                        {doc['ID_Format']}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-muted">
                        Status
                    </div>
                    <div className="col-sm-10 row">
                        success
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-muted" >
                        Transaction 
                    </div>
                    <div className="col-sm-10 row" style={{'overflow': 'scroll'}}>
                        {doc['Path']}
                    </div>
                </div>
                {orig}
                {content}
                <div >
                    <Button variant="outline-secondary" onClick={() => window.location = '/search/'+doc['Path']}>view</Button>
                    {actionButton}
                </div>
            </>
        ); 
    }


    const cardProposal = () => {
        var  list = [];
        Object.entries(rela.proposal).map((entry) => {
            var tmp = (
                <>
                    <Accordion>
                        <Accordion.Item eventKey={entry['Path']}>
                            <Accordion.Header className={'accHead'} id={'Acc'+entry[1]['Path'].substring(2, 9)}>
                            {entry[1]['Name']}</Accordion.Header>
                            <Accordion.Body >
                                {cartCert(entry[1],'proposale')}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            );
            list.push(tmp);
        });
        //<ResultTable rows={rowUsername} columns={columns}/>
        if (list !== undefined && list.length > 0) {
            return (
                <Card>
                    <Card.Body>
                        {list}
                    </Card.Body>
                </Card>
            );
        }else{
            return '';
        }
    }



    return (
        <>
                <div className='text-center mt-4'>
                    <AlertDismissible variant="danger"  text={msgError}/>
                    {/* {loading === true ? <Container className='text-center mb-4'><Loader /></Container> : <FormEvent doc={Doc}/>} */}
                    {loading === true ? <Container className='text-center mb-4'><Loader /></Container> : 
                    
                    
                    <>
                        <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> {certOrigin['Name']}</h2>
                        <Container  style={{'textAlign':'start'}}>
                            <Card>
                                <Card.Body>
                                    {cartCert(certOrigin,'origine') }
                                </Card.Body>
                            </Card>
                            <Row>
                                <Col sm={5}>
                                    <GitgraphHist handlerCdot={accordionShow} cbd={rela.branch}/>
                                </Col>
                                <Col sm={7}>
                                    <Card>
                                        <Card.Body>
                                            {cardCbd()}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {cardProposal()}
                        </Container>
                        <Modal show={modal} onHide={handleModalClose} size="lg">
                            <Modal.Header closeButton>
                            <Modal.Title>new Operation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body><FormEvent doc={certOrigin} parrent={parrentForm} rela={rela} lstTempl={lstTempl}/></Modal.Body>
                        </Modal>
                        {propSel === undefined ? '':
                        <ModalAddApproval
                            show={modalProp}
                            onHide={() => setModalProp(false)}
                            doc={propSel}
                            action={propaction}
                            update={() => {forceUpdate()}}
                            title={t("DICO_WORD_CONFIRM")}
                        />}
                    </>

                    }
                </div>
        </>
    );
}

export default ManageDoc;
