import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import qrcodeGooglePlay from '../img/google_app.png';
import qrcodeAppStore from '../img/apple_app.png';
import Loader from '../composent/shared/Loader';

const TQRPublic = () => {
	const { t } = useTranslation();
	const params = useParams();

	const [brandId, setBrandID] = useState();
	const [cssBackgroundColor, setCssBackgroundColor] = useState();

  useEffect(() => {
    const element = document.getElementById('mainNav');
    element.classList.add('hidden');
  }, []);

	async function GetAPI() {
    
    const form = {};
    
    form= {'Username': params.GBM};

    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
      } else {
        BrandCorporate(response.data.data['4000']);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

	async function BrandCorporate(api) {
    const form = {};
    
    form= {'Username': params.GBM};

    const options = {
      method: 'GET',
      url: api + 'corporate/brandID',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
      } else {
        setBrandID(response.data.data);
        const jsonObject = JSON.parse(response.data.data.CSS);
        setCssBackgroundColor(jsonObject.backgroundColor);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }


  useEffect(() => {
    console.log(params);
    GetAPI()
  },[]);
  


  return (

    <>
      <div className='text-center pt-4'>
        <Container style={{color : cssBackgroundColor}}> 
        <Card style={{ boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="TQR-card">
          {brandId ? (
            <div>
              <header className="TQR-header">
                <img src={brandId.URL_Image} alt={brandId.Slogan} />
              </header>
              <main className='TQR-Content'>
				<br />
				<Row>						
					<Col>
						<a href={brandId.Android}><img src={qrcodeGooglePlay} className='appStore'  alt="QR code to download the app on the Google Play" /></a>
						<a href={brandId.Apple}><img  src={qrcodeAppStore} className='appStore'  alt="QR code to download the app on the App Store" /></a>		
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
					In order to access the services and certificates of your products, <br/>please install the following application on your smartphone</Col>
				</Row> 
				
				<Row>
					<Col></Col>		
					<Col><br /><br />
						<a target='_blank' style={{color : cssBackgroundColor}} href={brandId.URL_Contact}>{t('DICO_WORD_CONTACT_INFORMATION')}</a> <br /><br />
						{brandId.Email_Contact}
					</Col>
					<Col></Col>
				</Row>
              </main>
            </div>
          ):(
            <div>
				<Loader />
            </div>
          )}

          </Card>

        </Container>
		<br/>
      </div>
    </>
  );
}

export default TQRPublic;


