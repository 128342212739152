import React, { useState } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Collapse,
  Container,
  Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown,  faAngleUp } from '@fortawesome/free-solid-svg-icons';

const Haradev = (props) => {
  const [email, setEmail] = useState();
  const [value, setValue] = useState(0);
  const [openInfo, setOpenInfo] = useState(false);
  const [addrTo, setAddrTo] = useState('0xca6BD4aE2F3F28BDAF95576e19814191BC763B47');
  const navigate = useNavigate();

  var misc;
  if (typeof props.template.Misc === 'string') {
    misc = JSON.parse(props.template.Misc);
    if (misc.bc !== undefined) {
      if (misc.bc.indexOf('NTTH') === -1) {
        return (<h4>this certificate cannot be sent to haradev</h4>)
      }
    }
    if (misc.price !== undefined) {
      if (misc.price.NTTH !== undefined && value === 0) {
        setValue(misc.price.NTTH);
      }
    }
    if (misc.to !== undefined) {
      if (addrTo === '0xca6BD4aE2F3F28BDAF95576e19814191BC763B47') {
        setAddrTo(misc.to);
      }
    }
  }

  async function submit() {
    const form = new FormData();

    form.append("address", "0xca6BD4aE2F3F28BDAF95576e19814191BC763B47");       //wallet ntity.io
    form.append("addrTo", addrTo);
    form.append("bcCode", 1653);
    form.append("idNtityDoc", props.doc.ID_Doc);
    form.append("value", value);
    form.append("email", email);

    const options = {
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL + "ntityio/mine",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    await axios
      .request(options)
      .then(function (response) {
        if (response.data.output.error === undefined && response.data.output !== undefined) {
          if (response.data.output.Txn_Hash !== undefined){
            navigate('/search/' + response.data.output.Txn_Hash, {
              state: {
                bca: response.data.output.Txn_Hash,
                congrat: props.doc.ID_Doc,
              }
            });
          } else {
            alert( response.data.output)
          }
        } else {
          alert(response.data.output.error + ' : ' + response.data.output.message)
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      <Container>
        <div className="confirm-page-container-summary-pay row">
          <div className="confirm-page-container-summary-pay-value">
            <div>
              <span className="currency-display-component__text"><h6>From : 0xca6BD4aE2F3F28BDAF95576e19814191BC763B47</h6></span>
            </div>
            <div>
              <span className="currency-display-component__text"><h6>To : {addrTo}</h6></span>
            </div>

          </div>
        </div>
        <hr style={{ position: 'relative', top: '18px' }} />
        <div className="confirm-page-container-summary-pay row">
          <div className="confirm-page-container-summary-pay-value">
          </div>
          <span
            onClick={() => setOpenInfo(!openInfo)}
            aria-controls="data_hex"
            aria-expanded={openInfo}
          >More information {openInfo?<FontAwesomeIcon icon={faAngleUp} />:<FontAwesomeIcon icon={faAngleDown} />}</span>
          <Collapse in={openInfo}>
            <div id="data_hex">
              <div className="currency-display-component" title="0">
                <span className="currency-display-component__text">Data hex</span>
              </div>
              <div className="currency-display-component" title="0">
                <Form.Control
                  as="textarea"
                  value={props.hexDatagram}
                  disabled
                />
              </div>
            </div>
          </Collapse>
        </div>
        <hr style={{ position: 'relative', top: '18px' }} />
        <Col className='mt-3'>
          <div className="transaction-detail-item row">
            <h6>In order to retrieve your certificate after creation, you can add the email below</h6>
            <div className='text-center'>
              <input type="email" className='form-control' name="email" id="email" value={email} onChange={emails => setEmail(emails.target.value)} />

            </div>
          </div>
        </Col>


        <Col className='text-center mt-3'>
          <Button variant="outline-secondary" id="submitSearch" onClick={() => submit()}>Send</Button>
        </Col>
      </Container>
    </>
  )
}


export default Haradev