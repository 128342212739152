import React, { useState } from 'react'
import {
  Container,
  Button,
  Form,
  Alert,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import validator from "validator";
import axios from 'axios';
import Footer from '../composent/index/footer';
import { useTranslation } from 'react-i18next';
import AlertDismissible from '../composent/shared/AlertDismissible';
import useAPI from '../hook/api';

const Register = () => {
  //state for form data
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const [msgError, setMsgError] = useState("");
	const [ validated, setValidated ] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    cpassword: "",
    email: ""
  });
  
	const {post} = useAPI();
  const handleInputData = input => e => {
    setValidated(false)
    const { value } = e.target;
    
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  
  const submitFormData = (e) => {
    e.preventDefault();
    setError(false);

    if (validator.isEmpty(formData.password)) {
      setError(true);
      setMsgError("Password is required");
      return false;
    } 

    if(formData.password.length < 8){
      setError(true);
      setMsgError("Your password need min 8 char");
      return false;
    }

    if (validator.isEmpty(formData.cpassword)) {
      setError(true);
      setMsgError("Confirm password is required");
      return false;
    } 

    if(formData.password !== formData.cpassword){
      setError(true);
      setMsgError("Password and confirm password must be equal");
      return false;
    }

    if (validator.isEmpty(formData.email)) {
      setError(true);
      setMsgError("Email is required");
      return false;
    } 

    if(!validator.isEmail(formData.email)){
      setError(true);
      setMsgError("Email format is not correct");
      return false;
    }

    if(!error){
      submit();
    }
  };
  
  async function submit() {
    const form ={};

      form["username"] = formData.email;
      form["password"] = formData.password;


      try {
          const data = await post(process.env.REACT_APP_SERVER_URL + 'auth/register',form);
          if (data.status === 201) {
            setError(false);
            setSuccess(true);
            setMsgError(data.msg);
          } else {
            setMsgError(data.msg);
            setError(true);
          }
      } catch(error) {
        setMsgError(error);
        setError(true);
      };
  
       
    }
    
  return (
      <Container>
        <div className='containerTitle'>{t('DICO_WORD_REGISTER')}</div>
					<Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} >
            <Card.Body>
              <Row>
                <Col md={6} style={{display:'flex'}}>
                  <div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase', margin:'auto'}}>
                      <img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
                      {t("DICO_WORD_HI")}<br/>{t("DICO_WORD_WELCOME")}
                  </div>
                </Col>
                <Col md={6}>
                  {error && <Alert variant="danger">{msgError}</Alert>}
                  {success && <Alert variant="success">{msgError}</Alert>}
                  {!success ? <Form onSubmit={submitFormData} validated={validated}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DICO_WORD_EMAIL")}</Form.Label>
                      <Form.Control name="email" defaultValue={formData.email} type="email" placeholder={t("DICO_WORD_EMAIL")} onChange={handleInputData("email")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DICO_WORD_PASSWORD")}</Form.Label>
                      <Form.Control name="password" defaultValue={formData.password} type="password" pattern='((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,128})' placeholder={t("DICO_WORD_PASSWORD")} onChange={handleInputData("password")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DICO_WORD_PASSWORD_REPEAT")}</Form.Label>
                      <Form.Control name="cpassword" defaultValue={formData.cpassword} type="password" placeholder={t("DICO_WORD_PASSWORD_REPEAT")}  onChange={handleInputData("cpassword")} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button variant="secondary"  style={{backgroundColor:'#f30438', borderRadius:0, border:0}} onClick={(e)=>{setValidated(true);submitFormData(e)}} type="submit">{t("DICO_WORD_REGISTER")}</Button>
                    </div>
                  </Form>:
                  <> 
                    <Col md={12} style={{display:'flex'}}>
                      <div style={{textAlign:'center',  paddingBottom: 30,  margin:'auto'}}>
                          {t("DICO_WORD_CHECK_EMAIL")}<br/>
                          {t("DICO_WORD_CHECK_SPAM")}
                      </div>
                    </Col>
                  </>}
                </Col>
              </Row>
            </Card.Body>
          </Card>
      </Container>
    );
  }
  
export default Register;
  