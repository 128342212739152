import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  Alert
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown,  faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useAuthorization } from '../../hook/AuthProvider';
import AlertDismissible from '../shared/AlertDismissible';

const PayApi = (props) => {
 
    
  const { authToken, wallet, signOut } = useAuthorization();
    const [address, setAddress] = useState("");
    const [balanceOk, setBalanceOk] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [errorF, seterrorF] = useState('');

    const url = {
      'NTT':'https://blockscout.haradev.com/api', //https://blockscout.ntity.io/
      'NTTH':'https://blockscout.haradev.com/api',
      'ETH':'https://blockscout.com/eth/mainnet/api'
    }
    const bcToBcCode = {
      'NTT':1553, //https://blockscout.ntity.io/
      'NTTH':1653,
      'ETH':1552
    }
    const navigate = useNavigate();

    useEffect(() => {
      if (props.sender !== null) {
        setAddress(props.sender);
        getCreditWallet(props.sender)
      }
      }, [props.sender]);

    const handleAddress = (e) => {
      setAddress(e.target.value);
      getCreditWallet(e.target.value)
    };

    const getCreditWallet = (addr) => {
      setBalanceOk(true);
      const form = new FormData();

      form.append("address", addr);       //wallet ntity.io
      form.append("module", 'account');
      form.append("action", 'balance');

      const options = {
        method: "POST",
        url: url[props.blockchain],
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data: form,
      };

      axios
        .request(options)
        .then(function (response) {
         if(response.data.result > 0){
          setBalanceOk(true);
         }else{
          setBalanceOk(false);
         }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectAdress(){
      if(props.sender!==undefined){
        console.log(props.sender);
        console.log(wallet);
        if(wallet.includes(props.sender)===false){
          seterrorF('you are not the owner of the wallet : '+props.sender);
        }
        return (<Form.Select disabled aria-label="select wallet" >
          <option value={props.sender}>{props.sender}</option>
      </Form.Select>)
      }else{
        return (
          <Form.Select aria-label="select wallet" onChange={handleAddress} defaultValue={address}>
            <option value="" >Open this select menu</option>
            {wallet !== null && wallet !== "undefined" ? wallet.split(',').map((wall) => {return <option value={wall}>{wall}</option>}):"Not defined please contact the administrator"}
          </Form.Select>)
      }
    }

    
  async function submit() {
    const form = new FormData();

    form.append("address", address);       //wallet ntity.io
    form.append("addrTo", address);
    form.append("bcId", bcToBcCode[props.blockchain]);
    form.append("idDoc", props.doc.ID_Doc);
    form.append("value", 0);
    console.log(props);
    const options = {
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL + "ntity/mine",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
          Authorization: `Bearer ${authToken}`
      },
      data: form,
    };

    await axios
      .request(options)
      .then(function (response) {
        
        if(response.data.status === 401 && response.data.path === "errorToken/mine"){
          seterrorF(response.data.msg);
          signOut();
        }else{
          if (response.data.success === true){
            navigate('/search/' + response.data.data.Txn_Hash, {
              state: {
                bca: response.data.data.Txn_Hash,
                congrat: props.doc.ID_Doc,
              }
            });
          } else {
            seterrorF( response.data.msg)
          }
        } 
      })
      .catch(function (error) {
        seterrorF(error);
      });
  }

  return (
    <>
      <Container>
        
			  <AlertDismissible variant="danger"  text={errorF}/>
        {errorF !=='' ? 
          <Alert  variant="danger">{errorF}</Alert>
          :
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {selectAdress()}
              {address !== "" && balanceOk === false ?<Alert  variant="danger">balance null</Alert>:''}
            </Form.Group>
            <div>
              <span
                onClick={() => setOpenInfo(!openInfo)}
                aria-controls="data_hex"
                aria-expanded={openInfo}
              >More information {openInfo?<FontAwesomeIcon icon={faAngleUp} />:<FontAwesomeIcon icon={faAngleDown} />}</span>
              <Collapse in={openInfo}>
                <div id="data_hex">
                  <div className="currency-display-component" title="0">
                    <span className="currency-display-component__text">Data hex</span>
                  </div>
                  <div className="currency-display-component" title="0">
                    <Form.Control
                      as="textarea"
                      value={props.hexDatagram}
                      disabled
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <Button variant="outline-secondary" id="submitSearch" onClick={() => submit()}>Send</Button> 
          </Form>
        }
      </Container>
    </>
  )
}


export default PayApi