import React, { useEffect, useState } from 'react'
import {
  Container
} from 'react-bootstrap';
import Brand from './brand';
import Related from './related';
import Content from './content';
import Send from './send';
import axios from 'axios';
import GitgraphHist from '../addEvent/gitgraphHist';


export const NtityDoc = (props) => {
  const [template, setTemplate] = useState();
  const [rela, setRela] = useState();

  useEffect(() => {
    if (props.doc.ID_Format !== 1507) {
      const form = {};
      form["bca"] =  props.doc.ID_Template;
      const options = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'ntity',
        params: form
      };

      axios.request(options).then(function (response) {
        setTemplate(response.data.data[0]);
      }).catch(function (error) {
        console.error(error);
      });
    }
    getRelation();
  }, [props.doc]);

  function getRelation(){
    const form = {};
    form['TX'] = props.doc.Path;
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'ntity/related',
      params: form
    };

    axios.request(options).then(function (response) {
      setRela(response.data.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  return (
    <Container>
      {(props.doc.Path !== null) ? '' : <Send  doc={props.doc} template={template}/>}
      <div id="searchRes"><Brand doc={props.doc} small={false} /></div>
      {(rela === undefined || rela === null) ? '' : <Related doc={props.doc} temp={template}/>}
      {((rela === undefined || rela === null) || props.doc.ID_Format === 1507) ? '' : <GitgraphHist doc={props.doc} cbd={rela.branch} Orientation={'Horizontal'}></GitgraphHist>}
      <div style={{'max-width':'710px', 'margin':'auto'}}>
        <Content template={template} doc={props.doc} />
      </div>
      
    </Container>
  )
}

export default NtityDoc;