import React from 'react'

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faDochub, faDocker, faGithubAlt, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = ({ showPopup }) => {

  const { t, i18n } = useTranslation();
  const currentDate = new Date();
  const currentYear = `${currentDate.getFullYear()}`;

  return (
    <div className="footer-dark">
      <footer>
          <div className="container">
              <div className="row">
                  <div className='col-sm-12 col-md-3 item'>
                    <div className="col item social"><a href="https://twitter.com/ntity_io/"><FontAwesomeIcon icon={faTwitter} /></a><a href="https://hub.docker.com/r/ntity/node"><FontAwesomeIcon icon={faDocker} /></a><a href="https://discord.com/B8DhkPrjsR"><FontAwesomeIcon icon={faDiscord} /></a><a href="https://github.com/ntity-core/node"><FontAwesomeIcon icon={faGithubAlt} /></a></div>
                  </div>
                  <div className="col-sm-12 col-md-3 item">
                      <h3>Info</h3>
                      <ul>
                          <li><a href="https://docs.ntity.io/ntt.php">Ntity Blockchain</a></li>
                          <li><a href="https://docs.ntity.io/mining.php">Mining</a></li>
                          <li><a href="https://docs.ntity.io/whitepaper_short.html">White paper</a></li>
                      </ul>
                  </div>
                  <div className="col-sm-12 col-md-3 item">
                      <h3>About</h3>
                      <ul>
                          <li><a href="https://www.cryptolex.com/">Company</a></li>
                          <li><a href="https://api.ntity.io/swagger/">API</a></li>
                          <li><a href="https://docs.ntity.io/">Docs</a></li>
                          <li><a href="https://ntity.io/privacy">Privacy</a></li>
                          <li><a href="https://ntity.io/terms">Terms and Conditions</a></li>
                          <li><a href="#" onClick={showPopup}>Announcement</a></li>
                      </ul>
                  </div>
                  <div className="col-sm-12 col-md-3 item">
                      <h3>Services</h3>
                      <h6>Mainnet</h6>
                      <ul>
                          <li><a href="https://stats.ntity.io/">Net-stats</a></li>
                          <li><a href="https://blockscout.ntity.io/">Block explorer</a></li>
                      </ul>
                      <hr />
                      <h6>Testnet</h6>
                      <ul>
                        <li><a href="https://stats.haradev.com/">Net-stats</a></li>
                        <li><a href="https://blockscout.haradev.com/">Block explorer</a></li>
                      </ul>
                  </div>

              </div>
              <p className="copyright">Ntity.io © 2018 - {currentYear}</p>
          </div>
      </footer>
  </div>
  )
}
export default Footer
