import { useState, useMemo,useEffect } from 'react'
import { FormControl, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { sortRows, filterRows, paginateRows } from '../../hook/helpers'
import Pagination from './Pagination'
import { useTranslation } from 'react-i18next'
import { faSort, faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from './Loader'

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const ResultTable = ({ columns, rows, ct='',rowsPerPage=10, paged={'page': undefined,'setPage':undefined,'totalC':undefined},sorted={ 'order': undefined, 'orderBy': undefined },searched={'search':undefined ,'setSearch':undefined }}) => {
		const [activePage, setActivePage] = useState(1)
	const [search, setSearch] = useState("");
	const { t} = useTranslation();

	const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
	const filteredRows = useMemo(() => filterRows(rows, search), [rows, search])
	const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])

	if(paged.page!==undefined){
		var calculatedRows = [...rows]
		var count = paged.totalC
	}else{
		var calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
		var count = filteredRows.length
	}

	useEffect(() => {
		if(ct==='refreshPage'){
			setActivePage(paged.page);
			setSort( {order: sorted.order.val, orderBy: sorted.orderBy.val })
		}
	},[paged.page]);
	
	useEffect(() => {
		if(ct==='refreshPage'){
			setActivePage(paged.page);
			setSort( {order: sorted.order.val, orderBy: sorted.orderBy.val })
			setSearch( searched.search)
		}
	},[rows]);

	const previousClickHandler = () => {
		if(paged.setPage!==undefined){
			paged.setPage(paged.page - 1);
		}else{
			setActivePage(activePage - 1);
		}
	};
	const nextClickHandler = () => {
		if(paged.setPage!==undefined){
			paged.setPage(paged.page + 1);
		}else{
			setActivePage(activePage + 1);
		}
	};
	const pageChangeHandler = (activePage) => {
		if(paged.setPage!==undefined){
			paged.setPage(activePage);
		}else{
			setActivePage(activePage);
		}
	};


	const handleSearch = (value) => {
		if(searched.setSearch!==undefined){
			searched.setSearch(value);
			setSearch( value)
			paged.setPage(1);
		}else{
			setActivePage(1);
			setSearch(value);
		}
	}

	const handleSort = (accessor) => {
		if(ct==='refreshPage'){
			paged.setPage(1);
			sorted.order.set(sorted.order.val == 'asc'  && sorted.orderBy.val === accessor ? 'desc' : 'asc')
			sorted.orderBy.set(accessor)
			setSort((prevSort) => ({
				order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
				orderBy: accessor,
			}))
		}else{
			setActivePage(1);
			setSort((prevSort) => ({
				order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
				orderBy: accessor,
			}))
		}
	}

	return (
		<>
		
			{(ct==='refreshPage'&& searched.search===undefined) ? '': 
				<FormControl
				type="search"
				className='formSearch'
				placeholder={`🔎 ${t('DICO_WORD_SEARCH')}`}
				value={search}
				onChange={(event) => {handleSearch(event.target.value);}}
			 />}
			<Table className='resultTable'>
				<thead>
					<tr>
						{columns.map((column) => {
							if(column.accessor !== 'ID'){
								const sortIcon = () => {
									if (column.accessor === sort.orderBy) {
										if (sort.order === 'asc') {
											return <FontAwesomeIcon icon={faSortDown} />
										}
										return <FontAwesomeIcon icon={faSortUp} />
									} else {
										return <FontAwesomeIcon icon={faSort} color='#BBB' />
									}
								}
								if( column.noFilter !== true){
									return (
										<th key={column.accessor}>
											<span>{column.label}</span>
											<span onClick={() => handleSort(column.accessor)} style={{marginLeft: "0.5rem"}}>{sortIcon()}</span>
										</th>
									)
								}else{
									return (
										<th key={column.accessor}>
											<span>{column.label}</span>
											
										</th>
									)
								}
								
							}
						})}
					{ct=== 'Doc'?
						<th>
							{t('DICO_WORD_LINK')}
						</th>
					:''}
					</tr>
				</thead>
				<tbody>
					{ct==='refreshPage' && rows==='loading' ? <><Loader /></>: 
						<>
							{calculatedRows.map((row) => {
								var extra =''
								if(ct === 'Doc'){
									extra = <td>{row.Path !== null ? <Link to={{ pathname: "/search/"+row.Path, bca: row.Path }} state={{ bca: row.Path }} >Detail</Link> : <Link to={{ pathname: "/ntityDoc/id/"+row.ID_Doc }} state={{ doc:row }}>Detail</Link>}</td>
								}
								var bgColor='';
								if(row.bgColor !== undefined){
									bgColor = 'table-'+row.bgColor
								}
								return (
									<tr className={bgColor} key={row.ID}>
										{columns.map((column) => {
											if(column.accessor !== 'ID'){
												if (column.format) {
													return <td key={column.accessor}>{column.format(row[column.accessor])}</td>
												}
												if(Array.isArray(row[column.accessor])){
													return <td className='col-sm-3'  key={column.accessor} ><div  style={{maxHeight:'2.6rem', overflow:'auto'}} >{row[column.accessor].join(', ')}</div></td>
												}
												return <td key={column.accessor} style={{wordWrap:'break-word',wordBreak:'break-word'}}>{row[column.accessor]}</td>
											}
										})}
										{extra}
									</tr>
								)
							})}
						</>
					}
				</tbody>
			</Table>

			{count > 0 ? (
				<Pagination
						currentPage={activePage}
						postsPerPage={rowsPerPage}
						noOfPosts={count}
						onPreviousClick={previousClickHandler}
						onNextClick={nextClickHandler}
						onPageChange={pageChangeHandler}
						rowsPerPage={25}
					/>
			) : (
				<p>{t('DICO_WORD_NODATA')}</p>
			)}
		</>
	)
}
export default ResultTable;


	