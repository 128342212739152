import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import ResultTable from '../../composent/shared/Result.table';
import { useLocalStorage } from '../../hook/useLocalStorage';
import ModalWallet from '../../composent/corporate/addWallet';
import { CSVLink } from 'react-csv';
import { Link, Navigate } from 'react-router-dom';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ModalBuyCredit from '../../composent/profil/buyCredit';
import ModalWalletInfo from '../../composent/profil/modalWalletInfo';


const ProductCertDetail = () => {
    const { t } = useTranslation();
    const { authToken, wallet, signOut } = useAuthorization();
    const [msgError, setMsgError] = useState('');
    const [page, setPage] = useState(0);
    const [result, setResult] = useState('');
    const [rowResult, setRowResult] = useState('');
    const [nbRes, setNbRes] = useState('');

    useEffect(() => {
        if(authToken !== null){
          //getInfo()
          getPrimaryWallet()
        }
      },[authToken]);

      async function getPrimaryWallet(){
		const options = {
			method: "GET",
			url: process.env.REACT_APP_SERVER_URL + "pay/detailMyProduct",
			headers: {
                Authorization: "Bearer " + authToken,
				"Content-Type":
					"multipart/form-data; boundary=---011000010111000001101001",
			},
            params:{'product':'100220','status':'full','page':page}
		};  

		await axios.request(options).then(function  (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/brandIdentity"){
              setMsgError(response.data.msg);
                      signOut();
            }else{
                if(response.data.data === undefined){
                    var tmprowUsername =[];
                  }else{
                    var tmprowUsername = [...response.data.data.res];
                  }
                    tmprowUsername.map( async(entry,index)=>{
                        tmprowUsername[index]['Name'] = entry.Name; 
                        tmprowUsername[index]['Status'] = entry.Status; 
                        tmprowUsername[index]['Quantity'] = entry.Quantity; 
                      tmprowUsername[index]['Created'] = new Date(tmprowUsername[index]['Created']).toISOString('en-CA').slice(0, 19).replace(/\//g, '-').replace("T", " "); 
                  });
                  setNbRes(response.data.data.nbRes)
                setRowResult(tmprowUsername);
                setResult(response.data.data.res);
                
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/brandIdentity"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

      const columns = [
        { accessor: 'ID_Trans', label: t("ID") },
        { accessor: 'Name', label: t("DICO_WORD_NAME") },
        { accessor: 'Status', label: t("DICO_WORD_ADDRESS") },
        { accessor: 'Quantity', label: t("DICO_WORD_ACCOUNT_BALANCE") },
        { accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
      ]

      return (
        <>
                <Container>
                  <AlertDismissible variant="danger"  text={msgError}/>
                <div className='containerTitle'>{t("DICO_WORD_DETAIL")} : NTT_Cert</div>
                    <Card style={{ marginTop: 50, marginBottom: 100 }}>
                        <Card.Body>
                            {/* <Button variant="outline-primary" onClick={() => {setmodalShowImport(true);}}>
                                {t('DICO_WORD_ADD')}
                            </Button> */}
                            {result === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowResult} columns={columns}/>}
                        </Card.Body>
                    </Card>
                </Container>
    
            </>
      );
}
export default ProductCertDetail;