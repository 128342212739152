import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { faTrash, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalProduct from '../../composent/corporate/addProduct';
import { useLocalStorage } from '../../hook/useLocalStorage';
import AlertDismissible from '../../composent/shared/AlertDismissible';

const Product = () => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [resultASku, setResultASku] = useState("");
	const [rowSku, setrowSku] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [modalConfirmShow, setModalConfirmShow] = useState(false);
	const [member, setMember] = useLocalStorage("member",'');
	const [skuDelete, setSKUDelete] = useState();
	const [nmeDelete, setNameDelete] = useState();
	const [ct, setCT] = useState('add');
	const [sku, setSKU] = useState();
	const [titleM, settitleM] = useState('');
	const [api, setAPI] = useLocalStorage("api",'');
	const [gbm,setGBM] = useLocalStorage("gbm",'');


	const [msgError, setMsgError] = useState('');
	useEffect(() => {
		if(authToken !== null){
			getMyProducts()
		}
	},[authToken]);

	useEffect(() => {
		if(!modalShow){
			getMyProducts();
		}
	},[modalShow]);

	if(member.Right['1066']===undefined){
		window.location ='/corporate';
	}

	async function getMyProducts() {
		const form = {};
        form["Username"] = gbm;

		const options = {
			method: 'GET',
			url: api + 'corporate/product',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/product"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					var tmprowSku = [...response.data.data];
					console.log();
					tmprowSku.map((entry,index)=>{
						tmprowSku[index]['ID'] = entry.Sku; 
						tmprowSku[index]['Created'] = new Date(tmprowSku[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
						let tmp = [];
						if(member.Right['1066'].U == 1){
							tmp.push(<Button variant="outline-secondary" key={entry.Sku+'edit'} onClick={() => {setModalShow(true); settitleM(t("DICO_WORD_MODIFY")+' - '+t("DICO_WORD_PRODUCTS")+' - '+entry['Sku']);setCT('edit');setSKU(entry);}}> <FontAwesomeIcon icon={faPenToSquare}/></Button>)
						}
						if(member.Right['1066'].D == 1){
							tmp.push(<Button variant="outline-danger" key={entry.Sku+'delete'} onClick={() => {setModalConfirmShow(true);setSKUDelete(entry['Sku']);setNameDelete(entry['Name'])}}><FontAwesomeIcon icon={faTrash} /></Button>)
						}
						tmprowSku[index]['Edit'] = <>{tmp}</>
					});
					setrowSku(tmprowSku);
					setResultASku(response.data.data);
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/product"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	async function removeProduct() {
		const form = new FormData();
		form.append("sku", skuDelete);
		const options = {
			method: 'DELETE',
			url: api + 'corporate/product',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/product"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if(response.data.data === true){
					setModalConfirmShow(false)
					getMyProducts();
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/product"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	const columns = [
		{ accessor: 'ID', label: '' },
		{ accessor: 'Sku', label: 'SKU' },
		{ accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
		{ accessor: 'Edit', label: t('DICO_WORD_ACTION') },
	];
	
	return (
	< >
		<Container>
			<AlertDismissible variant="danger"  text={msgError}/>
			<div className='containerTitle'>{t("DICO_WORD_PRODUCTS")}</div>
			<Card style={{ marginTop: 50, marginBottom: 100 }}>
				<Card.Body>
					{(member.Right['1066'].I == 1) && (<Button variant="outline-primary" onClick={() => {setModalShow(true); settitleM(t("DICO_WORD_ADD") + ' ' + t("DICO_WORD_PRODUCTS"));setCT('add')}}>
						{t("DICO_WORD_ADD")} 
					</Button>)}

					{resultASku === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowSku} columns={columns}/>}
				</Card.Body>
			</Card>
			<Modal show={modalConfirmShow} onHide={() => setModalConfirmShow(false)} size="sm" aria-labelledby="contained-modal-title-vcenter">
				<Modal.Header closeButton>
					<Modal.Title >
						{t('DICO_WORD_DELETE_CONFIRM')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='text-center'>
					<div className='text-center'>{skuDelete} - {nmeDelete}</div>
				</Modal.Body>
				<Modal.Footer >
					<Row style={{width: '100%'}}>
						<Button variant="outline-primary" style={{width: '50%'}} onClick={() => setModalConfirmShow(false)}>
						{t('DICO_WORD_NO')}
						</Button>
					<Button variant="outline-danger" style={{width: '50%'}}  onClick={() => removeProduct()}>
							{t('DICO_WORD_YES')}
						</Button>
						</Row>
				</Modal.Footer>
			</Modal>
		</Container>
		<ModalProduct ct={ct} skuM={sku} show={modalShow} onHide={() => setModalShow(false)} title={titleM}/>
	</>
	);
}

export default Product;