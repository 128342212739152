import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../composent/shared/Loader";
import NtityDoc from '../composent/ntityDoc/ntityDoc';
import Footer from '../composent/index/footer';
import { Container } from 'react-bootstrap';
import { useAuthorization } from '../hook/AuthProvider';

export const Doc = () => {
  const params = useParams();
  const [Doc, setDoc] = useState();
  const [loading, setloading] = useState(true);
  const { authToken, signOut } = useAuthorization();

  useEffect(() => {
    if(params.ID_Doc !== undefined){
      getNtityDoc('id');
    }
  }, [params.ID_Doc, authToken])

  useEffect(() => {
    if(params.Path !== undefined){
      getNtityDoc('path');
    }
  }, [params.Path, authToken])

  async function getNtityDoc(ct) {
    const form = {};

    let options;
    let action;

    if(ct === 'id'){
      action = "getById";
      form["bca"] = params.ID_Doc;
    }else if(ct === 'path'){
      action = "ntity";
      form["bca"] = params.Path;
    }

    if(authToken !== undefined && authToken !== null){
      options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
      };  
    } else {
      form["ntityIo"] = true; 
      options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
      };
    }


    await axios
      .request(options)
      .then(function (response) {
        if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
          signOut();
        }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
          
          if(Array.isArray(response.data.data)){
            setDoc(response.data.data[0]);
          }else{
            setDoc(response.data.data);
        }
          setloading(false);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
        {loading === true ? <Container className='text-center mb-4'><Loader /></Container> : <Container className='text-center mb-4'>{(typeof (Doc) === 'object') ? <NtityDoc doc={Doc} /> : <h1> Noting found </h1>}</Container>}
    </>
  );
}

export default Doc;