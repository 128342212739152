import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Table,
    Badge
  } from 'react-bootstrap';
  import { Link } from "react-router-dom";
  import {useSysVar} from "../../hook/SysVarF";
  import { useAuthorization } from "../../hook/AuthProvider";
import { useTranslation } from 'react-i18next';
  
export const Content = ({doc, template=undefined}) => {
  let rendu;
  const [keyChild, setKeyChild] = useState();
  const [headerTable, setHeaderTable] = useState();
  const [headerCard, setHeaderCard] = useState();
  const { authToken } = useAuthorization();
  const { t } = useTranslation();
  const { sysVarId } = useSysVar();
  useEffect(() => {
    if (template !== undefined) {
      const temp = JSON.parse(template.Content);
      const tmp = JSON.parse(doc.Content);
      const headers = Object.entries(temp).map((entry) => {
        if (!entry[0].includes('T') && !entry[0].includes('C1')) {
          let key = entry[0];
          if (key.includes("*")) {
            key = key.replace("*", "");
          }

          if (key.includes("!")) {
            key = key.replace("!", "");
          }

          // if (key.includes("^")) {
            key = key.replace("^", "");
          // }

          var className = 'nttValue';
          var value= '-'
          if(tmp[entry[0]] !== undefined){
            value = tmp[entry[0]];
          }
          if(tmp[key] !== undefined ){
            value = tmp[key];
          }
          if(value === '' ){
          }else if(tmp[key] === 'xxxxx'){
            className = className+' nttPrivate';
          }
          switch (key) {
            case 'y':
            case 's':
            case 'adc':
            case 'tc':
            case 'ft':
            case 'st':
              if(typeof tmp[key] === 'number'){
                var sysVar = sysVarId(tmp[key])
                if(Object.keys(sysVar).length > 1){
                  tmp[key] = sysVar.Name
                }
              }
              break;
          }
          return (
            <div className="nttValueDisplay" key={key}>
              <div className="nttField">{entry[1]}</div>
              <span className={className}>{value}</span>
            </div>
          );
        }
        else {
          if (entry[0].includes('C1')) {
            setKeyChild(entry[0]);
          } else{
            setKeyChild(undefined);
          }
        }
      });
      setHeaderCard(headers)
    } else{
      setHeaderCard(undefined);
    }
  }, [template]);

  useEffect(() => {
    if (template !== undefined) {
      const temp = JSON.parse(template.Content);
      let tmp;
      if (keyChild !== undefined) {
        if(Array.isArray(temp[keyChild])){
          tmp = temp[keyChild][0]
        }else{
          if(temp[keyChild] !== undefined){
            tmp = temp[keyChild];
          }else{
            tmp = temp;
          }
        }
        const headers = Object.entries(tmp).map((entry) => {
          if (entry[0].includes('T')) {
            return (
              <th key="number">#</th>
            );
          } else if (!entry[0].includes('y')) {
            return (
              <th key={entry[0]}>{entry[1]}</th>
            );
          } else if (entry[0].includes('y')) {
            if (entry[1].y !== 100200) {
              return (
                <th key={entry[0]}>{entry[1]}</th>
              );
            }
          }
        });
        setHeaderTable(headers);
      }else{
        setHeaderTable(undefined);
      }
    }
  }, [template, keyChild]);



  if (doc.ID_Format === 1507) {
    rendu = (
      <>
        <Row>
          <Col className='text-center'>
            <Link className="btn btn-outline-secondary" style={{ width: 250 }} to={{ pathname: "/create" }} state={{ certificat: doc.Content, TX: doc.Path }}>Create ntity</Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div id="searchResTable">
              <div className="nttValueTitle">
                <div className="nttValueDisplay">
                  <div className="nttField">Structure</div>
                  <span className="nttValue">
                    <div id='json' className="codeSource">{JSON.stringify(JSON.parse(doc.Content), null, 4)}</div>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>);
  } else {
    let list;
    let titleTable = '';

    if (template !== undefined) {
      const tmp = JSON.parse(doc.Content);
      const child = Object.entries(tmp).filter((entry) => entry[0].includes('C'));
      if (child[0] !== undefined) {
        if (child[0][1] !== null) {
          titleTable = (<div className="nttValueDisplay" ><br/>
                <span className="nttValue">{t('DICO_NTT_CERTIF_TABLE')}</span>
              </div>)
          
          list = Object.entries(child[0][1]).map((entry, key) => {
            const tmp = Object.entries(entry[1]).map((e) => {
              if(!e[0].includes('T')){
                if(entry[1].y === 100200 && e[0].includes('d')){
                  try {
                    var q =  JSON.parse(e[1]);
                    if( typeof q === 'string'){ 
                      q =  JSON.parse(q);
                      q = <span key={Object.keys(q)[0]}><Badge bg="secondary"> {Object.keys(q)[0]}</Badge>{q[Object.keys(q)[0]]}</span>
                    }else{
                      q = Object.entries(q).map((m) => {
                        return(
                            <span key={m[0]}><Badge bg="secondary"> {m[0]}</Badge>{m[1]}</span>
                          );
                        });
                    }
                  } catch (error) {
                    q =  <span key={e[0]}><Badge bg="danger"> Error json</Badge>{e[1]}</span>
                  }
                  
                  return (<td key={e[0]}>{q}</td>)
                }else{
                  if(e[1]=== 'xxxxx'){
                    return (<td key={e[0]} style={{'color': 'transparent','textShadow': '0 0 5px rgba(0, 0, 0, 0.5)'}}>{e[1]}</td>);
                  }else{
                    switch (e[0]) {
                      case 'y':
                      case 's':
                      case 'adc':
                      case 'tc':
                      case 'ft':
                      case 'st':
                        if(isNaN(e[1]) === false){
                          if(typeof e[1] === 'number'){
                            var sysVar = sysVarId(e[1])
                            console.log('hhhhhhhhhhhhhhhhhhh');
                            console.log(sysVar.length);
                            if(Object.keys(sysVar).length > 1){
                              console.log('hhhhhhhhhhhhhhhhhhh');
                              e[1] = sysVar.Name
                            }
                          }
                        }
                        break; 
                        default:
                        if(isNaN(e[1]) === false){
                          if(e[1].includes("http")||e[1].includes("https")||e[1].includes("www")){
                            return (<a key={e[0]} >{e[1]}</a>);
                          }
                        }
                        return (<td key={e[0]} >{e[1]}</td>);
                        break;
                        
                      }
                      return (<td key={e[0]} >{e[1]}</td>);
                    }
                  
                }
                
              }
            });
            return (
              <tr key={'child' + key}>
                <td className="nttDiscret nttTableCnt">{key+1}</td>
                {tmp}
              </tr>
            );
          });
        }
      }
    }

    rendu = (
      <Row>
        <Col>
          <div id="searchResTable">
            {headerCard}
            {titleTable}
            <Table id="nttData" max-width="100%" striped size="sm">
              <thead>
                <tr>
                  {headerTable}
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>);
  }

  return rendu;
}
export default Content;