import React, { useState } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const Faucet = () => {
	const { authToken } = useAuthorization();
	const [result,setResultat] = useState('');
	const [bca, setBca] = useState('');
	const { t, i18n } = useTranslation();
    
	i18n.t("key", { 'interpolation': {'escapeValue': false} });
  
	async function getFaucet() {
	  const form = new FormData();
	  if(bca === ''){
		setResultat('Please fill your wallet');
		return false;
	  }
	  
	  form.append("bca", bca);
	  form.append("ID_Type", 1035);
	  
	  const options = {
		method: 'POST',
		url: process.env.REACT_APP_SERVER_URL+'pay/reward',
		headers: {
		  'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
		  Authorization: 'Bearer ' + authToken
		},
		data: form
	  };
  
	  await axios.request(options).then(function (response) {
		setResultat(response.data.msg);
	  }).catch(function (error) {
		console.error(error);
	  });
	}
  
	return (
	  <>
	  <Container>
        <div className='containerTitle'>Faucet</div>
			<Card style={{ marginTop: 50, marginBottom: 100 }}>
				<Card.Body style={{ textAlign: 'center' }}>
					<p dangerouslySetInnerHTML={{__html: t('DICO_NIO_REC_FAUCET').replace("%", "0.5")}} />
  
					<div className="maxiSearch" >
						<input name="bca" id="faucetInput" className="form-control fieldSearch" value={bca} onChange={(text => setBca(text.target.value))} placeholder="Wallet : 0x13a28b39c2d..." />
					</div>
					<Button variant="outline-secondary" onClick={() => getFaucet()}>{t("DICO_NIO_FAUCET")}</Button>
					<div id="faucetAnswer" className="mt-2">
						{result}
					</div>
				</Card.Body>
			</Card>
		</Container>
	  </>
	);
  }
  
  export default Faucet;
