
import React, { useState } from 'react'
import {
	Button,
	Form,
	Card,
	Row,
	Col
} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from "validator";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faBuilding} from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useLocalStorage } from '../../hook/useLocalStorage';
import useAPI from '../../hook/api';
import { useLanguage } from '../../i18n';

const LoginCorporate = () => {
	let navigate = useNavigate();
	const { t } = useTranslation();
	const { signIn } = useAuthorization();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [corporate, setCorporate] = useState([]);
	const { changeLanguage } = useLanguage();

	const [member,setMember] = useLocalStorage("member",'');
	const [api,setAPI] = useLocalStorage("api",'');
	const [gbm,setGBM] = useLocalStorage("gbm",'');


	const {post, get} = useAPI();


	const [msgError, setMsgError] = useState("");
	const [formData, setFormData] = useState({
		corporate: "",
		email: "",
		password:""
	});

	useEffect(() => {
		getListCorporate();
	},[]);

	const  SelectAPI = () => {
		corporate.forEach((val) => {
			if(val.Firstname === formData.corporate.toLowerCase()){
				GetAPI(val.ID_GBM);
				setGBM(val.Username);
			}
		})
	}

	const handleInputData = input => e => {
		const { value } = e.target;

		setFormData(prevState => ({
			...prevState,
			[input]: value
		}));
	}

	const submitFormData = (e) => {
		console.log("HEHEHEHHEHE");
		e.preventDefault();
		setError(false);

		if (validator.isEmpty(formData.email)) {
			setError(true);
			setMsgError("Email is required");
			return false;
		}

		if (validator.isEmpty(formData.password)) {
			setError(true);
			setMsgError("Password is required");
			return false;
		}


		if(!validator.isEmail(formData.email)){
			setError(true);
			setMsgError("Email format is not correct");
			return false;
		}
		if(!error){
			console.log("HEHEHEHHEHE");

			SelectAPI();
		}
	};

	async function GetAPI(gbm) {
		let jsonData = {
			Username: gbm,
		};

		try {
			const data = await get(process.env.REACT_APP_SERVER_URL + 'corporate/info',jsonData);
			setAPI(data.data['4000']);
			submit(data.data['4000']);
		} catch (error) {
			return false;
		}
	}

	async function submit(api) {
		let jsonData = {
			username: formData.email,
			password: formData.password
		};
		  
		if (formData.fa !== '') {
			jsonData.totp = formData.fa;
		}

		try {
			const data = await post(api + 'auth',jsonData);
			getDefaultLang(data.data,api);
		} catch (error) {
			setMsgError(error);
			if(error === 'login2fa'){
				setError(false);
			}else{
				formData.fa = ''
				setError(true);
			}
		}
	}

	async function getDefaultLang(authToken,api) {
		try {
			const data = await get(api + 'user/lang',null,authToken);
			changeLanguage(data.data);
			getMember(authToken,api);
		} catch (error) {
			setMsgError(error);
			setError(true);
			return false;
		}
	}

	async function getMember(authToken,api) {
		let jsonData = {
			id: 'self',
		};

		try {
			const data = await get(api + 'corporate/member',jsonData,authToken);
			console.log(data);
			setSuccess(true);
			setMsgError(data.msg);
			setGBM(data.data.Username);
			setMember(data.data);
			getPrimaryWallet(authToken,formData.email,api);
		} catch (error) {
			setMsgError(error);
			setError(true);
			return false;
		}
	}

	async function getPrimaryWallet(authTk,email,api) {
		try {
			const data = await get(api + 'user/brandIdentity',null,authTk);
			signIn(authTk, email,data.data);
			window.location.assign("/corporate/");
		} catch (error) {
			setMsgError(error);
			setError(true);
		}
	}

	async function getListCorporate() {
		try {
			const data = await get(process.env.REACT_APP_SERVER_URL + 'corporate/list');
			console.log(data);
			setCorporate(data.data);
		} catch (error) {
			setMsgError(error);
			setError(true);
		}
	}

	return (
		<>
			<Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="card-login">
				<Card.Body>
					{error && <AlertDismissible variant="danger"  text={msgError} />}
					<div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase'}}>
						<img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
						{t("DICO_WORD_LOGIN")}
					</div>
					{error && <AlertDismissible variant="danger"  text={msgError} />}
					{success && <AlertDismissible variant="success"  text={msgError} />}
					<Form onSubmit={submitFormData}>
						{msgError !== 'login2fa'? (
							<>
								<Form.Group className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i><FontAwesomeIcon icon={faBuilding}/></i>
										</span>
									</div>
									<Form.Control name="corporate" defaultValue={formData.corporate} type="text" placeholder={t("DICO_WORD_CORPORATE")} onChange={handleInputData("corporate")} />
								</Form.Group>
								<Form.Group className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i><FontAwesomeIcon icon={faUser}/></i>
										</span>
									</div>
									<Form.Control name="email" defaultValue={formData.email} type="email" placeholder={t("DICO_WORD_EMAIL")} onChange={handleInputData("email")} />
								</Form.Group>
								<Form.Group className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i><FontAwesomeIcon icon={faKey}/></i>
										</span>
									</div>
									<Form.Control name="password" defaultValue={formData.password} type="password" placeholder={t("DICO_WORD_PASSWORD")} onChange={handleInputData("password")} />
								</Form.Group>
							</>
						):(
							<Form.Group className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<i><FontAwesomeIcon icon={faKey}/></i>
									</span>
								</div>
								<Form.Control name="fa" defaultValue={formData.fa} type="text" placeholder={t("DICO_WORD_2FA")} onChange={handleInputData("fa")} />
							</Form.Group>
						)}
						<Row>
							<Col>
								<Button variant="primary" type="submit" style={{width:'100%'}}>{t("DICO_WORD_LOGIN")}</Button>
							</Col>
						</Row>
						<Row style={{ fontSize:'0.8rem', textAlign:'center', marginTop:30}}>
							<Col>
								<Link to={{ pathname: "./forget" }} className="searchA">{t("DICO_WORD_PASSWORD_LOST")}</Link>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
}

export default LoginCorporate;
