

import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';

  import { faFlag, faUserGroup,faCertificate, faBuildingColumns,faSliders,faBarcode, faWallet, faChartSimple,faUser,faRing, faParachuteBox, faFile} from "@fortawesome/free-solid-svg-icons";
  import { useTranslation } from "react-i18next";
  import { useLocalStorage } from "../../hook/useLocalStorage";


import Footer from '../../composent/index/footer';
import Profil from './profil';
import Sidebar from '../../composent/shared/Sidebar';
import { useAuthorization } from '../../hook/AuthProvider';
import NotFound from '../NotFound';
import MyCertificats from '../certificate';
import Credit from '../credit';
import Faucet from './faucet';
import Wallet from './wallet';
import ProductCertDetail from './productCertdetail';
import ProfilCorp from '../corporate/profilCorp';

const IndexP = () => {
  const {authUser,authToken} = useAuthorization();
	const { t,i18n } = useTranslation();
	const [menu, setMenu] = useState([]);
	const [wait, setwait] = useState(0);
  const [api, setAPI] = useLocalStorage("api",'');
  useEffect(() => {
    if(wait <=1 ){
      setTimeout(function () {
        setwait(wait+1);
    }, 2000);
  }
  if(authToken === null && wait===1){
    window.location ='/';
  }
	},[wait]);

  useEffect(() => {

		const items = []; 
    
  if(process.env.REACT_APP_SERVER_URL === api){
    items.push({ type:'linkR', label: t("DICO_WORD_PROFILE"), link: "/profil" , icon: faBuildingColumns});
    items.push({ type:'linkR', label: t("DICO_WORD_WALLET"),		link: "/profil/wallet" , icon: faWallet});
      items.push({ type:'linkR', label: t("DICO_WORD_CERTIFICATE"),			link: "/profil/myCertificates" , icon: faFile});
      items.push({ type:'linkR', label: t("DICO_NIO_FAUCET")+' NTTH', link: "/profil/faucet" , icon: faParachuteBox});
    }else{
     
    }
			setMenu(items);
      
	},[i18n.language]);
  let route;
  if(process.env.REACT_APP_SERVER_URL === api){
    route =
    <>
      <Route path="/productCert" element={ <ProductCertDetail /> } />
      <Route path="/myCertificates" element={ <MyCertificats /> } />
      <Route path="/faucet" element={ <Faucet /> } />
      <Route path="/profil" element={ <Profil /> } />
      <Route path="/wallet/:wallet" element={ <Credit /> } />
      <Route path="/wallet" element={ <Wallet /> } />
      <Route path="" element={<Profil />} /> 
      <Route path="/" element={<Profil />} /> 
      <Route path="*" element={<NotFound />} /> 
    </>
  }else{
    route =<>
        <Route path="profil" element={<ProfilCorp />} /> 
        <Route path="" element={<ProfilCorp />} /> 
        <Route path="/" element={<ProfilCorp />} /> 
        <Route path="*" element={<ProfilCorp />} /> 
      </>
  }
  return (
        <>
          <div className="d-flex">
            
            {menu && menu.length && <Sidebar itemM={menu} />}
            <Routes>
              {route}
            </Routes>
          </div>
        </>
  );
}

export default IndexP;