
// I18nProvider.js
import React, { useEffect, useState, useCallback, useContext, createContext } from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { useLocalStorage } from './hook/useLocalStorage';

// Importez les fichiers de traduction
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationIT from "./locales/it/translation.json";
import translationES from "./locales/es/translation.json";

const supportedLanguages = ['en', 'de', 'fr', 'es', 'it'];

// Créez le contexte
const LanguageContext = createContext();

// Hook personnalisé pour utiliser le contexte
export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

// Provider component
export const LanguageProvider = ({ children }) => {
    const [isI18nInitialized, setIsI18nInitialized] = useState(false);
    const [storedLang, setStoredLang] = useLocalStorage('lang', '');

    const getBrowserLanguage = () => {
        if (typeof window === 'undefined') return 'en';
        
        const browserLang = (navigator.language || navigator.userLanguage).split('-')[0];
        return supportedLanguages.includes(browserLang) ? browserLang : 'en';
    };

    const changeLanguage = useCallback((newLang) => {
        if (supportedLanguages.includes(newLang)) {
            setStoredLang(newLang);
            i18n.changeLanguage(newLang);
        }
    }, [setStoredLang]);

    useEffect(() => {
        const initI18n = async () => {
            let lang = storedLang;

            if (!lang || !supportedLanguages.includes(lang)) {
                lang = getBrowserLanguage();
                setStoredLang(lang);
            }

            await i18n
                .use(initReactI18next)
                .init({
                    resources: {
                        en: { translation: translationEN },
                        fr: { translation: translationFR },
                        es: { translation: translationES },
                        it: { translation: translationIT },
                        de: { translation: translationDE },
                    },
                    lng: lang,
                    fallbackLng: 'en',
                    interpolation: {
                        escapeValue: false,
                    },
                });

            setIsI18nInitialized(true);
        };

        initI18n();
    }, [storedLang, setStoredLang]);

    if (!isI18nInitialized) {
        return null;
    }

    return (
        <I18nextProvider i18n={i18n}>
            <LanguageContext.Provider value={{ changeLanguage }}>
                {children}
            </LanguageContext.Provider>
        </I18nextProvider>
    );
};
