import React from 'react'
import { Button } from 'react-bootstrap';

import { useTranslation } from "react-i18next";

const Contribute = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="page-section" style={{backgroundColor:'#fff', marginTop:'10rem'}}>
      <div className='container'>
        <div className="row text-center">
          <div className="col-lg-6 col-md-12">
            <div style={{textAlign: 'left'}}>
              <p style={{color:'#808080',fontStyle: 'bold',fontSize: '12pt'}}>DEVS</p>
              <h2 className='mt-4' style={{fontSize: '32pt'}}>Ntity API</h2>
              <p style={{marginTop: '5rem'}}>{t("DICO_NIO_API")}</p>
              <a href='https://api.ntity.io/swagger/' style={{padding: '1rem 4rem 1rem 4rem'}} className='btn btn-outline-danger'>{t("DICO_NIO_TRY_NOW")}</a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <img className="mb-3 img-fluid" src="./img/ntt_api.png" alt="" style={{height: '450px',width:'auto'}}/>
          </div>
        </div>
        <hr style={{maxWidth: 500,margin: 'auto', marginBottom: '2rem'}}/>
        <div className="row text-center">
          <div className="col-lg-6 col-md-12">
            <img className="mb-3 img-fluid" src="./img/ntt_link.png" alt="" style={{height: '450px',width:'auto'}}/>
          </div>
          <div className="col-lg-6 col-md-12">
            <div style={{textAlign: 'left'}}>
              <p style={{color:'#808080',fontStyle: 'bold',fontSize: '12pt'}}>DEVS</p>
              <h2 className='mt-4' style={{fontSize: '32pt'}}>Links</h2>
              <a href='https://docs.ntity.io/ntt.php' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>Ntity Blockchain</a><br/><br/>
              <a href='https://docs.ntity.io/mining.php' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>Mining</a><br/><br/>
              <div className='row'>
                <div className="col-lg-6 col-md-12">
                  <h2 className='mt-4' style={{fontSize: '24pt'}}>Ntity</h2>
                  <a href='https://blockscout.ntity.io/' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>Block explorer</a><br/><br/>
                  <a href='https://stats.ntity.io/' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>NetStats</a>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h3 className='mt-4' style={{fontSize: '24pt'}}>Haradev</h3>
                  <a href='https://blockscout.haradev.com/' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>Block explorer</a><br/><br/>
                  <a href='https://stats.haradev.com/' style={{padding: '.5rem 4rem .5rem 4rem', minWidth: 215}} className='btn btn-outline-danger'>NetStats</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Contribute;
