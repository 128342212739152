import React, { useEffect, useState } from 'react'

const Sku = (props) => {
  const [image, setImage] = useState();
  const [desc, setDesc] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    if(props !== undefined && props.result !== undefined){
      if(props.result.Params !== null && props.result.Params !== undefined){
        let img = JSON.parse(props.result.Params);
        setImage(img[0]);
      }
      if(props.result.Description !== null && props.result.Description !== undefined){
        let desc = JSON.parse(props.result.Description);
        setDesc(desc[0])
      }
      if(props.result.Name !== null && props.result.Name !== undefined){
        setName(props.result.Name);
      }      
    }
  },[props]);

  return (
    <>
    {props.result && 
      <>
        <p style={{textAlign: 'center'}}>{name}</p>
        <img src={image} alt="Logo" style={{width:'100%'}} />
        <p style={{textAlign: 'justify'}}>{desc}</p>
      </>
    }
    </>
  )
}

export default Sku;