import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Pagination from "../composent/shared/Pagination";
import Loader from "../composent/shared/Loader";
import { Container } from 'react-bootstrap';
import axios from 'axios';
import Resultat from '../composent/search/resultat';
import Congrat from '../composent/sendToBc/congrat';
import Footer from '../composent/index/footer';
import SearchCmp from '../composent/index/search';

const Search = ({ bca = '', block = '', stat = '', docType = '' }) => {
  const [search, setSearch] = useState(bca);
  const [template, setTemplate] = useState(false);
  const [resultat, setResultat] = useState('');
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const [nbRes, setNbRes] = useState(0);
  const [congrat, setCongrat] = useState(false);
  const params = useLocation();

  useEffect(() => {
    if (params.state) {
      setSearch(params.state.bca);
      submit(params.state.bca);
      if (params.state.congrat) {
        setCongrat(true);
      }
    } else if (params.pathname.split('/')[2] !== undefined) {
      setSearch(params.pathname.split('/')[2]);
      submit(params.pathname.split('/')[2]);
    } else if (params.search.split('&')[1].split('=')[1] !== undefined) {
      setSearch(params.search.split('&')[1].split('=')[1]);
      submit(params.search.split('&')[1].split('=')[1]);
    }

  }, [params.state, page])

  async function submit(bca = '') {
    const form = {};
    if (bca === '') {
      bca = search;
    } 

    form["bca"] = bca;

    if(params.state !== null && params.state.block !== undefined){
      form["Blockchain"] = params.state.block;
    }else{
      form["Blockchain"] = '[1551,1552,1553]';
    }

    if(params.state !== null && params.state.stat !== undefined){
      form["Status"] = params.state.stat;
    }else{
      form["Status"] = 20;
    }

    console.log(params);
    if (params.state !== null && params.state.docType !== undefined) {
      form["Type"] = '1507';
      console.log("adsasdasdads")
      setLimit(51);
    } else {
      setLimit(12);
    }

    form["limit"] = limit;
    form["page"] = (page - 1);

    // for (var pair of form.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }

    const options = {
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL + 'ntity/',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      params: form
    };

    await axios.request(options).then(function (response) {
      if (response.status === 401) {
        console.log('login error', response.data.msg);
      } else {
        setNbRes(response.data.nbRes)
        setResultat(response.data.data);
        if (response.data.nbRes <= 15) { setPage(1) }
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  const previousClickHandler = () => {
    setPage(page - 1);
  };
  const nextClickHandler = () => {
    setPage(page + 1);
  };
  const pageChangeHandler = (page) => {
    setPage(page);
  };

  return (
    <>
        <Container>
          <div className='text-center mt-4'>
            <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> NTITY Search</h2>
          </div>
          <SearchCmp searchs={false} />
        </Container>
        <Container className='text-center mb-4'>
          {resultat === '' ? <Loader /> : <Resultat res={resultat} show={template} />}
          {resultat === '' ? '' : <Pagination
            currentPage={page}
            postsPerPage={limit}
            noOfPosts={nbRes}
            onPreviousClick={previousClickHandler}
            onNextClick={nextClickHandler}
            onPageChange={pageChangeHandler}
          />}
        </Container>
        {(congrat === true) ? <Congrat ID_Doc={params.state.congrat} /> : ''}
    </>
  )
}


export default Search;
