import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Form } from 'react-bootstrap';
import Loader from '../../composent/shared/Loader';
import { useAuthorization } from '../../hook/AuthProvider';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import Brand from '../../composent/ntityDoc/brand';
import FileUpload from '../../composent/shared/fileUpload';

const useNtityDoc = (authToken, params, signOut) => {
    const [Doc, setDoc] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getNtityDoc = async () => {
            const form = { bca: params.TX };
            const options = {
                method: 'GET',
                url: `${process.env.REACT_APP_SERVER_URL}ntity`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
                },
                params: form,
            };
            try {
                const response = await axios.request(options);
                if (response.data.status === 401 && response.data.message === 'Bad Token Key or Key missing, please register') {
                    signOut();
                } else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
                    setDoc(Array.isArray(response.data.data) ? response.data.data[0] : response.data.data);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (authToken) getNtityDoc();
    }, [authToken, params, signOut]);

    return { Doc, loading };
};

const Sell = () => {
    const { authToken, signOut } = useAuthorization();
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [price, setPrice] = useState(1.00);
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [msgError, setMsgError] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');
    const [validated, setValidated] = useState(false);
    const [newUserInfo, setNewUserInfo] = useState({ profileImages: [] });
    const [isEditMode, setIsEditMode] = useState(false);
    const [existingImages, setExistingImages] = useState([]);

    const { Doc, loading } = useNtityDoc(authToken, params, signOut);

    useEffect(() => {
        if (params.TX) {
            fetchExistingData();
        }
    }, [params.TX]);

    const fetchExistingData = async () => {
        const options = {
            method: 'GET',
            url: `${process.env.REACT_APP_SERVER_URL}marketplace/sell`,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            params: { TX: params.TX },
        };
        try {
            const response = await axios.request(options);
            console.log('response.data.data');
            console.log(response.data.data);
            console.log('response.data.data');
            if (response.data.data) {
                setPrice(response.data.data.price);
                setDescription(response.data.data.description);
                setType(response.data.data.type);
                const parsedImages = JSON.parse(response.data.data.img || '[]');
                const imageFiles = await convertUrlsToFiles(parsedImages);
                console.log(parsedImages);
                console.log(imageFiles);
                setNewUserInfo({ ...newUserInfo, profileImages: imageFiles })
                setIsEditMode(true);
            }
        } catch (error) {
            console.error('Error fetching existing data:', error);
        }
    };

    const convertUrlsToFiles = async (urls) => {
        const files = [];
        for (const url of urls) {
            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], url, { type: blob.type });
            files.push(file);
        }
        return files;
    };

    const uploadImages = async (files) => {
        const uploadedUrls = [];
        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}misc/uploadImg`, formData, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data && response.data.data.Hash) {
                    uploadedUrls.push(response.data.data.Hash);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                throw error;
            }
        }
        return uploadedUrls;
    };

    const submitForm = async () => {
        try {
            console.log(newUserInfo.profileImages);
            const uploadedImageUrls = await uploadImages(newUserInfo.profileImages);

            const form = new FormData();
            form.append("TX", Doc.Path);
            form.append("price", price);
            form.append("description", description);
            form.append("type", type);
            var urls=[];
            uploadedImageUrls.forEach(url => {
                urls.push(url);
            });
            form.append("img",JSON.stringify(urls) );
            const method = isEditMode ? 'PUT' : 'POST';
            const options = {
                method: method,
                url: `${process.env.REACT_APP_SERVER_URL}marketplace/sell`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: form,
            };

            const response = await axios.request(options);
            if (response.data.status === 401 && response.data.path === "errorToken/marketplace") {
                setMsgError(response.data.msg);
                signOut();
            } else if ((response.data.status === 201 && response.data.success === true) || (response.data.status === 200 && response.data.success === true)) {
                setMsgSuccess(<>{t('DICO_WORD_SUCCESS')} </>);
            } else {
                setMsgError(response.data.msg);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setMsgError('An error occurred while submitting the form.');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.checkValidity() === true) {
            submitForm();
        }
        setValidated(true);
    };

    const updateUploadedFiles = (files) => setNewUserInfo({ ...newUserInfo, profileImages: files });

    return (
        <>
            <AlertDismissible variant="danger" text={msgError} />
            <AlertDismissible variant="success" text={msgSuccess} />
            <div className='mt-4 row' style={{ padding: '2rem' }}>
                {loading ? <Container className='text-center mb-4'><Loader /></Container> : (
                    <>
                        <div id="searchRes">
                            <div style={{ height: 'fit-content' }}><Brand doc={Doc} small={true} /></div>
                        </div>
                        <Col sm={8}>
                            <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> <span style={{ textTransform: 'capitalize' }}>Sell</span> : {Doc['Name']}</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Form.Label>{t('DICO_WORD_PRICE')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder={t('DICO_WORD_PRICE') + ' : NTT'}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>{t('DICO_WORD_DESCRIPTION')}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>{t('DICO_WORD_TYPE')}</Form.Label>
                                    {[{'name':'DICO_WORD_DIGITALE','type':2545,'value':'Digital'}, {'name':'DICO_WORD_PHYSICAL','type':2546,'value':'Physique'}].map((typed) => (
                                        <Form.Check
                                            required
                                            inline
                                            type={'radio'}
                                            id={`rad-${typed.name}`}
                                            label={t(typed.value)}
                                            value={typed.type}
                                            checked={type === typed.type}
                                            onChange={(e) => setType(typed.type)}
                                        />
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} controlId="validationCustom03">
                                    <Form.Label>{t('DICO_WORD_IMAGE')}</Form.Label>
                                    <FileUpload
                                        accept=".jpg,.png,.jpeg"
                                        label=""
                                        multiple
                                        updateFilesCb={updateUploadedFiles}
                                        existingImages={newUserInfo.profileImages}
                                    />
                                </Form.Group>
                                <Button type="submit">Submit form</Button>
                                {isEditMode === true ? 
                                <Button onClick={() => { navigate('/marketplace/' + Doc.Path) }}>{t('DICO_NTT_VIEW_CERTIFICAT')}</Button>:''}
                            </Form>
                        </Col>
                    </>
                )}
            </div>
        </>
    );
};

export default Sell;
