import { useState, useEffect } from 'react';

export const UseLocalStorageWithTimeout = (key, defaultValue, timeoutInMinutes) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      const parsedValue = JSON.parse(storedValue);
      const { data, expiry } = parsedValue;
      if (expiry && new Date().getTime() > expiry) {
        localStorage.removeItem(key);
        return defaultValue;
      }
      return data;
    }
    return defaultValue;
  });

  useEffect(() => {
    const expiryTime = timeoutInMinutes ? new Date().getTime() + timeoutInMinutes * 60000 : null; // Conversion des minutes en millisecondes
    localStorage.setItem(key, JSON.stringify({ data: value, expiry: expiryTime }));
  }, [key, value, timeoutInMinutes]);

  const clearStorage = () => {
    localStorage.removeItem(key);
    setValue(defaultValue);
  };

  return [value, setValue, clearStorage];
};
