import * as React from 'react';

const AuthContext = React.createContext({
  status: 'idle',
  authToken: null,
  authUser: null,
  wallet: null,
  signIn: (token,authUser,wallet) => {},
  signOut: () => {},
});

export const useAuthorization = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('Error');
  }
  return context;
};

export const AuthProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, {
    status: 'idle',
    authToken: null,
    authUser: null,
    wallet: null,
  });

  React.useEffect(() => {
    const initState = async () => {
      const token = await localStorage.getItem('token');
      const user = await localStorage.getItem('user');
      const wallet = await localStorage.getItem('wallet');


      try {
        if(token != undefined){
          dispatch({
            type: 'SIGN_IN',
            token: token,
            authUser: user,
            wallet: wallet,
          });
        }else{
          dispatch({
            type: 'SIGN_OUT',
            token: undefined,
            authUser: undefined,
            wallet: undefined,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
    initState();
  }, []);

  const actions = React.useMemo(
    () => ({
      signIn: async (token,authUser,wallet) => {
        dispatch({type: 'SIGN_IN', token, authUser, wallet});
        localStorage.setItem('token',token);
        localStorage.setItem('user',authUser);
        localStorage.setItem('wallet',wallet);
      },
      signOut: async () => {
        dispatch({type: 'SIGN_OUT'});
        await localStorage.removeItem('token');
        await localStorage.removeItem('user');
        await localStorage.removeItem('wallet');
        window.location ='/';
      },
    }),
    [dispatch],
  );
  return (
    <AuthContext.Provider value={{...state, ...actions}}>
      {props.children}
    </AuthContext.Provider>
  );
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_OUT':
      return {
        ...state,
        status: 'signOut',
        authToken: null,
        authUser: null,
        wallet: null,
      };
    case 'SIGN_IN':
      return {
        ...state,
        status: 'signIn',
        authToken: action.token,
        authUser: action.authUser,
        wallet: action.wallet
      };
  }
};