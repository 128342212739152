import React from 'react'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
        <div className='text-center mt-4'>
          <div style={{fontSize : "4rem", margin : "5rem", fontWeight : 100}}><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo error-logo" alt="logo"/> ERROR 404</div>
          <div style={{fontSize : "2rem", margin : "2rem"}}>Page Not found</div>
          <p>The Page you are looking for doesn't exist or an other error occurred</p>
          <p>Please go back to the homepage</p>
          <Link to={{ pathname: "/" }} className="btn btn-outline-secondary mb-4">{t("DICO_WORD_HOME_BACK")} </Link>
        </div>
    </Container>
  );
}

export default NotFound;


