import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import ResultTable from '../../composent/shared/Result.table';
import { useLocalStorage } from '../../hook/useLocalStorage';
import ModalWallet from '../../composent/corporate/addWallet';
import { CSVLink } from 'react-csv';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ModalBuyCredit from '../../composent/profil/buyCredit';
import ModalWalletInfo from '../../composent/profil/modalWalletInfo';

const CSVDownload = (props) => {
	const btnRef = useRef(null)
	useEffect(() => btnRef.current?.click(), [btnRef])
	return (
		<CSVLink {...props}>
			<span ref={btnRef} />
		</CSVLink>
	)
}

const Wallet = () => {
    const { t } = useTranslation();
    const { authToken, wallet, signOut } = useAuthorization();
    const [resultAUsername, setResultAUsername] = useState("");
    const [rowUsername, setrowUsername] = useState([]);
    const [titleM, settitleM] = useState(t("DICO_WORD_ADD")+' '+t("DICO_WORD_WALLET"));
    const [api, setAPI] = useLocalStorage("api",'');
    const [csvData, setCSVData] = useState();
    const [date, setDate] = useState();
    const [msgError, setMsgError] = useState('');
    const [modalShowImport, setmodalShowImport] = useState(false);
    const [modalBuyCredit, setmodalBuyCredit] = useState(false);
    const [modalwalletSelected, setmodalwalletSelected] = useState(false);
    const [modalInfowallet, setmodalInfowallet] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(authToken !== null){
          //getInfo()
          getPrimaryWallet()
        }
      },[authToken]);

    useEffect(() => {
      if(authToken !== null){
        //getInfo()
      }
    },[rowUsername]);
      
	async function getPrimaryWallet(){
		const options = {
			method: "GET",
			url: process.env.REACT_APP_SERVER_URL + "user/brandIdentity",
			headers: {
                Authorization: "Bearer " + authToken,
				"Content-Type":
					"multipart/form-data; boundary=---011000010111000001101001",
			},
            params:{'ct':'full'}
		};  

		await axios.request(options).then(function  (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/brandIdentity"){
              setMsgError(response.data.msg);
                      signOut();
            }else{
                if(response.data.data === undefined){
                    var tmprowUsername =[];
                  }else{
                    var tmprowUsername = [...response.data.data];
                  }
                  setResultAUsername(response.data.data);
                  var wallets = [];
                    tmprowUsername.map( async(entry,index)=>{
                      wallets.push(entry.Adress);
                      tmprowUsername[index]['Batch'] = entry.Batch; 
                      tmprowUsername[index]['Created'] = new Date(tmprowUsername[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
                      tmprowUsername[index]['Edit'] = <>
                      <Button variant="outline-primary" onClick={() => {setmodalwalletSelected(entry.Adress);setmodalBuyCredit(true); settitleM(t('DICO_WORD_BUY')+' - '+entry.Adress)}}> {t('DICO_WORD_BUY')} </Button>
                      <Button variant="outline-primary" onClick={() => {setmodalwalletSelected(entry.Adress);setmodalInfowallet(true); settitleM(t('DICO_WORD_DETAIL')+' - '+entry.Adress)}}> {t('DICO_WORD_DETAIL')} </Button>
                      <Button variant="outline-primary" onClick={() => {}}> {t('DICO_WORD_TRANSFERT')} </Button>
                    </>
                  });
                  getNtt(wallets).then((res)=>{
                    res.map((entry,index)=>{
                      tmprowUsername[index]['credit']= entry;
                    });
                    myProduct(tmprowUsername).then((inner)=>{
                      setrowUsername(inner);
                      setResultAUsername(response.data.data);
                    });
                    
                  }).catch(function (error) {
                    myProduct(tmprowUsername).then((inner)=>{
                      setrowUsername(inner);
                      setResultAUsername(response.data.data);
                    });
                  });
                
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/brandIdentity"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

  async function getNtt(wallets) {

    const options = {
      method: 'GET',
      url: 'https://blockscout.ntity.io/api?module=account&action=balance&address=' + wallets.join(","),
    };

    return await axios.request(options).then(function (response) {
      if (typeof(response.data.result) === "number" || typeof(response.data.result) === "string") {
        return ['֍ '+Number(response.data.result)/ Math.pow(10,18)]  ;
      }else{
        return response.data.result.map((entry,index)=>{ 
          return '֍ '+entry.balance / Math.pow(10,18) ;
        });
      }
     
    }).catch(function (error) {
      return 'error';
    });
  }


	async function myProduct(wallets){
		const options = {
			method: "GET",
			url: process.env.REACT_APP_SERVER_URL + "pay/myProduct",
			headers: {
                Authorization: "Bearer " + authToken,
				"Content-Type":
					"multipart/form-data; boundary=---011000010111000001101001",
			},
            params:{'ct':'full'}
		};  

		return await axios.request(options).then(function  (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/myProduct"){
              setMsgError(response.data.msg);
              signOut();
            }else{
              var tmpRes = {'Batch': t("DICO_WORD_CERTIFICATE"), 'Adress':'Credite', 'credit':' 0', 'edit':[]};
              if(!(Array.isArray(response.data.data) && response.data.data.length === 0) || (typeof response.data.data === 'object' && Object.keys(response.data.data).length === 0)){
                if(response.data.data.hasOwnProperty(100220)){
                  tmpRes['credit'] = '֍ '+response.data.data[100220].Quantity;
                }
                tmpRes['Edit'] = <>
                    <Button variant="outline-primary" onClick={() => {setmodalwalletSelected('INNER');setmodalBuyCredit(true); settitleM(t('DICO_WORD_BUY')+' - '+t("DICO_WORD_CERTIFICATE"))}}> {t('DICO_WORD_BUY')} </Button>
                    <Button variant="outline-primary" onClick={() => {navigate('/profil/productCert')}}> {t('DICO_WORD_DETAIL')} </Button>
                </>
                var tmprowUsername = [tmpRes,...wallets];
                return tmprowUsername;
              }
            }
                
			
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/myProduct"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}
  


      const columns = [
        { accessor: 'Batch', label: t("DICO_WORD_NAME") },
        { accessor: 'Adress', label: t("DICO_WORD_ADDRESS") },
        { accessor: 'credit', label: t("DICO_WORD_ACCOUNT_BALANCE") },
        { accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
        { accessor: 'Edit', label: t('DICO_WORD_ACTION') },
      ]
  return (
    <>
            <Container>
			  <AlertDismissible variant="danger"  text={msgError}/>
            {csvData !== undefined ? <CSVDownload data={csvData} filename={'WALLET_Batch_'+ date} target="_blank" />: <></>}
            <div className='containerTitle'>{t("DICO_WORD_WALLET")}</div>
                <Card style={{ marginTop: 50, marginBottom: 100 }}>
                    <Card.Body>
                        {/* <Button variant="outline-primary" onClick={() => {setmodalShowImport(true);}}>
                            {t('DICO_WORD_ADD')}
                        </Button> */}
                        {resultAUsername === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowUsername} columns={columns}/>}
                    </Card.Body>
                </Card>
            </Container>
            <ModalWallet
                show={modalShowImport}
                onHide={() => setmodalShowImport(false)}
                title={titleM}
            />
            <ModalBuyCredit
                show={modalBuyCredit}
                onHide={() => setmodalBuyCredit(false)}
                title={titleM}
                wallet={modalwalletSelected}
            />
            <ModalWalletInfo
				show={modalInfowallet}
				onHide={() => setmodalInfowallet(false)}
				title={titleM}
				wallet={modalwalletSelected}
          />

        </>
  );
}

export default Wallet;