import React, { useEffect } from 'react'
import {
  Container,
  Card,
} from 'react-bootstrap';
import axios from 'axios';

import Footer from '../composent/index/footer';
import { useAuthorization } from '../hook/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hook/useLocalStorage';

const Confirm = () => {
  const { authToken, signOut } = useAuthorization();
  const navigate = useNavigate();
  const [number, setNumber] = useLocalStorage('number','');

  useEffect(() => {
    if(authToken !== undefined && authToken !== null){
      confirmPayment();
    }
  }, [authToken]);

  async function confirmPayment() {
    const form = new FormData();
    const url = new URLSearchParams(window.location.search);

    form.append("payment_intent",url.get("payment_intent"));
    form.append("type",'ntity.io');

    const options = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL + 'pay/confirm',
      headers: {
        Authorization: "Bearer " + authToken,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };

    axios.request(options).then(function (response) {
      console.log(response);
      if(response.data.success){
        var timeleft = 10;
        var downloadTimer = setInterval(function(){
          if(timeleft <= 0){
            clearInterval(downloadTimer);
            navigate('../profil/wallet');
          } else {
            document.getElementById("countdown").innerHTML = "You will be redirect in " + timeleft + " seconds";
          }
          timeleft -= 1;
        }, 1000);
      }else{
        document.getElementById("countdown").innerHTML = response.data.msg;
      }
    }).catch(function (error) {
      console.error(error);
    });
  }



  return (
    <>
        <Container>
          <Card style={{ marginTop: 50, marginBottom: 100 }}>
            <Card.Body className='text-center'>
              <div className="loader"><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png"  alt="logo" /><h3>Thank you</h3></div>
              <p>Thank you to buy {number} ntity coin. You will be creditate in the next 10 seconds.</p>
              <br/>
              <div id="countdown"></div>
            </Card.Body>
          </Card>
      </Container>
    </>
  )
}

export default Confirm;
