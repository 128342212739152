import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

import {
  Container,
  Col,
  Collapse,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from "react-router-dom";

const SearchCmp = ({searchs = true, dark = false}) => {
  let navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [blockchain, setBlockchain] = useState();
  const [status, setStatus] = useState();
  const [collapse, setCollapse] = useState(false);
  const [template, setTemplate] = useState();
  const [input, setInput] = useState('searchInput');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let test = document.getElementById("search");
    test.addEventListener("mouseover", function (event) {
      document.getElementById("searchInput").focus();
    }, false);
    if (dark){
      setInput('searchInputDark')
    }
  }, []);

  const handleType = () => {
    let tmp;
    if (template === undefined) {
      tmp = true;
    } else {
      tmp = !template
    }
    setTemplate(tmp);
  };

  useEffect(() =>{
    if(blockchain !== undefined || status !== undefined || template !== undefined)
    navigate('/search/'+search,{
      state: {
        bca: search,
        block: blockchain,
        stat: status,
        docType: template,
      }
    });
  },[status,blockchain,template]);

  const goSearch = () => {
    if(search != ''){
      navigate('/search/'+search,{
        state: {
          bca: search,
          block: blockchain,
          stat: status,
          docType: template,
        }
      });
    }

  }

  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      navigate('/search/'+search,{
        state: {
          bca: search,
          block: blockchain,
          stat: status,
          docType: template,
        }
      });
    }
  }

  return (
    <div className="page-section" id="search">
      <div>
        <div className={input}>
            <Container>
              <Row>
                <Col lg={11} xs={9}><input type="text" name="bca" id="searchInput" onKeyPress={key => onKeyUp(key)} className="fieldSearch" value={search} onChange={searchs => setSearch(searchs.target.value)} placeholder={t("DICO_NIO_SEARCH_BLC")} style={{}}/></Col>
                <Col lg={1} xs={3} id="searchButton" style={{textAlign:'right'}}>
                  <span onClick={() => goSearch()} style={{marginRight: '1rem'}}>
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                  <span onClick={() => setCollapse(!collapse)} aria-expanded={collapse} aria-controls="collapseOne">
                    <FontAwesomeIcon icon={faFilter} />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Collapse in={collapse}>
                    <div id="filtre">
                    
                      <hr />
                      <Container>
                        <Row>
                          <Col><label>Blockchain</label></Col>
                          <Col>
                            <select id="Blockchain" className='selectSearch' name="Blockchain" value={blockchain} onChange={blockchains => setBlockchain(blockchains.target.value)}>
                              <option value="[1551,1552,1553]">All mainnet</option>
                              <option value="1552">Ethereum</option>
                              <option value="1553">Ntity</option>
                              <option value="1551">Bitcoin </option>
                              <option value="[1651,1652,1653]">All testnet</option>
                              <option value="1652">Ethereum Testnet</option>
                              <option value="1653">Ntity Testnet</option>
                              <option value="1651">Bitcoin Testnet</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col><label>Status</label></Col>
                          <Col>
                            <select id="Status" className='selectSearch' name="Status" value={status} onChange={stat => setStatus(stat.target.value)}>
                              <option value="20">Succes</option>
                              <option value="14">Pending</option>
                              <option value="24">Rejected</option>
                            </select>
                          </Col>
                        </Row>
                        <Row>
                          <Col><label htmlFor="template">Template</label></Col>
                          <Col>
                          <input type="checkbox" onChange={types => handleType()} id="template" name="Type" value="1507" />
                          </Col>
                        </Row>
                      </Container>                       
                    </div>
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <Link id="btnSearch" to={{ pathname: "/search/", bca: search }} state={{ bca: search, block: blockchain, stat: status, docType: template }} className="invisible"></Link>
                </Col>
              </Row>
            </Container>
        </div>
        {searchs && 
        <div className="pb-5 mt-2">
          <div style={{color: '#808080'}}>
          {t("DICO_NIO_EXPLORE")}
          <Link to={{ pathname: "/search/", bca: "masterblock", }} state={{ bca: "masterblock", blockchain }} className="searchA" style={{color: '#808080', textDecoration: 'none'}}>Masterblock.</Link>
          </div>
        </div>}
      </div>
    </div>
  )
}
export default SearchCmp
