
import React, { useState } from 'react'
import {
	Container,
	Button,
	Form,
	Card,
	Row,
	Col,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import validator from "validator";
import { useAuthorization } from '../hook/AuthProvider';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey} from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../composent/shared/AlertDismissible';
import { useLocalStorage } from '../hook/useLocalStorage';
import useAPI from '../hook/api';

const Login = () => {
	const { t } = useTranslation();
	const { signIn } = useAuthorization();
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [, setMember] = useLocalStorage("member",'');
	const [, setAPI] = useLocalStorage("api",'');
	const [, setGBM] = useLocalStorage("gbm",'');  
	const [ validated, setValidated ] = useState(false);

	const {post, get} = useAPI();
	
	const [msgError, setMsgError] = useState("");
	const [formData, setFormData] = useState({
		email: "",
		password:"",
		fa: ""
	});
	
	const handleInputData = input => e => {
		setValidated(false);
		const { value } = e.target;
		
		setFormData(prevState => ({
			...prevState,
			[input]: value
		}));
	}
	
	const submitFormData = (e) => {
		e.preventDefault();
		setError(false);


		if (validator.isEmpty(formData.email)) {
			setError(true);
			setMsgError("Email is required");
			return false;
		} 

		if (validator.isEmpty(formData.password)) {
			setError(true);
			setMsgError("Password is required");
			return false;
		} 


		if(!validator.isEmail(formData.email)){
			setError(true);
			setMsgError("Email format is not correct");
			return false;
		}

		if(!error){
			submit();
		}
	};

	async function submit() {
		let jsonData = {
			username: formData.email,
			password: formData.password
		};
		  
		if (formData.fa !== '') {
			jsonData.totp = formData.fa;
		}

		try {
			const data = await post(process.env.REACT_APP_SERVER_URL + 'auth',jsonData);
			setError(false);
			setSuccess(true);
			setMsgError(data.msg);
			setAPI(process.env.REACT_APP_SERVER_URL);
			getMember(data.data)
		} catch (error) {
			setMsgError(error);
			if(error === 'login2fa'){
				setError(false);
			}else{
				formData.fa = ''
				setError(true);
			}
		}
	}


	async function getMember(authToken) {
		let jsonData = {
			id: 'self',
		};

		try {
			const data = await get(process.env.REACT_APP_SERVER_URL + 'corporate/member',jsonData,authToken);
			setGBM(data.data.Username);
			setMember(data.data);
			getPrimaryWallet(authToken,formData.email);
		} catch (error) {
			getPrimaryWallet(authToken,formData.email);
		}
	}

	async function getPrimaryWallet(authTk,email) {

		try {
			const data = await get(process.env.REACT_APP_SERVER_URL + 'user/brandIdentity',null,authTk);
			signIn(authTk, email,data.data);
			window.location.assign("/");
		} catch (error) {
			setMsgError(error);
			setError(true);
		}
	}
	  	
	return (
		<>
			<Container>
				<Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="card-login">
					<Card.Body>
							<div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase'}}>
								<img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
								{t("DICO_WORD_LOGIN")}
							</div>
							{error && <AlertDismissible variant="danger"  text={msgError} />}
							{success && <AlertDismissible variant="success"  text={msgError} />}
							<Form onSubmit={submitFormData} validated={validated}> 
								{msgError !== 'login2fa'? (
									<>
										<Form.Group className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i><FontAwesomeIcon icon={faUser}/></i>
												</span>
											</div>
											<Form.Control name="email" defaultValue={formData.email} type="email" placeholder={t("DICO_WORD_EMAIL")} onChange={handleInputData("email")} />
										</Form.Group>
										<Form.Group className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i><FontAwesomeIcon icon={faKey}/></i>
												</span>
											</div>
											<Form.Control name="password" defaultValue={formData.password} type="password" placeholder={t("DICO_WORD_PASSWORD")} pattern='((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,128})' onChange={handleInputData("password")} />
											<Form.Control.Feedback type='invalid'>{t("DICO_PREG_PASSWORD")}</Form.Control.Feedback>
										</Form.Group>
									</>
								):(
									<Form.Group className="input-group mb-3">
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i><FontAwesomeIcon icon={faKey}/></i>
											</span>
										</div>
										<Form.Control name="fa" defaultValue={formData.fa} type="text" placeholder={t("DICO_WORD_2FA")} onChange={handleInputData("fa")} />
									</Form.Group>
								)}
								<Row>
									<Col>
										<Button variant="primary" type="submit" style={{width:'100%'}} onClick={(e)=>{setValidated(true);submitFormData(e)}}>{t("DICO_WORD_LOGIN")}</Button>
									</Col>
								</Row>
								<Row style={{ fontSize:'0.9rem', textAlign:'center', marginTop:30}}>
									<Col>	
										<Link to={{ pathname: "/forget" }} className="searchA">{t("DICO_WORD_PASSWORD_LOST")}</Link>							
									</Col>
									<Col>	
										{t("DICO_WORD_NOT_MEMBER")} <br/><Link to={{ pathname: "/register" }} className="searchA">{t("DICO_WORD_REGISTER")} </Link>
									</Col>

									<Col>	
										{t("DICO_WORD_LOGIN")} <Link to={{ pathname: "/corporate" }} className="searchA">{t("DICO_WORD_CORPORATE")}</Link>		
									</Col>
								</Row>
							</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
}

export default Login;
