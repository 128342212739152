import React, { useState, useEffect } from 'react'
import { Form,  Alert, Button, Row, Col, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import validator from "validator";

const ModalWallet = ({show,onHide,title}) => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [name, setName] = useState('');
	const [number, setNumber] = useState(1);
	const [Err, setErr] = useState("");
	const [validated, setValidated] = useState(false);
    const [api, setAPI] = useLocalStorage("api",'');

	async function create() {
		const form = new FormData();
		form.append("nb", number);
		form.append("name", name);
		const options = {
			method: 'POST',
			url: api + 'wallet',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/"){
				setErr(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 201 && response.data.success === true)||(response.data.status === 200 && response.data.success === true)){
					window.location ='/corporate/wallet'
				}else{
					setErr(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setErr(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/"){
				setErr(errt.data.msg);
				signOut();
			}else{
				setErr(errt.data.msg);
			}
		});
	}

	const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!validator.isNumeric(number)) {
            setValidated(false);
			setErr("Number is required");
			return false;
		}

        if (validator.isEmpty(number)) {
            setValidated(false);
			setErr("Number is required");
			return false;
		}
        
        create()
    };

    const hide = () => {
        setNumber(1);
		setName("")
        setValidated(false);
        onHide()
    }

	return(
		<>
		    <Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
				<Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
					<Modal.Header closeButton>
						<Modal.Title >
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Err!=='' ? <Alert  variant={'danger'}>{Err}</Alert>:''}
						<Row >
							<Col md={4}>
								<Form.Label>Batch {t("DICO_WORD_NAME")} </Form.Label>
								<Form.Control type="text" required value={name} name="name" onChange={(ev) =>{setName(ev.target.value);}} />
							</Col><Col md={4}>
								<Form.Label>{t("DICO_WORD_NUMBER")} </Form.Label>
								<Form.Control type="number" min={1} required value={number} name="number" onChange={(ev) =>{setNumber(ev.target.value);}} />
							</Col><Col md={4}>
								<Form.Label>&nbsp;</Form.Label>
								<Button type="submit" variant="outline-info">Submit</Button>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={hide}>Close</Button>
					</Modal.Footer>
				</Form>
        	</Modal>
		</>
	)
}
export default ModalWallet;