import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import SearchCmp from './search';
import NodesJs from './nodes';

const Header = () => {

  const { t, i18n } = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [width,setWidth] = useState();
  const [height,setHeight] = useState();
  const [number,setNumber] = useState();

  useEffect(() => {
    setWidth(windowSize.current[0]);
    setHeight(windowSize.current[1]);
    setNumber(windowSize.current[0] < 1000 ? 30: 100);
  },[windowSize])

  // let number = 
  return (
    <header className="masthead headerMain" style={{height: height}}>
      <NodesJs
        id="myNodesJsCanvas"
        width={width}
        height={height}
        particleSize={5}
        lineSize={2}
        particleColor={[150, 150, 150, 0.2]}
        lineColor="150,150,150"
        backgroundColor={[0, 0, 0, 0.1]}
        number={number}
        speed={3}
        nobg={true}
      />
    <div className="container h-100">
      <div className="row h-100 centered">
        <div className="col-12 my-auto text-center text-white">
          <div className='row' style={{marginTop: '10rem'}}>
            <div className='col-lg-6 col-md-12' style={{margin:'auto',textAlign: 'left'}}>
              <h1 >{t("DICO_NIO_FIRST_CERTIFICAT")}</h1>
              <Button className='btn btn-danger p-3' style={{backgroundColor: '#F30438'}} id="searchBtn">{t("DICO_NIO_TRY_FREE")}</Button>
            </div>
            <div className='col-lg-6 col-md-12'>
              <img className="mb-3 img-fluid" src="./img/smartphones.png" alt="" style={{height: '90%',width:'auto'}}/>
            </div>
            <div className='col-lg-12 col-md-12' style={{marginTop: '-5rem'}}>
              <div  style={{textAlign: 'left'}}>
                <SearchCmp dark={true}></SearchCmp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="scroll-down">
      <a className="btn js-scroll-trigger" href="#search">
        <FontAwesomeIcon icon={faAngleDown} />
      </a>
    </div>
  </header>
  )
}
export default Header
