import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { CSVLink } from "react-csv";
import AlertDismissible from '../shared/AlertDismissible';
import validator from "validator";
import { useLocalStorage } from '../../hook/useLocalStorage';
import { ExportToExcel } from '../shared/ExportToExcel';

const ModalTQR = ({show,onHide,title}) => {
    const { t } = useTranslation();
    const { authToken, signOut } = useAuthorization();
    const [number, setNumber] = useState(0);
    const [Err, setErr] = useState("");
    const [validated, setValidated] = useState(false);
    const [csvReport, setCSVReport] = useState();
    const [filename, setFilename] = useState();
    const [dataExcel, setData] = useState();
    const [api, setAPI] = useLocalStorage("api",'');
    const [msgError, setMsgError] = useState('');

    function getInfo(tqrs) {
        const form = {};
        form["ct"]='full';
        const options = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
            },
            data: form
        };     
        
        axios.request(options).then(function (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/info"){
                setMsgError(response.data.msg);
                signOut();
            } else {
                if(response.data.status === false){
                    setMsgError(response.data.msg);
                }else{
                    let yourDate = new Date()
                    let tmp = [];
                    let Excel = [];
                    
                    tmp.push('QR Code;Verification Code');
					tqrs.forEach(element => {
                        Excel.push({'QR Code': response.data.data[4001]+response.data.data.ID_GBM+'/'+element,'Verification Code' : element.slice(-6)});
						tmp.push(response.data.data[4001]+response.data.data.ID_GBM+'/'+element+';'+element.slice(-6));
					});

                    setCSVReport({
                        data: tmp.join('\n'),
                        filename: 'TQR_Batch_' + yourDate.toISOString().split('T')[0],
                    });
                    setFilename('TQR_Batch_' + yourDate.toISOString().split('T')[0]);
                    setData(Excel);
                    setValidated(true);
                }
            }
        }).catch(function (error) {
            var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/info"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
    }

    async function submitForm() {
        const form = new FormData();
        form.append("number", number);
        
        const options = {
            method: 'POST',
            url: api + 'tqr/batch',
            headers: {
                Authorization: "Bearer " + authToken,
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
            },
            data: form
        };
        
        axios.request(options).then(function (response) {
        if(response.data.status === 401 && response.data.path === "errorToken/batch"){
            setMsgError(response.data.msg);
            signOut();
        }else{
            if((response.data.status === 201 && response.data.success === true)||(response.data.status === 200 && response.data.success === true)){
                getInfo(response.data.data);
            }else{
                setMsgError(response.data.msg);
            }    
        }
        }).catch(function (error) {
            var errt;
			if (error.response) {
				errt=error.response;
			} else if (error.request) {
				errt=error.request.response;
			} else {
				setMsgError(error.message);
				return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/batch"){
				setMsgError(errt.data.msg);
				signOut();
			}else{
				setMsgError(errt.data.msg);
			}
        });
    }
  
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!validator.isNumeric(number)) {
            setValidated(false);
			setErr("Number is required");
			return false;
		}

        if (validator.isEmpty(number)) {
            setValidated(false);
			setErr("Number is required");
			return false;
		}
        
        submitForm()
    };

    const hide = () => {
        setNumber(0);
        setValidated(false);
        setCSVReport();
        onHide()
    }

    return(
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter"  >
        <AlertDismissible variant="danger"  text={msgError}/>
            <Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Err !== '' ? <AlertDismissible  variant={'danger'} text={Err} />:''}
                    <Row>
                        <Col>{t("DICO_NTT_TQR_EXP")}</Col>
						<Col>

						{t("DICO_WORD_NUMBER")}
                        <Form.Control   type="number" 
                                        min={1}
                                        max={10000} 
                                        required 
                                        value={ number} 
                                        onChange={(ev) =>{setNumber(ev.target.value)}} />


						</Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {dataExcel !== undefined ? <ExportToExcel apiData={dataExcel} fileName={filename} />:<></>}
                    {csvReport === undefined ? <Button type="submit" variant="outline-info" >{t("DICO_WORD_SAVE")}</Button>:<CSVLink className="btn btn-outline-success" {...csvReport}>{t("DICO_WORD_EXPORT_CSV")}</CSVLink>}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default ModalTQR;