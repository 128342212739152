import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useAuthorization } from '../hook/AuthProvider';
import { useLocalStorage } from '../hook/useLocalStorage';
import axios from 'axios';
import AlertDismissible from '../composent/shared/AlertDismissible';

const BuyPublic = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { authToken, wallet, signOut } = useAuthorization();
  const [walletIn, setwalletIn] = useState();
  const [quantity, setQuantity] = useState(10.00);
  const [price, setPrice] = useState('');
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const maxQuantity = 5000;

  useEffect(() => {
    getPrice();
  }, [quantity]);

  async function getPrice() {
    const form = new FormData();
    form.append("domain", 'NTT');
    form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100210}]));
    form.append("ntityIo", true);
    const options = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL + 'pay/calculate',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: "Bearer " + authToken,
      },
      data: form 
    };
      
    await axios.request(options).then(function (response) {
      if (response.data.status === 200 && response.data.success) {
        setPrice(response.data.data.Total);
      } 
    }).catch(function (error) {
      console.log(error);
    });
  }

  
  const walletdata = ()=>{
    if(!(wallet === null || wallet==='')){
      return <datalist id="data">
        {wallet.split(',').map((item, key) =>{
          return <option key={key} value={item} />
        })}
      </datalist>
    }
    return "";
  }

  const setPriceQuantity = (value) => {
    setQuantity(value );
  }
    
  const handleSubmit = (e) => {
    setError(false);
    setMsgError(t("DICO_WORD_FILL_FORM"));
    e.preventDefault();
    if (!quantity || !walletIn) {
      setError(true);
      setMsgError(t("DICO_WORD_FILL_FORM"));
      return;
    }
    createPayment();
  };
    
  async function createPayment() {
    const form = new FormData();
    form.append("data",JSON.stringify([{"Quantity" : quantity, "ID_VAR" : 100210,"bca":walletIn}]));
    form.append("method",'stripe');
    form.append("domain", 'NTT');
    form.append("ntityIo", true);

    const options = {
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL + 'pay',
      headers: {
        Authorization: "Bearer " + authToken,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };
    
    axios.request(options).then(function (response) {
      if(response.data.success===true){
        navigate('/stripe', {
          state: {
            clientId: response.data.data.client_secret,
          }
        });
      }else{
        setError(true);
        setMsgError(response.data.msg);
      }
    }).catch(function (error) {
      console.error(error);
    });
  }
  return (
    <div className="container"  id="containerBuy" >
    <div className='card d-card'>
      <div id="cardBuy" className='card-body' >
        <div id="contentBuy">
          <Row className="justify-content-md-center mb-4">
            <Col lg={{ order: 'last',size:7}}>
              <Container>
                <Form onSubmit={handleSubmit}>
						      {error && <AlertDismissible variant="danger"  text={msgError} />}
                  <Row >
                          <InputGroup style={{fontSize:'130%'}} size="lg" as={Col} controlId="quantity">
                            <Form.Label className="labelBuy" >Ntity Token</Form.Label>
                            <Form.Control
                              type="number"
                              step="0.01"
                              min={10}
                              id='inputBuyQuant'
                              value={quantity}
                              onChange={(e) => {setPriceQuantity(e.target.value);}}
                              placeholder="100.00"
                            />
                          </InputGroup>
                            <Col id="equalColBuy"  md={1} sm={1} style={{textAlign:'center',fontSize:'130%'}}>
                            <Form.Label id="spacerBuy" ></Form.Label>
                            <div id="equalContent" className="PaddingBuy"><span  className="fontBuy" style={{textAlign:'center'}}>= </span></div>
                            </Col>
                            <Col lg={6} md={5} sm={5} style={{textAlign:'right',fontSize:'130%'}}>
                              <Form.Label className="labelBuy" >{t('DICO_WORD_PRICE')}</Form.Label>
                              <div className="PaddingBuy"><span className="fontBuy" style={{textAlign:'right'}}> {price} CHF</span></div>
                            </Col>
                            
                  </Row>
                  
                
                {walletIn!=='INNER' ? 
                <InputGroup style={{fontSize:'130%'}} size="lg" controlId="walletIn" className='mb-4'>
                  <Form.Label >{t('DICO_WORD_WALLET')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={walletIn}
                    list="data"
                    onChange={(e) => {setwalletIn(e.target.value);}}
                    placeholder="0x154154..."
                    pattern="(0[xX]){0,1}[0-9A-Fa-f]{40}"
                    minLength={42}
                    maxLength={42}
                  />
                    {walletdata()}
                </InputGroup> :''}

                <Form.Group className='mb-4' style={{marginTop:'1.5em'}}>
                {quantity>maxQuantity ? 
                <div>{t("DICO_WORD_OVER_BUY").replace('%s',maxQuantity)} <br/> <a href="https://www.cryptolex.com/contact/"><Button>{t("DICO_NIO_CONTACT_TITLE")}</Button></a></div>
                :
                  <Button size='lg' style={{fontSize:'170%'}} variant="outline-danger" type="submit">
                   { t("DICO_WORD_BUY")}
                  </Button>}
                </Form.Group>
              </Form>
              </Container>
            </Col>
            
            <Col lg="5">
              <Container className='mt-4'>
                <h4>What is Ntity token ? </h4>
                <p style={{textAlign: 'justify'}}>
                  Ntity is a utility fungible token. The token's main objective is not to generate expectations of obtaining economic benefits but is to offer a specific and value-added utility on applications related to the Ntity protocol.
                  <br/><br/>
                  The token is called Ntity and its acronym is NTT.
                </p>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
    
  )
}
export default BuyPublic
