import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Accordion, Button, Card, Col, Collapse, Container, Form, InputGroup, ListGroup, Modal, Row , useAccordionButton } from 'react-bootstrap';



import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Loader from '../../composent/shared/Loader';
import { useAuthorization } from '../../hook/AuthProvider';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import Brand from '../../composent/ntityDoc/brand';
import Gallery from '../../composent/shared/gallery';

const Show = () => {
    const { authToken, signOut } = useAuthorization();
    const { t } = useTranslation();
    const params = useParams();

    const navigate = useNavigate();
    
    const [market, setmarket] = useState();
    
    const [loading, setloading] = useState(true);
    const [lstTempl, setlstTempl] = useState({});
    
    const [msgError, setMsgError] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');

    const [validated, setValidated] = useState(false);

    const [Doc, setDoc] = useState();
    const [newUserInfo, setNewUserInfo] = useState({
      profileImages: []
    });
    
    useEffect(() => {
        getSell()
    }, [authToken]);
    
    
    
    async function getSell() {
        const form = {};
        let options;
        form["TX"] = params.TX;

        options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "marketplace/sell",
        headers: {
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
                signOut();
            }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
                
                if(response.data.success === true){
                    setmarket(response.data.data);
                }else if ( response.data.status=== 404 ) {
                    setmarket(t('DICO_WORD_NOT_SELL'));
                }else{
                    setMsgError(response.data.msg);
                }
                setloading(false);
                getNtityDoc();
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    
    

    async function getNtityDoc() {
        const form = {};
        let options;
        let action;
        action = "ntity";
        form["bca"] = params.TX;

        options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
            Authorization: "Bearer " + authToken,
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
              signOut();
            }else if (typeof response.data.data === 'object' && response.data.data.length !== 0) {
              
              if(Array.isArray(response.data.data)){
                setDoc(response.data.data[0]);
              }else{
                setDoc(response.data.data);
            }
              setloading(false);
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }


    const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });
    return (
        <>
                <AlertDismissible variant="danger"  text={msgError}/>
                <AlertDismissible variant="success"  text={msgSuccess}/>
            <div className='mt-4 row' style={{padding:'2rem'}}>
                {/* {loading === true ? <Container className='text-center mb-4'><Loader /></Container> : <FormEvent doc={Doc}/>} */}
                {loading === true || Doc === undefined ? <Container className='text-center mb-4'><Loader /></Container> : 
                    <>
                        {market === t('DICO_WORD_NOT_SELL') ? <Container className='text-center mb-4'><h2>{t('DICO_WORD_NOT_SELL')}</h2></Container> : 
                            <>
                                <div  id="searchRes">
                                    <div style={{height: 'fit-content'}}><Brand doc={Doc} small={true} /></div>
                                </div>
                                <Col sm={8} style={{padding:'3rem', fontSize:'170%'}}>
                                    <h2><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" /> <span style={{'text-transform':'capitalize'}}>{t('DICO_WORD_BUY')}</span> : {Doc['Name']}</h2>
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label className='nttField'>{t('DICO_WORD_PRICE')}</Form.Label>
                                            <Col>
                                                {market.price} NTT
                                            </Col>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label style={{ fontSize:'80%'}} className='nttField'>{t('DICO_WORD_DESCRIPTION')}</Form.Label>
                                            <Col>
                                                {market.description}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Form.Label style={{ fontSize:'80%'}} className='nttField'>{t('DICO_WORD_TYPE')}</Form.Label>
                                                <Col>
                                                    {market.type}
                                                    Physique
                                                </Col>
                                        </Form.Group>
                                        <Form.Group as={Col}  controlId="validationCustom03">
                                            <Form.Label style={{ fontSize:'80%'}} className='nttField'>{t('DICO_WORD_IMAGE')}</Form.Label>
                                                <Col>
                                                    <Gallery images={JSON.parse(market.img)} />
                                                </Col>
                                        </Form.Group>
                                        
                                        <Form.Group style={{ fontSize:'80%'}} as={Col}  controlId="validationCustom04">
                                            {authToken !== null && <Button  onClick={() => { navigate('/marketplace/' + Doc.Path+'/pay') }} >{t('DICO_WORD_BUY')}</Button>}
                                        </Form.Group>
                                        
                                </Col>
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}

export default Show;
