import React, { useState, useEffect } from 'react'
import { Alert, Button, Container, Form } from 'react-bootstrap';
import axios from "axios";

import { useAuthorization } from '../../hook/AuthProvider';
import { useNavigate, } from "react-router-dom";

import SendFast from './sendfast';
import JsonForm from '../jsonField/jsonForm';
import Loader from '../shared/Loader';
import { faL } from '@fortawesome/free-solid-svg-icons';
import AlertDismissible from '../shared/AlertDismissible';

const  FormEvent = (props) => {
    const [action, setAction] = useState('');
    const [sourceJson, setSourceJson] = useState('');
    const [parent, setParent] = useState('');
    const [parentPath, setParentPath] = useState('');
    const [entity, setEntity] = useState('');
    const [newDoc, setnewDoc] = useState('');
    const [lstTempl, setlstTempl] = useState({});
    const { authToken, signOut } = useAuthorization();
    const [loading, setloading] = useState(false);
    const [errorAl, setErrorAl] = useState('');
    const [cbdDis, setcbdDis] = useState(false);
	const [msgError, setMsgError] = useState('');
    const navigate = useNavigate();
    const lstbcCode = {
        2515: 'certify',
        2516: 'event',
        15: 'lock',
        10: 'unlock',
        17: 'revoke',
        2519: 'ack',
        2517: 'move',
        2518: 'own',
        2514: 'transfer',
      }
    let tmpjson = '';
    let txToID = {};


    useEffect(() => {
        if (props.doc.ID_Doc !== null) {
            setParentPath(props.doc.Path);
            setParent(props.doc.ID_Doc);
            setEntity(props.doc.ID_Entity);
        }
      }, [props.doc]);
      

    useEffect(() => {var  res
        if (props.lstTempl !== null) {
            var  res = Object.assign(lstTempl,props.lstTempl)
            setlstTempl(res);
            if(res['0x97a7fa63839cb9ae91568ac990690c851218dccde926fffa1191d48077bbf770'] === undefined){
                getTemplate('0x97a7fa63839cb9ae91568ac990690c851218dccde926fffa1191d48077bbf770');
            }
        }
        
    }, [props.lstTempl]);

    useEffect(() => {
    if (props.parrent !== null) {
        setParent(props.parrent);
    }
    }, [props.parrent]);

    async function submit() {

        if(sourceJson === ''){
            if([2517,2518,2514].includes(action)){
                setErrorAl("address provided is not a wallet address");
            }else{
                setErrorAl('please fill in the form');
            }
        }
        const form = new FormData();
        
        form.append("idNtity", "");       //wallet ntity.io
        form.append("sourceJson", sourceJson);
        form.append("TX", '0x97a7fa63839cb9ae91568ac990690c851218dccde926fffa1191d48077bbf770');
        form.append("idNtity", entity);
        form.append("parent", parentPath);
        form.append("operation", action);
    
        let options;
        options = {
        method: "POST",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
            Authorization: "Bearer " + authToken,
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            
        if(response.data.status === 401 && response.data.path === "errorToken/"){
            setMsgError(response.data.msg);
            signOut();
        } else {
            if(/[a-fA-F0-9]{8}\-[a-fA-F0-9]{4}\-4[a-fA-F0-9]{3}\-[a-fA-F0-9]{4}\-[a-fA-F0-9]{12}/.test(response.data.output)){
                if(props.return === undefined){
                    // navigate('/ntityDoc/id/' + response.data.output, {
                    //     state: {
                    //     ID_Doc: response.data.output
                    //     }
                    // });
                    getNtityDoc(response.data.data)
                }else{
                    setMsgError(response.data.msg);
                }
            }else{
                setMsgError(response.data.msg);
            }
        }})
        .catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
        });
      }

      async function getNtityDoc(id) {
        let options;
        let action;
    
        let form = {"bca": id};
        options = {
        method: "GET",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
            Authorization: "Bearer " + authToken,
            "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        params: form,
        };  
        await axios
          .request(options)
          .then(function (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/"){
                setMsgError(response.data.msg);
                signOut();
            }else {
                if(response.data.success == false){
                    setMsgError(response.data.msg);
                }else{
                    setnewDoc(response.data.data);
                } 
            }
          })
          .catch(function (error) {
            var errt;
            if (error.response) {
              errt=error.response;
            } else if (error.request) {
              errt=error.request.response;
            } else {
                setMsgError(error.message);
                return null;
            }
            if(errt.data.status === 401 && errt.data.path === "errorToken/"){
              setMsgError(errt.data.msg);
              signOut();
            }else{
                setMsgError(errt.data.msg);
            }
          });
      }
      async function getTemplate(tx){
        setloading(true);
        let form = {"bca": tx};
        const options = {
            method: "GET",
          url: process.env.REACT_APP_SERVER_URL + 'ntity',
          headers: {
              Authorization: "Bearer " + authToken,
              "Content-Type":
              "multipart/form-data; boundary=---011000010111000001101001",
          },
          data: form
        };
        await axios
        .request(options)
        .then(function (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/"){
                setMsgError(response.data.msg);
                signOut();
            }else if (typeof response.data.output === 'object' && response.data.output.length !== 0) {
                var tmp = lstTempl;
                tmp[response.data.output[0].Path] = response.data.output[0];
                setlstTempl(tmp)
                setloading(false);
            }else{
                setMsgError(response.data.msg);
            }
        })
        .catch(function (error) {
            var errt;
            if (error.response) {
              errt=error.response;
            } else if (error.request) {
              errt=error.request.response;
            } else {
              setMsgError(error.message);
              return null;
            }
            if(errt.data.status === 401 && errt.data.path === "errorToken/"){
              setMsgError(errt.data.msg);
              signOut();
            }else{
                setMsgError(errt.data.msg);
            }
        });
    }
    
    function valueCbd(){
        var option = props.rela.history.map((entry,index) => {
            if(entry['Path'] === props.doc['Path']){
              var arrcbd = mapCbd([entry]);
              return arrcbd;
            }
        });
        var display = 'block';
        if(cbdDis === false){
            display = 'none'
        }
        return (
            <Form.Group className="mb-3" style={{display:display}}>
                <Form.Label>Parent</Form.Label>
                <Form.Select name='ID_Parent' value={parent} onChange={e => {setParent(txToID[e.currentTarget.value])
                    setParentPath(e.currentTarget.value);}}>
                    {option}
                </Form.Select>
            </Form.Group>
        )
    }

    function mapCbd(arrData){
        var res;
        res = arrData.map((entry,index) => {
            var child
            if(entry['Child']){
                child = <>{mapCbd(entry['Child'])}</>
            }
            txToID[entry['Path']] = entry['ID_Doc'];
            return(<><option value={entry['Path']}>{entry['Path']}</option>{child}</>);
        });
        return res;
    }


    const selEvent = () => {
        var arrEv=[];
        if(props.rela.status === '10' || props.rela.status === 10){
            arrEv = [
                //{'value':2515, 'name':'Surcharge'},
                {'value':2516, 'name':'Event'},
                {'value':17, 'name':'Closed'},
                {'value':15, 'name':'Lock'},
                {'value':2517, 'name':'Move'},
                {'value':2518, 'name':'Own'},
                {'value':2514, 'name':'Transfert'},
            ]
        }else if(props.rela.status === '15' || props.rela.status === 15){
            arrEv = [
                {'value':10, 'name':'Unlock'},
            ]
        }
        
        return (
            <Form.Group className="mb-3">
                <Form.Label>select type event</Form.Label>
                <div  className="mb-3">
                    {arrEv.map((entrer, index) => (
                        <Form.Check
                        inline
                        label={entrer['name']}
                        name="action"
                        type="radio"
                        id={`inline-action-${index}`}
                        value={entrer['value']}
                        onChange={(entry) => {selEventHandler(entrer['value'])}}
                    />
                    ))}
                </div>
            </Form.Group>
        )
    }

    const selEventHandler = (value) => {
        setAction(value)
        if ([2516,2515].includes(value)) {
            setParent(props.doc.ID_Doc);
            setParentPath(props.doc.Path);
            setcbdDis(true);
        }else{
            setParent(txToID[props.rela.lastTxOrigin]);
            setParentPath(props.rela.lastTxOrigin);
            setcbdDis(false);
        }
    }

    function handlerAction(){
        var form
        switch (action) {
            case 2515:      //surcharge
                form = event()
                break;
            case 2516:      //event
                form = event()
                
                break;
            case 2517:      //Move
                form = status()
                break;
            case 2518:      //Own
                form = status()
                break;
            case 2514:      //Transfert
                break;
            case 10:      //Unlock
            case 15:      //Lock
            case 17:      //closed
                form = status()
                break;
            default:
                break;
        }
        return (
            <>
                {form}
                <Button variant="outline-primary"  onClick={() => submit()}>
                    Submit
                </Button>
            </>
            
        )
    }

    function event(){
        var tmp;
        switch (action) {
            case 2515:
                tmp = props.doc.ID_Template;

                break;
            case 2516:
                tmp = '0x97a7fa63839cb9ae91568ac990690c851218dccde926fffa1191d48077bbf770';
                break;
        
            default:
                break;
        }
        return(
            <>
            <Form.Group className="mb-3">
                <Form.Label>form event</Form.Label>
                <div  className="mb-3">
                    
                {loading=== true ? <Container className='text-center mb-4'><Loader /></Container> : 
                 <JsonForm certificat={lstTempl[tmp]} handleReturn={handleJsonform}/>}
                </div>
            </Form.Group>
            </>
        )
    }
    function handleJsonform(data){
        data.N = lstbcCode[action]
        data.L =1508
        setErrorAl('');
        setSourceJson(JSON.stringify(data))
    }

    const handleInput = (ev) => {
        var value = ev.target.value;
        if([2517,2518,2514].includes(action)){
            if(/0[Xx][0-9A-Fa-f]{40}/.test(value.toUpperCase())===true){
                setErrorAl('');
                setSourceJson(JSON.stringify({'D':{"T":"t","y":action,"c":value.toUpperCase()},'N':lstbcCode[action]}));
            }else{
                setSourceJson('');
            }
        }else{
            setErrorAl('');
            setSourceJson(JSON.stringify({'D':{"T":"t","y":action,"c":value},'N':lstbcCode[action]}));
        }


        ;
    }
    function status(){
        return(
            <Form.Group className="mb-3">
                <Form.Label>comment</Form.Label>
                <Form.Control type="text"  placeholder="Normal text" name="c" onChange={(ev) =>handleInput(ev)}/>
            </Form.Group>
        )
    }
    

    return(
        <Container>
			<AlertDismissible variant="danger"  text={msgError}/>
            {newDoc==='' ? (
            <Form>
                {selEvent()}
                {valueCbd()}
                {errorAl!=='' ? <Alert  variant={'danger'}>{errorAl}</Alert>:''}
                {action!==undefined ? handlerAction():''}
            </Form> ):<SendFast doc={newDoc} bcCode={props.doc.ID_Type} rela={props.rela} parent={parent} parentPath={parentPath}/>}
            
        </Container>
    )
}
export default FormEvent;