import React, { useState, useEffect } from 'react'
import { Form,  Alert, Button, Row, Col, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import validator from "validator";

const ModalAddApproval = ({show,onHide,title,doc,action,forceUpdate}) => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [name, setName] = useState('');
	const [number, setNumber] = useState(1);
	const [Err, setErr] = useState("");
	const [validated, setValidated] = useState(false);
    const [api, setAPI] = useLocalStorage("api",'');

	async function create() {
		const form = new FormData();
		form.append("Action", action);
		form.append("TX", doc.Path);
		const options = {
			method: 'POST',
			url: api + 'operation/addAppoval',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		
		axios.request(options).then(function (response) {
            if(response.data.status === 401 && response.data.path === "errorToken/addAppoval"){
				signOut();
			} else {
                if(response.data.success == false){
					setErr('error');
				}else{
					if("Txn_Hash" in response.data.data){
						forceUpdate(false);
						setErr('');
					}else{
						setErr('error');
					}
					
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
				setErr(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/addAppoval"){
				setErr(errt.data.msg);
			  signOut();
			}else{
				setErr(errt.data.msg);
			}
		});
	}

	const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        create()
    };

    const hide = () => {
        setNumber(1);
		setName("")
        setValidated(false);
        onHide()
    }

	return(
		<>
		    <Modal show={show} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter">
				<Form id="test" validated={validated} onSubmit={(ev) =>{ handleSubmit(ev)}}>
					<Modal.Header closeButton>
						<Modal.Title >
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
							{Err!=='' ? <Alert  variant={'danger'}>{Err}</Alert>:''}
						{t("DICO_WORD_APROVAL_DISCLAMER")}<br/>
                        {doc.Name}<br/>
                        {action}<br/>
					</Modal.Body>
					<Modal.Footer>
                        <Button onClick={hide} variant="outline-danger">{t('DICO_WORD_NO')}</Button>
                        <Button type="submit" variant="outline-primary" >{t('DICO_WORD_YES')}</Button>
					</Modal.Footer>
				</Form>
        	</Modal>
		</>
	)
}
export default ModalAddApproval;