import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/Sidebar.css"; // Import CSS styles for the sidebar
import "../../css/dragNdropUp.css"; // Import CSS styles for the dragNdropUp
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Sidebar({itemM = []}) {

	const currentYear = new Date().getFullYear();
	const [show, setShow] = useState('');

	const linkSend = (item,index) =>{
		if(item.type ==='hrNew'){
			return <div key={index} className="hrNew" />;
		}
		if(item.type ==='linkR'){
			return <li key={index}>
			<Link to={item.link}> 
				<FontAwesomeIcon icon={item.icon} />
				&nbsp;&nbsp;  {item.label}
			</Link>
		</li>;
		}
		if(item.type ==='aboutB'){
			return <li key={index}>
			<a href={item.link} target="_blank"> 
				<FontAwesomeIcon icon={item.icon} />
				&nbsp;&nbsp;  {item.label}
			</a>
		</li>;
		}
	}
	

	return (
		<>
		<div  className={"sidebar  sidebar-dark bg-blue "+show}>
		<div className={"bg-blue sidebarBtn "+show} onClick={()=>{if(show==''){setShow('toggled')}else{setShow('')}}}><FontAwesomeIcon size="lg" icon={faBars} /></div>
			
				<ul className=""  style={{width:'100%', padding : '20px 0 0 0'}}>
					{itemM.map((item, index) => (
						item.label === 'html' ? (
							<div key={index} className="hrNew" />
						) : (
							<>{linkSend(item,index)}</>
						)
					))}
				</ul>
				<div style={{padding: '0 0 0 15px', fontSize:'70%', position:'fixed', bottom:5}}>
						<a style={{color:'#999'}} href="https://ntity.io">Powered by ntity.io</a><br />
						<a style={{color:'#999'}} href="#">Copyright Cryptolex &copy; 2018-{currentYear}</a>
				</div>
		</div>
		
		</>
	);
}

export default Sidebar;
