import React from 'react'

import { useTranslation } from "react-i18next";

const Description = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container" style={{marginTop: '10rem'}}>
      <div className='card d-card'>
        <div className='card-body' style={{padding: '5rem'}}>
          <div style={{marginTop: '5rem'}}>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <h1>{t("DICO_NIO_WHAT_NTITY")}</h1>
                <p>{t("DICO_NIO_NTITY_HEART")}</p>
                <p>{t("DICO_NIO_NTITY_ABSTRACTION")}</p>
                <div style={{marginTop: '5rem'}}>
                  <h1>{t("DICO_NIO_WHY_NTITY")}</h1>
                  <div dangerouslySetInnerHTML={{__html: t("DICO_NIO_MOJO")}} />
                </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <img src='./img/hand_check.png' className="img-fluid" style={{width:'100%',height:'auto'}}/>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}
export default Description
