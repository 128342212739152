
import React from 'react'
import { useAuthorization } from '../hook/AuthProvider';
import { useEffect } from 'react';

const Logout = () => {
  const {signOut} = useAuthorization();

  useEffect(() => {
    signOut();
  },[]);

  return (
    <>
    </>
  )
}


export default Logout;
