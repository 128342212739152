import React, { useState, useEffect } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { Link } from 'react-router-dom';
import AlertDismissible from '../../composent/shared/AlertDismissible';

const ItemCorporate = () => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [resultASku, setResultASku] = useState("");
	const [rowSku, setrowSku] = useState([]);
	const [api, setAPI] = useLocalStorage("api",'');
	const [msgError, setMsgError] = useState('');

	useEffect(() => {
		if(authToken !== null){
			getMyProducts()
		}
	},[authToken]);

	async function getMyProducts() {
		const form = {};
		form["ct"] = 'all';
		const options = {
			method: 'GET',
			url: api + 'user/myCertificat',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/myCertificat"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					var tmprowSku = [...response.data.data];
					tmprowSku.map((entry,index)=>{
						let tmp = JSON.parse(entry.Content)
						tmprowSku[index]['ID'] = entry.ID_Doc; 
						tmprowSku[index]['sn'] = tmp.sn; 
						tmprowSku[index]['v'] = tmp.v; 
						tmprowSku[index]['Status'] = entry.ID_Status == 20 ? t('DICO_WORD_ON_BLOCKCHAIN'):''; 
						tmprowSku[index]['Created'] = new Date(tmprowSku[index]['Date']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
						if(entry.Path != undefined){
							tmprowSku[index]['Edit'] = <>
							<Link className='btn btn-outline-primary' to={{ pathname: "/corporate/item/"+entry.Path }}>{t('DICO_WORD_DETAIL') }</Link>
						</>
						}else{
							tmprowSku[index]['Edit'] = <>
							<Link className='btn btn-outline-primary' to={{ pathname: "/corporate/item/"+entry.ID_Doc }}>{t('DICO_WORD_DETAIL') }</Link>
						</>
						}

				});
				setrowSku(tmprowSku);
				setResultASku(response.data.data);		
			}else{
				setMsgError(response.data.msg);
			}
		}
	}).catch(function (error) {
		var errt;
		if (error.response) {
		  errt=error.response;
		} else if (error.request) {
		  errt=error.request.response;
		} else {
		  setMsgError(error.message);
		  return null;
		}
		if(errt.data.status === 401 && errt.data.path === "errorToken/myCertificat"){
		  setMsgError(errt.data.msg);
		  signOut();
		}else{
		  setMsgError(errt.data.msg);
		}
	});
	}

	const columns = [
		{ accessor: 'ID', label: '' },
		{ accessor: 'v', label: 'ID' },
		{ accessor: 'sn', label: 'SKU' },
		{ accessor: 'storageAddr', label: t('DICO_WORD_WALLET') },
		{ accessor: 'Status', label:  t('DICO_WORD_STATUS') },
		{ accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
		{ accessor: 'Edit', label: t('DICO_WORD_ACTION') },
	];
	
	return (
	< >
		<Container>
			<AlertDismissible variant="danger"  text={msgError}/>
			<div className='containerTitle'>{t("DICO_WORD_ITEMS")}</div>
			<Card style={{ marginTop: 50, marginBottom: 100 }}>
				<Card.Body>
					{resultASku === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowSku} columns={columns}/>}
				</Card.Body>
			</Card>
		</Container>
	</>
	);
}

export default ItemCorporate;