
import {
    connectorsForWallets,
    getDefaultWallets,
    RainbowKitProvider,Chain
  } from '@rainbow-me/rainbowkit';
  import { configureChains, createConfig, WagmiConfig } from 'wagmi';
  import {
    mainnet,
    sepolia,
    polygon,
    optimism,
    arbitrum,
    base,
    zora,
  } from 'wagmi/chains';
  import { alchemyProvider } from 'wagmi/providers/alchemy';
  import { publicProvider } from 'wagmi/providers/public';
  
  import {
    bitskiWallet,
    braveWallet,
    coinbaseWallet,
    dawnWallet,
    frameWallet,
    imTokenWallet,
    injectedWallet,
    mewWallet,
    oneKeyWallet,
    phantomWallet,
    rabbyWallet,
    walletConnectWallet,
  } from '@rainbow-me/rainbowkit/wallets';
import { defineChain } from 'viem';
import { NTT, NTTH } from './nttChain';
  
  
  

  
  const { chains, publicClient } = configureChains(
    [NTT, NTTH,mainnet,sepolia, polygon,],
    [
      publicProvider()
    ]
  );
  
  
  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
      //   phantomWallet({ chains: [mainnet,sepolia, polygon], }),
      braveWallet({ chains:chains }),
      //   coinbaseWallet({ appName: 'ntity',chains:chains }),
      ],
    },
    {
      groupName: 'Others',
      wallets: [
        coinbaseWallet({ appName: 'ntity',chains:chains }),
        oneKeyWallet({ chains:chains }),
        mewWallet({ chains:chains }),
        rabbyWallet({ chains:chains }),
        frameWallet({ chains:chains }),
        dawnWallet({ chains:chains }),
        bitskiWallet({ chains:chains }),
        phantomWallet({ chains: [mainnet,sepolia, polygon], }),
      ],
    },
  ]);
  
  
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,publicClient
  })
  


  export {chains,connectors,wagmiConfig};