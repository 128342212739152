import React, { useState, useEffect } from 'react'
import axios from "axios";
import Haradev from '../sendToBc/haradev';
import Web3Js from '../sendToBc/web3Js';
import { Modal } from 'react-bootstrap';

import {
  Container
} from 'react-bootstrap';
import PayApi from '../sendToBc/payApi';
import { useAuthorization } from '../../hook/AuthProvider';

const SendFast = (props) => {
  const { authUser} = useAuthorization();
  const [hexDatagram, setHexDatagram] = useState();
  const [isloading, setisloading] = useState(false);
  const [bcPay, setBcPay] = useState('ntity');

  const lstApi = {
    2515: 'certify',
    2516: 'event',
    15: 'lock',
    10: 'unlock',
    17: 'revoke',
    2519: 'ack',
    2517: 'move',
    2518: 'own',
    2514: 'transfer',
  }
  const BcCodeTobc = {
    1553:'NTT', //https://blockscout.ntity.io/
    1653:'NTTH',
    1552 : 'ETH'
  }
  useEffect( () => {
    if (props.doc.Content !== null) {
      const form = new FormData();
      form.append("bcid", props.bcCode);
      form.append("lastOperationTX", props.parentPath);
      if ([15,10,2519,2517,2518,2514].includes(Number(props.doc.ID_Format))) {
        form.append("sourcejson", JSON.parse(props.doc.Content).c);
      }else if(Number(props.doc.ID_Format) === 2515){
        form.append("sourcejson", JSON.stringify({ N: props.doc.Name, X: props.doc.Expire, 'D': JSON.parse(props.doc.Content), 'V': 0, 'L': 1508 }));
        form.append("TX", props.doc.ID_Template);
      }else if(Number(props.doc.ID_Format) === 2516){
        form.append("sourcejson", JSON.stringify({ N: props.doc.Name, X: props.doc.Expire, 'D': JSON.parse(props.doc.Content), 'V': 0, 'L': 1508 }));
      }
        // found element
      const options = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'ntityio/'+lstApi[props.doc.ID_Format],
        data: form
      };
      axios.request(options).then(function (response) {
        setHexDatagram(response.data.output);
      }).catch(function (error) {
        console.error(error);
      });
    }
  }, [props.doc]);

  function showPay() {
    var sender
    switch (props.doc.ID_Format) {
      case '2515':
      case '2516':
      case '15':
      case '10':
      case '17':
      case '2519':
        console.log( props.rela.owner)
        if(props.rela.owner !== undefined){
          sender = props.rela.owner
        }
        break;
        case '2517':
        case '2518':
        case '2514':
        if(props.rela.stock !== undefined){
          sender = props.rela.stock
        }
        break;
    
      default:
        break;
    }
    switch (bcPay) {
      case 'ntity': return <PayApi doc={props.doc} hexDatagram={hexDatagram} template={props.template}  blockchain={BcCodeTobc[props.bcCode]} sender={sender}/>;
      case 'web3': return <Web3Js doc={props.doc} hexDatagram={hexDatagram} template={props.template}  blockchain={props.bcCode} sender={sender}/>;
      default:
        break;
    }
  }

  return (
    <Container id="registerForm">
              {showPay()}
    </Container>
  )
}

export default SendFast