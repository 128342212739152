

import React, {  useEffect, useState } from 'react'
import { BrowserRouter as Link, Routes, Route, useLocation }from 'react-router-dom';


import { faFlag, faUserGroup,faCertificate, faBuildingColumns,faSliders,faBarcode, faWallet, faChartSimple,faUser,faRing, faLink} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../hook/useLocalStorage";

import Footer from '../../composent/index/footer';
import NotFound from './NotFound';
import Product from './product';
import BrandId from './brandId';
import Member from './member';
import Location from './location';
import Wallet from './sku/wallet';
import TQR from './tqr';
import Sidebar from '../../composent/shared/Sidebar';
import LoginCorporate from './login';
import { useAuthorization } from '../../hook/AuthProvider';
import DetailsWallet from './sku/details';
import InfoServer from './infoServer';
import ItemCorporate from './itemCorporate';
import ItemDetail from './itemDetails';
import Dashboard from './dashboard';
import Logs from './logs';
import ForgetCorporate from './forget';
import ProfilCorp from './profilCorp';


const Corporate = () => {
  const {authToken} = useAuthorization();
	const { t,i18n } = useTranslation();
	const [member, setMember] = useLocalStorage("member",'');
	const [menu, setMenu] = useState([]);
	const [wait, setwait] = useState(0);
	const location = useLocation();
  useEffect(() => {
    if(wait <=1 ){
      setTimeout(function () {
        setwait(wait+1);
    }, 2000);
  }
  if(authToken === null && wait===1 && location.pathname === 'corporate/login'){
    window.location ='/';
  }
	},[wait]);

  useEffect(() => {
		const items = []; 
		if (member !== null && member !== "undefined" && member !== false) {
			items.push({ type:'linkR', label: t("DICO_WORD_DASHBOARD"),		link: "/corporate/dashboard" , icon: faChartSimple});
			if(member.Role === 'Member'){
				if(member.hasOwnProperty('Right') == true){
					if(member.Right.hasOwnProperty(1060) == true){
						if(member.Right[1060].S === 1 ){
							items.push({ type:'linkR', label: t("DICO_WORD_MEMBER"),			link: "/corporate/member" , icon: faUser });
							items.push({ type:'linkR', label: t("DICO_WORD_GROUP"),			link: "/corporate/group" , icon: faUserGroup});
							items.push({ type:'hrNew', label: 'html' , 						link:  '' });
						}
					}
					if(member.Right.hasOwnProperty(1066) == true){
						if(member.Right[1066].S === 1 ){
							items.push({ type:'linkR', label: t("DICO_WORD_PRODUCTS"),		link: "/corporate/product" , icon: faCertificate});
						}
					}
					if(member.Right.hasOwnProperty(1067) == true){
						if(member.Right[1067].S === 1 ){
							items.push({ type:'linkR', label: t("DICO_WORD_LOGS"),			link: "/corporate/logs" , icon: faFlag});

						}
					}
				}
			}else if(member.Role === 'Corporate'){
				
				if(member.hasOwnProperty('Right') == true){
					if(member.Right.hasOwnProperty(1066) == true){
						if(member.Right[1066].S === 1 ){
							items.push({ type:'linkR', label: t("DICO_WORD_PRODUCTS"),		link: "/corporate/product" , icon: faCertificate});
						}
					}
				}
				items.push({ type:'linkR', label: t("DICO_WORD_TQR"), 		    link: "/corporate/tqr" , icon: faBarcode});
				items.push({ type:'linkR', label: t("DICO_WORD_ITEMS")+' QR ('+t("DICO_WORD_WALLET")+')',			link: "/corporate/wallet" , icon: faWallet});  
				items.push({ type:'linkR', label: t("DICO_WORD_ITEMS"), 		    link: "/corporate/item" , icon: faRing});
				items.push({ type:'hrNew', label: 'html' , 						link:  ''  });
				if(member.hasOwnProperty('RightCorporate') == true){
					member.RightCorporate.map((right,index)=>{
						if(right.ID_Block === 1060 ){
							items.push({ type:'linkR', label: t("DICO_WORD_MEMBER"),			link: "/corporate/member" , icon: faUser });
							items.push({ type:'linkR', label: t("DICO_WORD_GROUP"),			link: "/corporate/group" , icon: faUserGroup});
							items.push({ type:'hrNew', label: 'html' , 						link:  '' });
						}
					})
				}
				items.push({ type:'linkR', label: t("DICO_WORD_BRAND_IDENTITY"), link: "/corporate/brandid" , icon: faBuildingColumns});
				items.push({ type:'linkR', label: t("DICO_WORD_SETTINGS"),		link: "/corporate/infoServer" , icon: faSliders});
				
				if(member.hasOwnProperty('Right') == true){
					if(member.Right.hasOwnProperty(1067) == true){
						if(member.Right[1067].S === 1 ){
							items.push({ type:'linkR', label: t("DICO_WORD_LOGS"),			link: "/corporate/logs" , icon: faFlag});
						}
					}
				}
				items.push({ type:'hrNew', label: 'html' , 						link:  '' });
			}
			items.push({ type:'aboutB', label: "Api" , 						link:  'https://api.ntity.io/swagger', icon:faLink} );
			setMenu(items);
		}
	},[member,i18n.language]);
  
  return (
	<>
              {authToken == null?(
                <div className="container">
                <Routes>
                  <Route path="login" element={ <LoginCorporate/> } />
                  <Route path="" element={ <LoginCorporate/> } />
				  <Route path="forget" element={ <ForgetCorporate /> } />
                </Routes>
                </div>
              ):(
                <div className="d-flex">
                  <Sidebar itemM={menu} />
                  <Routes>
                    <Route path="item" element={ <ItemCorporate /> } />
                    <Route path="item/:path" element={ <ItemDetail /> } />
                    <Route path="product" element={ <Product/> } />
                    <Route path="brandid" element={ <BrandId/> } />
                    <Route path="member" element={ <Member/> } />
                    <Route path="infoServer" element={ <InfoServer/> } />
                    <Route path="group" element={ <Location/> } />
                    <Route path="tqr" element={ <TQR /> } />
                    <Route path="wallet" element={ <Wallet /> } />
                    <Route path="detail/:path" element={ <DetailsWallet /> } />
                    <Route path="dashboard" element={<Dashboard />} /> 
                    <Route path="logs" element={<Logs />} /> 
                    <Route path="" element={<Dashboard />} /> 
                    <Route path="/" element={<Dashboard />} /> 
                    <Route path="*" element={<NotFound />} /> 
                  </Routes>
                </div>

              )}
        </>
  );
}

export default Corporate;