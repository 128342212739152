import React, { useEffect, useState } from 'react'

import {
  Container,
  Button,
  Form,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import validator from "validator";
import axios from 'axios';
import Footer from '../composent/index/footer';
import { useTranslation } from 'react-i18next';
import AlertDismissible from '../composent/shared/AlertDismissible';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useAuthorization } from '../hook/AuthProvider';
import { useLocalStorage } from '../hook/useLocalStorage';


const Code = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
	const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [success, setSuccess] = useState(false);
  const [api] = useLocalStorage("api",'');

  const [codes, setCodes] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (event, index) => {
    const { value } = event.target;
    const updatedCodes = [...codes];
    updatedCodes[index] = value;
    setCodes(updatedCodes);

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');
    const pastedCodes = pastedText.split('').slice(0, 6);
    const updatedCodes = [...codes];
    pastedCodes.forEach((code, index) => {
      if (inputRefs.current[index]) {
        updatedCodes[index] = code;
        inputRefs.current[index].value = code;
      }
    });
    setCodes(updatedCodes);
    
    // Changer le focus pour être sur le dernier élément
    if (inputRefs.current[inputRefs.current.length - 1]) {
      inputRefs.current[inputRefs.current.length - 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && codes[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const renderInputs = () => {
    return codes.map((code, index) => (
      <input
        key={index}
        type="text"
        defaultValue={code}
        onChange={(event) => handleChange(event, index)}
        onKeyDown={(event) => handleKeyDown(event, index)}
        ref={(input) => inputRefs.current[index] = input}
        maxLength={1}
        className="form-control"
        style={{ width: '30px', marginRight: '5px' }}
      />
    ));
  };

  const resend = () => {
    const form = new FormData();
    form.append("username", state.user);

    let URL_API = process.env.REACT_APP_SERVER_URL
    if(api !== ''){
      URL_API = api;
    }

    const options = {
      method: 'POST',
      url: URL_API + 'auth/recover',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };

    axios.request(options).then(function (response) {
        if(response.data.success){
          setSuccess(true);
          setMsgError(response.data.data);
        }
      
    }).catch(function (error) {
      console.error(error);
    });
  }
  
  const submitFormData = (e) => {
    e.preventDefault();
    setError(false);
    const fullCode = codes.join('');

    if (validator.isEmpty(fullCode)) {
      setError(true);
      setMsgError(t("DICO_ERR_EMAIL_EMPTY"));
      return false;
    } 

    const form = new FormData();
    form.append('code',fullCode);
    form.append("username", state.user);

    let URL_API = process.env.REACT_APP_SERVER_URL
    if(api !== ''){
      URL_API = api;
    }

    const options = {
      method: 'POST',
      url: URL_API + 'auth/confirm',
      headers: {
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if(response.data.success){
        navigate('/recover',{ state: {user:response.data.token}}) 
      }
    }).catch(function (error) {
      setMsgError(error.response.data.msg);
      setError(true);
    });
  };


  return (
    <>
        <Container>
					<Card style={{ marginTop: 50, marginBottom: 200, boxShadow:'4px 5px 14px 4px rgba(0,0,0,.1)'  }} className="card-login">
						<Card.Body>
						{error && <AlertDismissible variant="danger"  text={msgError} />}
            {success && <AlertDismissible variant="success"  text={msgError} />}
						<div style={{textAlign:'center', fontSize: '1.5rem', fontWeight : 'bold', paddingBottom: 30, textTransform: 'uppercase'}}>
						<img src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png" className="App-logo" alt="logo" />
						{t("DICO_WORD_PASSWORD_RESTORE")}
						</div>
							<Form onSubmit={submitFormData} onPaste={handlePaste}>
                <div className='text-center'>
                <label>{t("DICO_WORD_TOTP_ENTER")} :</label>
                </div>

              <div className="form-group d-flex justify-content-center align-items-center mb-4">
                <div className="d-flex">
                  {renderInputs()}
                </div>
              </div>
								<Row>
									<Col>
										<Button variant="primary" type="submit" style={{width:'100%'}}>{t("DICO_WORD_VERIFY")}</Button>
									</Col>
								</Row>
                <Row className='mt-2'>
									<Col>
										<Button variant="primary" onClick={() => resend()} style={{width:'100%'}}>{t("DICO_WORD_RESEND")}</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Container>
    </>
  )
}

export default Code;
