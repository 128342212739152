

import React, {  useEffect, useState } from 'react'
import { BrowserRouter as Link, Routes, Route, useLocation }from 'react-router-dom';


import { faFlag, faUserGroup,faCertificate, faBuildingColumns,faSliders,faBarcode, faWallet, faChartSimple,faUser,faRing, faLink} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../hook/useLocalStorage";

import Sell from './sell';
import NotFound from '../NotFound';
import Login from '../login';
import Forget from '../forget';
import { useAuthorization } from '../../hook/AuthProvider';
import Show from './show';
import Pay from './pay';
import Status from './status';


const Marketplace = () => {
  const {authToken} = useAuthorization();
	const { t,i18n } = useTranslation();
	const [member, setMember] = useLocalStorage("member",'');
	const [menu, setMenu] = useState([]);
	const [wait, setwait] = useState(0);
	const location = useLocation();
  useEffect(() => {
    if(wait <=1 ){
      setTimeout(function () {
        setwait(wait+1);
    }, 2000);
  }
  if(authToken === null && wait===1 && location.pathname === 'corporate/login'){
    window.location ='/';
  }
	},[wait]);


  return (
    <>
      <div className="d-flex">
              {authToken == null?(
                <div className="container">
                <Routes>
                  <Route path="login" element={ <Login/> } />
                  <Route path="" element={ <Login/> } />
                  <Route path="/:TX" element={ <Show/> } />
				          <Route path="forget" element={ <Forget /> } />
                </Routes>
                </div>
              ):(
                <div className="container">
                  <Routes>
                    <Route path="/:TX/sell" element={ <Sell/> } />
                    <Route path="/:TX/pay" element={ <Pay/> } />
                    <Route path="/:TX/status" element={ <Status/> } />
                    <Route path="/:TX" element={ <Show/> } />
                    {/* <Route path="/" element={<Dashboard />} />  */}
                    <Route path="*" element={<NotFound />} /> 
                  </Routes>
                </div>

              )}
              </div>
        </>
  );
}

export default Marketplace;