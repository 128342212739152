import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

function AlertDismissible(props) {
  const [show, setShow] = useState(false);
  const [lastTxt, setlastTxt] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  if(lastTxt !== props.text){
    setlastTxt(props.text);
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => clearTimeout(timer);
  }

  if (show) {
    return (
		<div style={{ right: 20, top: 50 , position: 'fixed', zIndex: 100000}}>
			<Alert variant={props.variant} onClick={() => setShow(false)} dismissible>
				{props.text}
			</Alert>
		</div>
    );
  }
  return null;
}

export default AlertDismissible;
