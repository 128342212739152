import React, { useState, useEffect } from 'react'
import { Table, Card, Container, Form, Row ,Col} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import { useLocalStorage } from '../../hook/useLocalStorage';
import NotificationManager from '../../composent/corporate/notificationManager';
import AlertDismissible from '../../composent/shared/AlertDismissible';


const InfoServer = () => {
    const { t } = useTranslation();
    const { authToken, signOut } = useAuthorization();
    const [resultAUsername, setResultAUsername] = useState("");
    const [api] = useLocalStorage("api",'');
    const [msgError, setMsgError] = useState('');
    const [gbm] = useLocalStorage("gbm",'');

    useEffect(() => {
        if(authToken !== null){
          getInfo()
        }
      },[authToken]);


      function getInfo() {
        const form = {};
        form["ct"] = 'full';
        form["Username"] = gbm;
        const options = {
          method: 'GET',
          url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
          headers: {
            Authorization: "Bearer " + authToken,
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          },
          params: form
        };
    
        axios.request(options).then(function (response) {
          if(response.data.status === 401 && response.data.path === "errorToken/info"){
            signOut();
          } else {
            if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
              setResultAUsername(response.data.data);
            }else{
              setMsgError(response.data.msg);
            }
          }
        }).catch(function (error) {
          var errt;
          if (error.response) {
            errt=error.response;
          } else if (error.request) {
            errt=error.request.response;
          } else {
            setMsgError(error.message);
            return null;
          }
          if(errt.data.status === 401 && errt.data.path === "errorToken/info"){
            setMsgError(errt.data.msg);
            signOut();
          }else{
            setMsgError(errt.data.msg);
          }
        });
      }

    
    const table = (title, array) =>{
        var inputRight = []
        if(array === [] || array === undefined){
            inputRight=   <tr>
                    <td  className='text-center'>
                        {t("DICO_WORD_NODATA")}
                    </td>
                </tr>;
        }else{
            inputRight= array.map((entry)=>{
                var inputS = 
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        defaultChecked={Boolean(entry.S)}
                        disabled
                        />;
                
                return (<tr>
                    <td  className='text-center'>
                        {entry.Name}
                    </td>
                    <td  className='text-center'>
                        {inputS}
                    </td>
                </tr>);
            });
        }
        return( 
            
            <Table striped>
                <thead>
                    <tr>
                        <th className='text-center'> </th>
                        <th className='text-center'>{t("DICO_WORD_ACTIVATE")}</th>
                    </tr>
                </thead>
                <tbody>
                    {inputRight}
                    
                </tbody>
            </Table>
        );
    }

  return (
    <>
            <Container>
			  <AlertDismissible variant="danger"  text={msgError}/>
            <div className='containerTitle'>{t("DICO_WORD_SETTINGS")}</div>
                <Card style={{ marginTop: 50, marginBottom: 100 }}>
                    <Card.Body>
                            {resultAUsername === '' ? 
                                <Container className='text-center'><Loader /></Container> 
                                :
                                <>
                                <Row>
                                    <Col className='groupedCol'>
                                        <div className='groupedTitle' >{t("DICO_WORD_COMPANY")} {t("DICO_WORD_DESCRIPTION")} </div>
                                        <Row><Form.Label>{"ID_GBM"} :</Form.Label><div>{resultAUsername['ID_GBM']} :</div></Row>
                                        <Row><Form.Label>{t("DICO_WORD_PAAS_DOMAIN")} :</Form.Label><div>{resultAUsername[4000]} </div></Row>
                                        <Row><Form.Label>{t("DICO_WORD_TQR_SUBDOMAIN")} :</Form.Label><div>{resultAUsername[4001]} </div></Row>
                                        </Col>	
                                        <Col className='groupedCol'>
                                        <div className='groupedTitle' >{t("DICO_WORD_PAAS_DOMAIN")} </div>
                                        {table(t("DICO_WORD_PAAS_DOMAIN"),resultAUsername['eventCustom'])}
                                        </Col>	   	
                                    
                                </Row>
                                <NotificationManager/>
                                </>
                            }
                    </Card.Body>
                </Card>
            </Container>
           

        </>
  );
}

export default InfoServer;