import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocalStorage } from '../../hook/useLocalStorage';
import ModalLocation from '../../composent/corporate/addlocation';
import AlertDismissible from '../../composent/shared/AlertDismissible';

const Location = () => {
	const { t } = useTranslation();
	const { authToken, signOut } = useAuthorization();
	const [resultAUsername, setResultAUsername] = useState("");
	const [rowUsername, setrowUsername] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [modalConfirmShow, setModalConfirmShow] = useState(false);

	const [msgError, setMsgError] = useState('');
	const [titleM, settitleM] = useState('');
	const [ct, setCT] = useState("add");
	const [entry, setEntry] = useState();
	const [entryName, setEntryName] = useState();
	const [api, setAPI] = useLocalStorage("api",'');
	
	useEffect(() => {
		if(authToken !== null){
			getAlllocation()
		}
	},[authToken]);
	
	useEffect(() => {
		if(!modalShow){
			getAlllocation();
		}
	},[modalShow]);

	async function getAlllocation() {
		const options = {
			method: 'GET',
			url: api + 'corporate/group', 
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
		};
		
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/group"){
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined &&  Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					var tmprowUsername = [...response.data.data];
					tmprowUsername.map((entry,index)=>{
						tmprowUsername[index]['ID'] = entry.ID_Entity; 
						tmprowUsername[index]['Created'] = new Date(tmprowUsername[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
						tmprowUsername[index]['Edit'] = <>
							<Button variant="outline-primary" onClick={() => {setModalShow(true); settitleM(t("DICO_WORD_EDIT")+' '+t("DICO_WORD_GROUP")+' - '+entry['Name']);setCT('edit');setEntry(entry)}}><FontAwesomeIcon icon={faEdit} /></Button>
							<Button variant="outline-danger" onClick={() => {setModalConfirmShow(true);setEntryName(entry['Name']);setEntry(entry['ID_Entity'])}}><FontAwesomeIcon icon={faTrash} /></Button>
						</>
					});
						
					setrowUsername(tmprowUsername);
					setResultAUsername(response.data.data);
				}else{
					setMsgError(response.data.msg);
					setResultAUsername(false);

				}

						
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/group"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}
	
	async function remove() {
		const form = new FormData();
		form.append("id", entry);
		const options = {
			method: 'DELETE',
			url: api + 'corporate/group',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
	
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/group"){
				setMsgError(response.data.msg);
				signOut();
			} else {
				if(response.data.data === true){
					setModalConfirmShow(false);
					getAlllocation()
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/group"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	const columns = [
		{ accessor: 'ID', label: '' },
		{ accessor: 'Name', label: 'Name' },
		{ accessor: 'Edit', label: 'Action' },
	]

	return (
		<>
			<Container>
			<AlertDismissible variant="danger"  text={msgError}/>
				<div className='containerTitle'>{t("DICO_WORD_GROUP")}</div>
				<Card style={{ marginTop: 50, marginBottom: 100 }}>
					<Card.Body>
						<Button variant="outline-primary" onClick={() => {setModalShow(true); settitleM(t("DICO_WORD_ADD")+' '+t("DICO_WORD_GROUP"));setCT("add");setEntry()}}>
							{t("DICO_WORD_ADD")}
						</Button>
						{resultAUsername === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowUsername} columns={columns}/>}
					</Card.Body>
				</Card>
			</Container>
			<Modal show={modalConfirmShow} onHide={() => setModalConfirmShow(false)} size="sm" aria-labelledby="contained-modal-title-vcenter">
				<Modal.Header closeButton>
					<Modal.Title >
						{t('DICO_WORD_DELETE_CONFIRM')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='text-center'>
					<div className='text-center'>{entryName}</div>
					</Modal.Body>
				<Modal.Footer className='text-center'>
					<Row style={{width: '100%'}}>
					<Button variant="outline-primary" style={{width: '50%'}} onClick={() => setModalConfirmShow(false)}>
						{t('DICO_WORD_NO')}
					</Button>
					<Button variant="outline-danger" style={{width: '50%'}}  onClick={() => remove()}>
						{t('DICO_WORD_YES')}
					</Button>
					</Row>
        		</Modal.Footer>
			</Modal>
			<ModalLocation
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={titleM}
				ct={ct}
				UsernameM={entry}
			/>
		</>
	);
}

export default Location;