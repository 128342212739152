import React from "react";

const Gallery = ({ images }) => {
    return (
        <div className="gallery">
            {images.map((image,id) => (
                <img key={id} src={image} alt={`Img ${id}`} />
            ))}
        </div>
    );
};
export default Gallery;