import React, { useEffect, useState } from 'react'
import {useSysVar} from "../hook/SysVar";
import axios from "axios";
import { useLocation, useNavigate, } from "react-router-dom";
import Footer from "../composent/index/footer";
import Ipfs from "../composent/jsonField/ipfs";
import { Container, Row, Badge, Col } from "react-bootstrap";
import Form from "@rjsf/core";
import Content from '../composent/ntityDoc/content';
import { useAuthorization } from '../hook/AuthProvider';


const Create = () => {
  const { sysVarGroup } = useSysVar();
  const [newState, setNewsState] = useState({});
  const [name, setName] = useState();
  const params = useLocation();
  const [certificatJson, setCertificatJson] = useState();
  const [shemaJs, setshemaJs] = useState({});
  const [shemaUIJs, setshemaUIJs] = useState({});
  const navigate = useNavigate();
  const { authToken, signOut } = useAuthorization();
  const {getSysVar} = useSysVar();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  

  let stateName = '';

  async function submit() {
    const form = new FormData();

    form.append("idNtity", "");       //wallet ntity.io
    form.append("sourceJson", JSON.stringify(newState ));
    form.append("TX", params.state.TX);

    let options;

    if(authToken !== undefined && authToken !== null){
      options = {
        method: "POST",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data: form,
      };  
    } else {
      form.append("ntityIo", true);
      options = {
        method: "POST",
        url: process.env.REACT_APP_SERVER_URL + "ntity",
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data: form,
      };
    }

    await axios
      .request(options)
      .then(function (response) {
        if(response.data.status === 401 && response.data.message === "Bad Token Key or Key missing, please register"){
          signOut();
        }else if(/[a-fA-F0-9]{8}\-[a-fA-F0-9]{4}\-4[a-fA-F0-9]{3}\-[a-fA-F0-9]{4}\-[a-fA-F0-9]{12}/.test(response.data.data)){
          navigate('/ntityDoc/id/' + response.data.data, {
            state: {
              ID_Doc: response.data.output
            }
          });
        }else{
          console.error(response.data.msg);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }



  let itemList;
  let shema;
  let shemaUi;

  const types = {
    n: "text",
    f: "text",
    sn: "text",
    kn: "text",
    v: "text",
    vn: "float",
    d: "text",
    y: "number",
    s: "number",
    c: "text",
    cn: "text",
    p: "text",
    ad1: "text",
    ad2: "text",
    adn: "text",
    adz: "text",
    ady: "text",
    ads: "text",
    adc: "number",
    tq: "float", // Transaction Quantity
    tu: "text", // Transaction Unit
    tp: "float", // Transaction price
    td: "float", // Transaction discount
    tx: "float", // Transaction tax
    tt: "float", // Transaction total
    tc: "number", // Transaction currency
    cb: "text",
    ct: "date",
    eo: "date",
    ec: "date",
    ft: "number",
    po: "number",
    st: "number",
    e: "Table.Entity",
    a: "Table.Attribute",
    r: "Table.Relation",
    t: "Table.Transaction",
    h: "Table.Document",
  };

  useEffect(() => {
  if (params.state.certificat !== "") {
    var eCert = {};
    if (certificatJson === undefined) {
      eCert.D = JSON.parse(params.state.certificat);
      eCert.N = 'Name of certificat';
      eCert.E = 'Expire';
      setCertificatJson(eCert);
      shema = createShema(eCert)
      shemaUi = createShemaUi(eCert,NaN,newState);
    } else {
      
      shema = createShema(certificatJson)
      shemaUi = createShemaUi(certificatJson,NaN,newState);
    }
  }
}, []);
  // Define a custom component for handling the root position object         //ne marche pas et un horreur
  function DataList (props){

    var searchSysVar = []; 
    var search = '';
    

    var optionstart = <option>--- no value ---</option>; 
    if(props.value !== undefined){
        var sysVar = JSON.parse(localStorage.getItem('sysVar'));
        search = sysVar[props.value].Name;
      optionstart = <option value={props.value}>{sysVar[props.value].Name}</option>;
    }
  
    
        async function ApiSysVarSearch(bca) {
            let forms= {"bca": bca}; 
            const options = {
                method: "GET",
                url: process.env.REACT_APP_SERVER_URL + "misc/sysVarSearch",
                headers: {
                    "Content-Type":
                    "multipart/form-data; boundary=---011000010111000001101001",
                },
                params: forms,
            };
        
            await axios
                .request(options)
                .then(function (response) {
                if(response.data.status !== 401 && (typeof response.data.data === 'array' || typeof response.data.data === 'object') ){
                    let sysVar
                    try {
                        sysVar  =  localStorage.getItem('sysVar'); 
                    } catch (e) {
                        console.error(e);
                    }
                    var tmp = response.data.data
                    if(typeof sysVar === 'string'){
                        sysVar = JSON.parse(sysVar);
                    }else{
                        sysVar = {};
                    }
                    if(tmp.Name === undefined ){ 
                        tmp.map((entry) => {
                            if (!(entry.ID_Var in sysVar)){
                                sysVar[entry.ID_Var] = entry;
                            }
                        })
                        tmp = sysVar;
                    
                    }else{
                        if (!(tmp.ID_Var in sysVar)){
                            sysVar[tmp.ID_Var] = tmp;
                        }
                    }
                    localStorage.setItem('lastSVSearch', bca  );
                    localStorage.setItem('sysVar', JSON.stringify(sysVar)  );
                    return tmp;
                }else{
                    console.error(response.data.msg);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
        }

        function getSearchSysVar(bca) {
            let res = [];
            try {
                //localStorage.removeItem('sysVar');
                let lastSysVar =  localStorage.getItem('lastSVSearch');
                if(bca.includes(lastSysVar)  === false){
                    return ApiSysVarSearch(bca);
                }
                let sysVar =  localStorage.getItem('sysVar');
                if(typeof sysVar === 'string'){
                    sysVar = JSON.parse(sysVar);
                }else{
                    sysVar = {};
                }
                if (sysVar === {}) {
                    res = ApiSysVarSearch(bca)
                } else {
                    Object.entries(sysVar).map((entry) => {
                        if(entry[1].Name.includes(bca)  !== false){
                            res.push(entry[1]);
                        }
                    });
                    if(res.length === 0){
                        res = ApiSysVarSearch(bca)
                    }
                }
            } catch (e) {
                console.error(e);
            }
            return res
        }
    
    
        
    
        function getSv(bca){
            searchSysVar = getSearchSysVar(bca)
            var option;
            if(searchSysVar.length === 0){
              option = '<option>--- no value ---</option>'
            }else{
              Object.entries(searchSysVar).map((entry) => {
                  option = option+'<option  value="'+entry[1]['ID_Var']+'" onClick="document.getElementById(\"'+props.id+'\").value = searchSysVar[0].ID_Var;" >'+entry[1]['Name']+'</option>'
              });
              document.getElementById(props.id).value = searchSysVar[0].ID_Var;
            }
            
            var datalist = document.getElementById(props.id);
            datalist.innerHTML = option;
          }

          function validField(){
            if(document.getElementById(props.id) !== null){
              document.getElementById(props.id).click()
            }
          }
    return (
      <div className='card' style={{backgroundColor: 'transparent' ,padding: '20px', margin:'5px' }}>
        <Row>
        <label>search value</label>
        <input list='browsers'  
        id={props.id+'search'}
        className="form-control"
        value={props.value}
        onChange={(event) => {getSv(event.target.value)}}
        onBlur={ validField }
        />
         </Row>
        <Row>
        <label>please select value</label>
        <select list='browsers'
          id={props.id}
          type="button"
          value={props.value}
          required={props.required}
          onChange={(event) => {props.onChange(event.target.value)}}
        >
          {optionstart}
        {/* //   <datalist id="browsers">
        //     {option}
        //   </datalist> */}
        </select>
         </Row>
      </div>
    );
  }

  const widgets  = {listdata: DataList};

  const fields = {'ipfs': Ipfs};

  function createShemaUi(certificat, child = NaN, data={}) {
    var res = {
      "ui:submitButtonOptions": {
        "props": {
          "class": "btn btn-outline-secondary",
          "style": {'float': 'right',"color": '#6c757d',
          'border-color': '#6c757d', 'margin': '15px',
          'background-color': 'transparent'}
        },
        "submitText": "Submit"
      }
    }
    
    if(certificat.y !== undefined){
      if(certificat.y[0] === 1548){
        res["ui:field"] = 'ipfs';
      }
    }
    Object.entries(certificat).map((entry) => {
      const [key, value] = entry;
      var matches = key.match(/[a-zA-Z]+/);
      let tmp = matches[0];
      res[tmp]= {};
      if( tmp === "C"){
        res[key]= {};
        res[key].items = createShemaUi(value, child+'_'+key);
        if(Object.keys(data).length !== 0){
          if (key.includes("#")) {
            var matches = key.match(/C[\d+][\S]*#(\d*)-?(\d+)?/);
            var limit = matches[1];
          } else {
            limit = 1;
          }
          if(data[key].length <= limit){
            res[key] = {
              "ui:options":  {
                'removable': false,
              }
            }
          }else{
            res[key] = {
              "ui:options":  {
                'removable': true,
              }
            }
          }
          res[key].items = createShemaUi(value, child+'_'+key, data[key]);
        }
      } else if (tmp === "T" ) {
        res[tmp] = {"ui:widget" : 'hidden'};
      } else if (tmp === "D" ) {
        res[tmp] = createShemaUi(value, child+'_'+key, data[key]);
      }else if (tmp === "n" ) {
        //document.getElementById('element_id').onchange = function() {
        if(child === 'NaN_D' && document.getElementById('root_N') || document.getElementById('root_D_n')){
          document.getElementById('root_D_n').onchange = function() {
            document.getElementById('root_N').value = params.state.Name+' : '+document.getElementById('root_D_n').value;
            var tmp = newState
            tmp.N = params.state.Name+' : '+document.getElementById('root_D_n').value;
            setNewsState(tmp);
            forceUpdate()       //force re-render to take effect
          }
        }
          
      }else if (tmp === "y" || tmp === "s") {
        if(typeof certificat[key] === 'string'){
          res[tmp] = {"ui:widget": "listdata"};
        }else{
         if(certificat[key].length ===1){
          if (certificat[key][0] === 1548){
          }else{
            res[tmp] = {"ui:widget" : 'hidden'};
          }
        } 
       }
      }
    });

    if(isNaN(child) ){
      setshemaUIJs(res);
    }
    return res;
  }


  async function createShema(certificat, child = NaN) {
    var res = {
      "type": "object",
      "properties": {}
    };
    var req = []; 
    Promise.all(
    Object.entries(certificat).map(async (entry) => {
      const [key, value] = entry;
      let cached = false;
      let compress = false;
      let tmp = key;
      var priv
      var zip
      if (key.includes("*") || key.includes("N")) {
        tmp = tmp.replace("*", "");
        req.push(tmp);
      }

      if (tmp.includes("!")) {
        var priv = <Badge bg="danger">&#128274; Private</Badge>;
        cached = true;
        tmp = tmp.replace("!", "");
      }

      if (tmp.includes("^")) {
        
        zip = <Badge bg="info"> &#128230; Compressed</Badge>;
        compress = true;
        tmp = tmp.replace("^", "");
      }
      res.properties[tmp]= {};
      var label = <span>{value} {priv} {zip}</span>;
      
      if (tmp.includes("adc") || tmp.includes("tc")  || tmp.includes("ft") || tmp.includes("st")) {
        var nameSv;
        switch (tmp) {
          case 'adc':
            nameSv='CountryISO';
            break;
          case 'tc':
            nameSv='Currency';
            break;
          case 'ft':
            nameSv='Document';
            break;
          case 'st':
            nameSv='Status';
            break;
        
          default:
            break;
        }
        await sysVarGroup(nameSv).then((sv)=>{
          res.properties[tmp].enum = [];
          res.properties[tmp].enumNames = [];
          sv.map((entry) => {
            res.properties[tmp].enum.push(entry.ID_Var);
            res.properties[tmp].enumNames.push(entry.Name);
          });
          res.properties[tmp].type = 'integer';
          res.properties[tmp].title = label;
        }); 
          
      } else if (types[tmp] === "number" && !(tmp === "y" || tmp === "s")) {
        res.properties[tmp].type = 'integer';
        res.properties[tmp].title = label;;
      } else if (tmp === "T" ) {
        res.properties[tmp].type = 'string';
        res.properties[tmp].title = 'T';
        res.properties[tmp].default = value;
      } else if (tmp === "y" ||  tmp === "s") {
        if(typeof value === 'array' || typeof value === 'object'){
          res.properties[tmp].type = 'integer';
          res.properties[tmp].title = tmp;
          res.properties[tmp].enum = value;
          res.properties[tmp].enumNames = value;
          res.properties[tmp].default = value[0];
        }else{
          res.properties[tmp].type = "integer";
          res.properties[tmp].title = value;
        }

      } else if (types[tmp] === "float") {
        res.properties[tmp].type = 'integer';
        res.properties[tmp].title = label;;
      } else if (types[tmp] === "date" || key.includes("E")) {
        res.properties[tmp].type = 'string';
        res.properties[tmp].format = 'date';
        res.properties[tmp].title = label;;
      } else if (types[tmp] === "json") {
        res.properties[tmp].type = 'string';
        res.properties[tmp].title = label;;
      } else if (key.includes("C") ) {
        if (key.includes("#")) {
          var matches = key.match(/C[\d+][\S]*#(\d*)-?(\d+)?/);
          var fmatches = matches[1];
          if(matches[2]===undefined){
            res.properties[tmp].minItems = Number(fmatches);
            res.properties[tmp].maxItems = Number(fmatches);
          }else{
            
            res.properties[tmp].title = fmatches + '-' + matches[2];
            res.properties[tmp].minItems = fmatches;
            res.properties[tmp].maxItems = matches[2];
          }
        } else {
          res.properties[tmp].minItems = 1;
          res.properties[tmp].maxItems = 99;
        }
        

        res.properties[tmp].type = 'array';
        res.properties[tmp].title = '';
        res.properties[tmp].nbItems = 1;
        res.properties[tmp].items = await createShema(value, child+'_'+tmp);
      }else if (key.includes("D") ) {
        res.properties[tmp]= await createShema(value, child+'_'+tmp);
        res.properties[tmp].title=  '';
      } else {
        
        res.properties[tmp].type = 'string';
        res.properties[tmp].title = label;
      }
      
    })).then((values) => {
      res.required = req;
      if(isNaN(child) ){
        setshemaJs(res)
      }
      
      return res;
    });

    return res;
   
  }

  return (
    <>
        <div className="text-center mt-4">
          <h2>
            <img
              src="https://cdn.cryptolex.ch/img/logo_ethernity_wb.png"
              className="App-logo"
              alt="logo"
            />
            Certify your item
          </h2>
        </div>
        <Container className="mb-4">
          <Row>
            <Col  xs={12} sm={6}>

              <h3>{params.state.Name}</h3>
              {Object.keys(shemaJs).length === 0 ? 'none':
              <>
              <Form 
                schema={shemaJs}
                  onChange={(event) =>{setNewsState(event.formData);console.log(event.formData)}}
                  formData={newState}
                  uiSchema={shemaUIJs}
                  widgets={widgets}
                  fields={fields }
                  showErrorList={false}
                  validate={submit}
              /></>
              }
            </Col>
            <Col sm={6} xs={12}>
              <h4>{name}</h4>
            {("D" in newState) ? 
            <>
             { (newState.N !== undefined) ? <h4>{newState.N}</h4>: <h4>-</h4>}
              <Content 
                  template={{Content:params.state.certificat}} 
                  doc={{Content: JSON.stringify(newState.D)}} /> 
            </>
            
            : ''}
            </Col>
          </Row>
          
        </Container>
    </>
  );
};

export default Create;
