import React, { useEffect, useState } from 'react'
import { NavHashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";

import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown
} from 'react-bootstrap';

import { useTranslation } from "react-i18next";
import ScrollToTop from './ScrollToTop';
import { useAuthorization } from '../../hook/AuthProvider';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../../i18n';

const Navigation = (props) => {

  const [expanded, setExpanded] = useState(false);
  const { authUser,status } = useAuthorization();
  const { t, i18n } = useTranslation();
	const [member, setMember] = useLocalStorage("member",'');
  const [api, setAPI] = useLocalStorage("api",'');
  const { changeLanguage } = useLanguage();


  const testFocus = (state) => {
    setExpanded(state);
    setTimeout(function () {
      document.getElementById("searchInput").focus();
    }, 500);//wait 2 seconds
  }

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 75,
      headerEl = document.getElementById("mainNav");

    if (distanceY > shrinkOn) {
      headerEl.classList.add("navbar-shrink");
    } else {
      headerEl.classList.remove("navbar-shrink");
    }
  }

  if (props.isShrink !== '') {
    window.addEventListener("scroll", resizeHeaderOnScroll);
  } else {
    const headerEl = document.getElementById("mainNav");
    headerEl.classList.add("navbar-shrink");
  }

  let perso;
  var logOut;
  var logIn;
  if (authUser !== null) {
      logOut=
      (
        <NavItem className='port-navbar-item'>
            <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/logout" }}><FontAwesomeIcon icon={faSignOut} style={{margin:'5px'}}/></Link >
        </NavItem>
      );

        perso =
          (
            <NavItem className='port-navbar-item'>
                <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/profil" }}>{t("DICO_WORD_PROFILE")}</Link >
            </NavItem>
          );
      
  } else {
    logIn =
      (
        <>
          <NavItem className='port-navbar-item'>
            <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/login" }}>{t("DICO_WORD_LOGIN")}</Link >
          </NavItem>
        </>
      );
  }

  let arti = '';

  if (member !== null && member !== "undefined" && member !== false && status==='signIn') {
    //if(true){
    if(api!==process.env.REACT_APP_SERVER_URL){
      arti =
        (
          <>
            <NavItem className='port-navbar-item'>
              <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/corporate" }}>{t("DICO_WORD_CORPORATE")}</Link >
            </NavItem>
          </>
       );
      }
  }
  
  // Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    changeLanguage(languageValue);
  }

  return (
    <>
      <Navbar expand="lg" expanded={expanded} variant='dark' id="mainNav" fixed="top">
        <Container>
          <ScrollToTop />
          <Navbar.Brand className="navbar-brand port-navbar-brand">
            <NavHashLink to="/" className="nav-link js-scroll-trigger"><img src="https://cdn.cryptolex.ch/img/logo_ethernity_wg.png" alt="logo" className="d-inline-block align-top App-logo" /> Ntity</NavHashLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav>
              <NavItem className='port-navbar-item'>
                <NavHashLink to="/#searchBtn" onClick={() => testFocus(expanded ? false : "expanded")} className="miniSearch nav-link js-scroll-trigger">{t("DICO_NIO_SEARCH_BLC")}</NavHashLink>
              </NavItem>
              <NavItem className='port-navbar-item'>
                <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/store" }}>{t("DICO_NIO_STORE")}</Link >
              </NavItem>
              <NavItem className='port-navbar-item'>
                <Link className="nav-link js-scroll-trigger" onClick={() => setExpanded(expanded ? false : "expanded")} to={{ pathname: "/buy" }}>{t("DICO_WORD_BUY")}</Link >
              </NavItem>
              <NavItem className='port-navbar-item'>
                <NavHashLink to="/#locations" onClick={() => setExpanded(expanded ? false : "expanded")} className="nav-link js-scroll-trigger">{t("DICO_NIO_ECOSYSTEM")}</NavHashLink>
              </NavItem>
              {/* <NavItem className='port-navbar-item'>
                <NavHashLink to="/#contact" onClick={() => setExpanded(expanded ? false : "expanded")} className="nav-link js-scroll-trigger">{t("DICO_NIO_CONTACT_TITLE")}</NavHashLink>
              </NavItem> */}
              {perso}
              {logIn}
              {arti}
            </Nav>
            <select className="custom-select" style={{ backgroundColor:'rgba(0,0,0,0)', border: 'none', borderRadius : 3, color: '#AAA' }} onChange={changeLanguageHandler} value={i18n.language}>
              <option value="en" >EN</option>
              <option value="fr" >FR</option>
              <option value="de" >DE</option>
              <option value="it" >IT</option>
              <option value="es" >ES</option>
            </select>
            {logOut}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
export default Navigation
