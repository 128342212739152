import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,existingImages,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [isDrag, setIsDrag] = useState(false);


  const handleUploadBtnClick = () => {

    fileInputField.current.click();
  };

  
  useEffect(() => {
    if(existingImages!==undefined){
      setFiles({ ...existingImages });
    }
    
  }, [existingImages]);

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <section className={isDrag ? 'dragover dpFileUploadContainer' : 'dpFileUploadContainer'}>
        <label className="dpInputLabelr">{label}</label>
        <p className="dpDragDropText">Drag and drop your files anywhere or</p>
        {isDrag===false &&  <button className="dpUploadFileBtn" type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
        </button>}
        
        <input className={isDrag ? 'dragover dpFormField' : 'dpFormField'} 
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          onDragOver={(e)=>{setIsDrag(true);}}
          onDragLeave={(e)=>{setIsDrag(false);}}
          onDrop={(e)=>{setIsDrag(false);}}
          {...otherProps}
        />
        <article className="dpFilePreviewContainer" >
        <span>To Upload</span>
        < section className="dpPreviewList">
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <section className="dpPreviewContainer"   key={fileName}>
                <div>
                  {isImageFile ? <> <img className="dpImagePreview"
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                    <div className="dpFileMetaData isImageFile"   >
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <FontAwesomeIcon icon={faTrashAlt} 
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </div></>:<><div className="dpFileMetaData"   >
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <FontAwesomeIcon icon={faTrashAlt} 
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </div>
                  </>

                   
                  }
                </div>
              </section>
            );
          })}
        </section>
      </article>
      </section>
      
    </>
  );
};

export default FileUpload;