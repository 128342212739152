import React from 'react'
import Brand from '../ntityDoc/brand'
import NtityDoc from '../ntityDoc/ntityDoc'

function Resultat(props) {
  if (props.res != null && props.res.length != 0 ){
    if ( props.res.length === 1 ) {
      return <NtityDoc doc={props.res[0]}/>;
    } else {
      const tmp = Array.from(props.res);
      let tmp_x = [];

      tmp.forEach(x => {
        if (x['ID_Format'] === 1507) {
          if (tmp_x[x['Name']] !== undefined) {
            if (tmp_x[x['Name']]['Version'] < x['Version']) {
              tmp_x[x['Name']] = x;
            }
          } else {
            tmp_x[x['Name']] = x;
          }
        } else {
          tmp_x.push(x);
        }
      });

      const list = Object.entries(tmp_x).map((entry) => {
        var idContainer = 'searchRes';
        if (entry[1].ID_Format === 1507) {
          idContainer = 'searchTmpl';
        }
        return (<div id={idContainer} key={entry[1].ID_Doc} className='sRa'> <Brand doc={entry[1]} small={true} /></div>);
      });

      return <div> {list} </div>;
    }
  } else {
    return <h1> Noting found </h1>;
  }
}

export default Resultat;