import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Accordion, Row, Col, Nav, Navbar, Table, ButtonGroup, Badge, Form, FormSelect } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { useLocalStorage } from '../../hook/useLocalStorage';
import AlertDismissible from '../../composent/shared/AlertDismissible';
import { useSysVar } from '../../hook/SysVar';

const Logs = () => {
	const { t } = useTranslation();
	const { sysVarId } = useSysVar();
	const { authToken, signOut } = useAuthorization();
	const [resultASku, setResultASku] = useState("");
	const [rowSku, setrowSku] = useState([]);
	const [block, setBlock] = useState("");
	const [resultCount, setResultCount] = useState([]);
	const [page, setPage] = useState(1);
	const [IDBlock, setIDBlock] = useState("");
	const [level, setLevel] = useState("");
	const [date, setdate] = useState("");
	const [order, setOrder] = useState("");
	const [orderBy, setOrderBy] = useState("");
	const [statusId, setStatusId] = useState("");
	const [search, setSearch] = useState("");

	
	const [selectStatus, setSelectStatus] = useState('');
	const [checkedStatus, setCheckedStatus] = useState([]);

    const [msgError, setMsgError] = useState('');
	const racOrder = {
		'order' : {'val' : order,'set' : setOrder},
		'orderBy' : {'val' : orderBy,'set' : setOrderBy}
	}
	
	const  lstBgStatus={14:'warning',16:'success',24:'danger'}
	const [api, setAPI] = useLocalStorage("api",'');
	const [member, setMember] = useLocalStorage("member",'');

	let rowsPerPage = 10

	useEffect(() => {
		if(authToken !== null){
			getNotifications('count');
			getblock();
		}
		console.log();
	},[authToken]);

	
	useEffect(() => {
		if(authToken !== null){
			setResultASku("");
			getNotifications('full')
		}
	},[page]);

	
	useEffect(() => {
		console.log('dddddddddddddddddddddddd');
		if(authToken !== null){
			setResultASku("loading");
			setResultCount(0);
			getNotifications('count');
			getNotifications('full')
		}
	},[IDBlock,level,date,order,orderBy,statusId,search]);

	async function getblock(ct) {
        const form = {};
        form["ct"] = 'min';
		const options = {
			method: 'GET',
			url: api + 'corporate/notifictionBlock',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
        axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/notifictionBlock"){
			  setMsgError(response.data.msg);
				signOut();
			} else {
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
				var tmprowSku = [...response.data.data];
				setBlock(tmprowSku);
				}else{
				setMsgError(response.data.msg);
			  }
			}
		  }).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/notifictionBlock"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		  });
		}

		
	async function sendStatus() {
		const form = new FormData();
		form.append("ct", selectStatus);
		form.append("logs",  JSON.stringify(checkedStatus));
		const options = {
			method: 'PUT',
			url: api + 'corporate/log',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			data: form
		};
		axios.request(options).then(function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/log"){
				signOut();
			} else {
				if(response.data.data === true){
					setResultASku("");
					setResultCount([]);
					getNotifications('count');
					getNotifications('full')
				}else{
					setMsgError(response.data.msg);}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/log"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
		
	}

	async function getNotifications(ct) {
        const form = {};
        form["ct"] = ct;
        form["page"] = page-1;
        form["limit"] = 10;
        form["filtre"] = JSON.stringify({'Limit':rowsPerPage,'ID_Block':IDBlock,'Level':level,'Status':statusId,'Date':date,'Search':search,'Order':order,'OrderBy':orderBy});
		
		const options = {
			method: 'GET',
			url: api + 'corporate/log',
			headers: {
				Authorization: "Bearer " + authToken,
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
			params: form
		};
		
		await axios.request(options).then(async function (response) {
			if(response.data.status === 401 && response.data.path === "errorToken/log"){
				signOut();
			} else {
				
				if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
					if(ct=='count'){
						setResultCount(response.data.data.total);
						setPage(1);
					}else{
					var tmprowSku = [...response.data.data];
					//setrowSku(response.data.data)
						if(tmprowSku.length >1){
							tmprowSku.map(async (entry,index)=>{
								sysVarId(entry['ID_Type']).then((rep)=>{
								   tmprowSku[index]['ID_Type'] = t(rep.Tname);
								   sysVarId(entry['ID_Action']).then((rep)=>{
									   tmprowSku[index]['ID_Action'] = t(rep.Tname);
									   sysVarId(entry['ID_Status']).then((rep)=>{
											tmprowSku[index]['check'] =  <input key={entry.ID_Cache} type="checkbox" id={entry.ID_Cache} class={`logCheck`} value={entry.ID_Cache} onChange={(e)=>{handlerStatus(e)}}/>;	
											if(lstBgStatus[tmprowSku[index]['ID_Status']] === undefined){
												tmprowSku[index]['ID_Status'] = <Badge bg="secondary">{t(rep.Tname)}</Badge>;
									   		}else{
												tmprowSku[index]['ID_Status'] = <Badge bg={lstBgStatus[tmprowSku[index]['ID_Status']]}>{t(rep.Tname)}</Badge>;
											}
											tmprowSku[index]['ID'] = entry.ID_Cache; 
											tmprowSku[index]['Time'] = new Date(tmprowSku[index]['Time']).toLocaleDateString('en-CA',{ year: 'numeric', month: 'numeric', day: 'numeric' , hour: 'numeric' , minute: 'numeric', hour12:false}); 
											tmprowSku[index]['bgColor'] = levelRowsCol[entry.Level]; 
											if(index+1 === response.data.data.length){
												setrowSku(tmprowSku);
											}
									   });
								   });
							   });	
						   });
						}else{
							setrowSku(tmprowSku);
						}
						
						setResultASku(response.data.data);
					}
				}else{
					setMsgError(response.data.msg);
				}
			}
		}).catch(function (error) {
			var errt;
			if (error.response) {
			  errt=error.response;
			} else if (error.request) {
			  errt=error.request.response;
			} else {
			  setMsgError(error.message);
			  return null;
			}
			if(errt.data.status === 401 && errt.data.path === "errorToken/log"){
			  setMsgError(errt.data.msg);
			  signOut();
			}else{
			  setMsgError(errt.data.msg);
			}
		});
	}

	const levelRowsCol = {
		'Notice':'info',
		'Warning':'warning',
		'Critical':'danger',
	};
	function handleGloCheck(elem){
		var elems = document.getElementsByClassName('logCheck'); 
		var tmpL = checkedStatus;
		if(elem.target.checked===true){
			Array.from(elems).map((e)=>{
				var index = tmpL.indexOf(e.value);
				if (index === -1) {
					tmpL.push(e.value)
				}
				e.checked = true
			});
		}else{
			Array.from(elems).map((e)=>{
				var index = tmpL.indexOf(e.value);
				if (index !== -1) {
					tmpL.splice(index, 1)
				}
				e.checked = false
			});
		}
		setCheckedStatus(tmpL);
	}
	
	function handlerStatus(e){
		var tmpL = checkedStatus;
		
		var elems = document.getElementById('checkAll').checked = false;
		if(e.target.checked===true){
			var index = tmpL.indexOf(e.target.value);
			if (index === -1) {
				tmpL.push(e.target.value)
				setCheckedStatus(tmpL);
			}
		}else{
			var index = tmpL.indexOf(e.target.value);
			if (index !== -1) {
				tmpL.splice(index, 1)
				setCheckedStatus(tmpL);
			}
		}
		
	}

	const columns = [
		{ accessor: 'check', label: <input  type="checkbox" id={`checkAll`} label={`checkAll`} onClick={(e)=>{handleGloCheck(e)}} /> ,'noFilter':true},
		{ accessor: 'ID_Action', label: 'Action' },
		{ accessor: 'ID_Status', label: 'Status' },
		{ accessor: 'Message', label: 'Message' },
		{ accessor: 'MsgFrom', label: 'MsgFrom' },
		{ accessor: 'MsgTo', label: 'MsgTo' },
		{ accessor: 'Time', label: t('DICO_WORD_CREATION_DATE') },
	];

	const fateFilter =[
		[t('DICO_WORD_ALL'),''],
		[t('DICO_WORD_TODAY'),'lstJ'],
		[t('DICO_WORD_LAST_MONTH'),'lstM'],
		[t('DICO_WORD_LAST_YEAR'),'lstY'],
	]


	const accordFilter = () => {
		
		var tmpBlock = [...block];
		var arrlevel = []
		var btnBlock = []
		var bgB = 'outline-'
		if(''===IDBlock){
			bgB = ''
		}
		btnBlock.push(<Button variant={bgB+'secondary'} size="sm" onClick={() => {setIDBlock('');setLevel('');setdate('');}}>all</Button>)
		tmpBlock.map((entry)=>{
			if(!arrlevel.includes(entry.Description)){
				arrlevel.push(entry.Description)
			}
			var bgB = 'outline-'
			if(entry.ID_Block===IDBlock){
				bgB = ''
			}
			btnBlock.push(<Button variant={bgB+'secondary'} size="sm" onClick={() => {setIDBlock(entry.ID_Block);setLevel('');setdate('');}}>{entry.Name}</Button>)
		});
		var btnLevel = arrlevel.map((entry)=>{
			var bgB = 'outline-'
			if(entry===level){
				bgB = ''
			}
			return <Button variant={bgB+levelRowsCol[entry]} size="sm"  onClick={() => {setIDBlock('');setLevel(entry);setdate('');}}>{entry}</Button>
		});
		var btnDate = fateFilter.map((entry)=>{
			var bgB = 'outline-'
			if(entry[1]===date){
				bgB = ''
			}
			return <Button variant={bgB+"secondary"} size="sm"  onClick={() => {setdate(entry[1]);}}>{entry[0]}</Button>
		});
		return (
			<Accordion defaultActiveKey={['0','1']} alwaysOpen>
				<Accordion.Item eventKey="0">
					<Accordion.Header>{t("DICO_WORD_BLOCK")}</Accordion.Header>
					<Accordion.Body>
						<>{btnBlock}</>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item   eventKey="1" active>
					<Accordion.Header>{t("DICO_WORD_LEVEL")}</Accordion.Header>
					<Accordion.Body >
						<>{btnLevel}</>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item   eventKey="2" active>
					<Accordion.Header>{t("DICO_WORD_DATE")}</Accordion.Header>
					<Accordion.Body >
						<>{btnDate}</>
					</Accordion.Body>
				</Accordion.Item>
		</Accordion>
	  )
	}
	
	const navFilter = () => {
		
		var tmpBlock = [...block];
		var arrlevel = []
		var btnBlock = []
		var lstBlock = {}
		var bgB = 'outline-'
		if(''===IDBlock && ''===level){
			bgB = ''
		}
		btnBlock.push(<Button  variant={bgB+'secondary'} style={{borderRadius:0}} size='sm' onClick={() => {setIDBlock('');setLevel('');setdate('');}}>all</Button >)
		tmpBlock.map((entry)=>{
			if(!arrlevel.includes(entry.Description)){
				arrlevel.push(entry.Description);
				var bgB = 'outline-'
				if(entry.Description===level){
					bgB = ''
				}
				lstBlock[entry.Description] = [<Button variant={bgB+levelRowsCol[entry.Description]} size='sm' style={{borderRadius:0}} onClick={() => {setIDBlock('');setLevel(entry.Description);setdate('');}}>{entry.Description}</Button>]
			}
			var bgB = 'outline-'
			if(entry.ID_Block===IDBlock){
				bgB = ''
			}
			lstBlock[entry.Description].push(<Button  variant={bgB+levelRowsCol[entry.Description]} size='sm' style={{borderRadius:0}} onClick={() => {setIDBlock(entry.ID_Block);setLevel('');setdate('');}}>{entry.Name}</Button >)
		});
		['Notice','Warning','Critical'].map((entry)=>{
			if(lstBlock[entry] !==undefined){
				btnBlock = btnBlock.concat(lstBlock[entry])
			}
		});
		var btnDate = fateFilter.map((entry)=>{
			var bgB = 'outline-'
			if(entry[1]===date){
				bgB = ''
			}
			return <Button variant={bgB+"secondary"} style={{borderRadius:0}} size='sm' onClick={() => {setdate(entry[1]);}}>{entry[0]}</Button>
		});
		var btnStatus = [["",'All'],[14,'Pending'],[16,'Resolved'],[24,'Rejected']].map((entry)=>{
			var bgB = 'outline-'
			if(entry[0]===statusId){
				bgB = ''
			}
			var bgS='secondary';
			if(lstBgStatus[entry[0]] !== undefined){
				bgS=lstBgStatus[entry[0]];
			}
			return <Button variant={bgB+bgS} style={{borderRadius:0}} size='sm' onClick={() => {setStatusId(entry[0]);}}>{entry[1]}</Button>
			
		});
		return (
			<Accordion defaultActiveKey={['0']} alwaysOpen>
				<Accordion.Item eventKey="0">
					<Accordion.Header>{t("DICO_WORD_GROUP")}</Accordion.Header>
					<Accordion.Body style={{padding:0}}>
    					<div className="d-grid" style={{width:'100%'}}>
							{btnBlock}
    					</div>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item   eventKey="1" active>
					<Accordion.Header>{t("DICO_WORD_STATUS")}</Accordion.Header>
					<Accordion.Body style={{padding:0}}>
    					<div className="d-grid" style={{width:'100%'}}>
							{btnStatus}
    					</div>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item   eventKey="2" active>
					<Accordion.Header>{t("DICO_WORD_DATE")}</Accordion.Header>
					<Accordion.Body style={{padding:0}}>
    					<div className="d-grid" style={{width:'100%'}}>
							{btnDate}
    					</div>
					</Accordion.Body>
				</Accordion.Item>
		</Accordion>
	  )
	}
	return (
	< >
		<Container>

			  <AlertDismissible variant="danger"  text={msgError}/>
			<div className='containerTitle'>{t("DICO_WORD_LOGS")}</div>
			<Card style={{ marginTop: 50, marginBottom: 100 }}>
				<Card.Body>
					{resultASku === '' ? <Container className='text-center'><Loader /></Container> : 
					<>
						<Row>
							<Col sm={2}>
								{block === '' ? '': <>{navFilter()}</>}
							</Col>
							<Col sm={10}>
								
								{member.Right[1067].U===1 &&
									<ButtonGroup>
										<FormSelect onChange={(e)=>{setSelectStatus(e.target.value)}} aria-label="Default select example">
											<option>Open this select menu</option>
											<option value="14">Pending</option>
											<option value="16">Resolved</option>
											<option value="24">Rejected</option>
										</FormSelect>
										<Button variant="primary" onClick={()=>{sendStatus()}}> Submit</Button>
									</ButtonGroup>
								}
								
								<ResultTable rows={rowSku} columns={columns} paged={{'page':page,'setPage':setPage,'totalC':resultCount}} ct={'refreshPage'} searched={{search,setSearch}} rowsPerPage={rowsPerPage} sorted={racOrder}/>
							</Col>
							
					
						</Row>
					</>
					}
				</Card.Body>
			</Card>
		</Container>
	</>
	);
}

export default Logs;