import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useAuthorization } from '../../hook/AuthProvider';
import Loader from '../../composent/shared/Loader';
import ResultTable from '../../composent/shared/Result.table';
import { CSVLink } from 'react-csv'
import ModalTQR from '../../composent/corporate/addTQR';
import { useLocalStorage } from '../../hook/useLocalStorage';
import { ExportToExcel } from '../../composent/shared/ExportToExcel';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import AlertDismissible from '../../composent/shared/AlertDismissible';




	const CSVDownload = (props) => {
		const btnRef = useRef(null)
		useEffect(() => btnRef.current?.click(), [btnRef])
		return (
			<CSVLink {...props}>
			<span ref={btnRef} />
			</CSVLink>
			)
	}

	
	const TQR = () => {


		const { t } = useTranslation();
		const { authToken, signOut } = useAuthorization();
		const [rowTQR, setRowTQR] = useState([]);
		const [csvData, setCSVData] = useState();
		const [csvExcel, setCSVExcel] = useState();

		const [date, setDate] = useState();
		const [modalShow, setModalShow] = useState(false);
		const [titleM, settitleM] = useState('');
		
		const [api, setAPI] = useLocalStorage("api",'');
		const [gbm, setGBM] = useLocalStorage("gbm",'');


		const [msgError, setMsgError] = useState('');
		const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";
		
		const exportToCSV = (apiData, fileName) => {
			const ws = XLSX.utils.json_to_sheet(apiData, {header: false});
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(data, fileName + fileExtension);
		};
		
		useEffect(() => {
			if(!modalShow){
				getInfo();
			}
		},[modalShow]);
		
		function getInfo() {
			const form = {};
			form["ct"] = 'full';
			form["Username"] = gbm;

			const options = {
				method: 'GET',
				url: process.env.REACT_APP_SERVER_URL + 'corporate/info',
				headers: {
					Authorization: "Bearer " + authToken,
					'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
				},
				params: form
			};
			
			axios.request(options).then(function (response) {
				if(response.data.status === 401 && response.data.path === "errorToken/info"){
					setMsgError(response.data.msg);
					signOut();
				} else {
					if(response.data.status === false){
						setMsgError(response.data.msg);
					}else{
						console.log(response.data.data);
						getAllTQR(response.data.data.ID_GBM,response.data.data[4001])
					}
				}
			}).catch(function (error) {
				var errt;
				if (error.response) {
				  errt=error.response;
				} else if (error.request) {
				  errt=error.request.response;
				} else {
				  setMsgError(error.message);
				  return null;
				}
				if(errt.data.status === 401 && errt.data.path === "errorToken/info"){
				  setMsgError(errt.data.msg);
				  signOut();
				}else{
				  setMsgError(errt.data.msg);
				}
			});
		}
		
		function getAllTQR(ID_GBM, TQR_URL) {
			const options = {
				method: 'GET',
				url: api + 'tqr/batch',
				headers: {
					Authorization: "Bearer " + authToken,
					'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
				},
			};
			
			axios.request(options).then(function (response) {
				if(response.data.status === 401 && response.data.path === "errorToken/batch"){
					setMsgError(response.data.msg);
					signOut();
				} else {
					if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
						var tmprowTQR = [...response.data.data];
						tmprowTQR.map((entry,index)=>{
							tmprowTQR[index]['ID'] = index; 
							tmprowTQR[index]['Created'] = new Date(tmprowTQR[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'); 
							tmprowTQR[index]['Edit'] = <>
							<Button variant="outline-success" onClick={() => {getExcel(entry.Description,new Date(tmprowTQR[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'),ID_GBM,TQR_URL)}}> Get Excel </Button>
							<Button variant="outline-success" onClick={() => {getCSV(entry.Description,new Date(tmprowTQR[index]['Created']).toLocaleDateString('en-CA').replace(/\//g, '-'),ID_GBM,TQR_URL)}}> Get CSV </Button>
							</>
						});
						setRowTQR(tmprowTQR);
					}else{
						setMsgError(response.data.msg);
					}
				}
			}).catch(function (error) {
				var errt;
				if (error.response) {
				  errt=error.response;
				} else if (error.request) {
				  errt=error.request.response;
				} else {
				  setMsgError(error.message);
				  return null;
				}
				if(errt.data.status === 401 && errt.data.path === "errorToken/batch"){
				  setMsgError(errt.data.msg);
				  signOut();
				}else{
				  setMsgError(errt.data.msg);
				}
			});
		}
		
		async function getCSV(TQR,date,ID_GBM,TQR_URL) {
			setCSVData();
			setDate(date)
			
			const form = {};
			form["batch"]=TQR;
			
			const options = {
				method: 'GET',
				url: api + 'tqr/batch',
				headers: {
					Authorization: "Bearer " + authToken,
					'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
				},
				params: form
			};
			
			axios.request(options).then(function (response) {
					if(response.data.status === 401 && response.data.path === "errorToken/batch"){
						setMsgError(response.data.msg);
						signOut();
					}else{
						if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
							let tmp = [];
							tmp.push('QR Code;Verification Code')
							response.data.data.forEach(element => {
								tmp.push(TQR_URL+ID_GBM+'/'+element.TQR+';'+element.TQR.slice(-6));
							});
							setCSVData(tmp.join('\n'));
						}else{
							setMsgError(response.data.msg);
						}
					}
			}).catch(function (error) {
				var errt;
				if (error.response) {
				  errt=error.response;
				} else if (error.request) {
				  errt=error.request.response;
				} else {
				  setMsgError(error.message);
				  return null;
				}
				if(errt.data.status === 401 && errt.data.path === "errorToken/batch"){
				  setMsgError(errt.data.msg);
				  signOut();
				}else{
				  setMsgError(errt.data.msg);
				}
			});
		}

		async function getExcel(TQR,date,ID_GBM,TQR_URL) {
			setCSVExcel();
			
			const form = {};
			form["batch"]=TQR;
			
			const options = {
				method: 'GET',
				url: api + 'tqr/batch',
				headers: {
					Authorization: "Bearer " + authToken,
					'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
				},
				params: form
			};
			
			axios.request(options).then(function (response) {
				if(response.data.status === 401 && response.data.path === "errorToken/batch"){
					setMsgError(response.data.msg);
					signOut();
				}else{
					if((response.data.status === 400 && response.data.msg===undefined  && Array.isArray(response.data.data))||(response.data.status === 200 && response.data.success === true)){
						let tmp = [];
						response.data.data.forEach(element => {
							tmp.push({'QR Code': TQR_URL+ID_GBM+'/'+element.TQR,'Verification Code' : element.TQR.slice(-6)});
						});
						exportToCSV(tmp,'TQR_Batch_'+ date);
					}else{

						setMsgError(response.data.msg);
					}
					
				}
			}).catch(function (error) {
				var errt;
				if (error.response) {
				  errt=error.response;
				} else if (error.request) {
				  errt=error.request.response;
				} else {
				  setMsgError(error.message);
				  return null;
				}
				if(errt.data.status === 401 && errt.data.path === "errorToken/batch"){
				  setMsgError(errt.data.msg);
				  signOut();
				}else{
				  setMsgError(errt.data.msg);
				}
			});
		}
		
		const columns = [
			{ accessor: 'ID', label: '' },
			{ accessor: 'Description', label: t('DICO_NIO_NAME_BATCH') },
			{ accessor: 'Number', label: t('DICO_NIO_NUMBER_TQR') },
			{ accessor: 'Created', label: t('DICO_WORD_CREATION_DATE') },
			{ accessor: 'Edit', label: t('DICO_WORD_ACTION') },
		]
		
		return (
			<>
				<Container>
					<AlertDismissible variant="danger"  text={msgError}/>
					<div className='containerTitle'>{t("DICO_WORD_TQR")}</div>
					<Card style={{ marginTop: 50, marginBottom: 100 }}>
						{csvData !== undefined ? <CSVDownload data={csvData} filename={'TQR_Batch_'+ date} target="_blank" />: <></>}
						<Card.Body>
							<Button variant="outline-primary" onClick={() => {setModalShow(true);settitleM(t("DICO_WORD_ADD")+' - '+t("DICO_WORD_TQR"))}}>
								{t("DICO_WORD_ADD")}
							</Button>
							{rowTQR === '' ? <Container className='text-center'><Loader /></Container> : <ResultTable rows={rowTQR} columns={columns}/>}
						</Card.Body>
					</Card>
				</Container>
				<ModalTQR 
					show={modalShow}
					onHide={() => setModalShow(false)}
					title={titleM}
				/>
			</>
			);
		}
		
		export default TQR;